// src/components/Customer.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Customer.css';
import { toast } from 'react-toastify';
import Spinner from './Spinner'; // Pastikan path ini betul
import ClosingRateChart from './ClosingRateChart'; // Import komponen ClosingRateChart

const FILTER_OPTIONS = {
  ALL: 'all',
  TODAY: 'today',
  THIS_WEEK: 'this_week',
  THIS_MONTH: 'this_month',
};

const Customer = ({ therapistId }) => {
  const [customers, setCustomers] = useState([]);
  const [groupedCustomers, setGroupedCustomers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [repeatedBookings, setRepeatedBookings] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(FILTER_OPTIONS.ALL); // Tambah state untuk filter

  useEffect(() => {
    if (therapistId) {
      fetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId, filter]); // Tambah filter sebagai dependency

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      setSelectedCustomers([]); // Reset pilihan apabila memuat semula
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/customers/${therapistId}`,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json',
          },
          params: { _: new Date().getTime() }, // Pastikan cache diatasi
        }
      );

      if (Array.isArray(response.data)) {
        let customersData = response.data;

        // Tapis pelanggan berdasarkan filter yang dipilih
        customersData = filterData(customersData, filter);

        // Susun pelanggan mengikut tarikh secara menaik
        customersData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));

        // Mengumpulkan pelanggan mengikut tarikh
        const grouped = customersData.reduce((acc, customer) => {
          const date = formatDate(customer.bookingDate);
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(customer);
          return acc;
        }, {});

        // Menyusun pelanggan dalam setiap tarikh mengikut masa
        Object.keys(grouped).forEach(date => {
          grouped[date].sort((a, b) => a.startTime.localeCompare(b.startTime));
        });

        setGroupedCustomers(grouped);
        setCustomers(customersData);

        // Mendapatkan jumlah tempahan berulang bagi setiap pelanggan
        const repeatedData = {};
        await Promise.all(
          customersData.map(async (customer) => {
            try {
              const repeatedResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/customers/repeated/${customer.phone}`,
                {
                  headers: { 'x-auth-token': token }
                }
              );
              // Pastikan count adalah integer
              repeatedData[customer.phone] = Math.round(repeatedResponse.data.count);
            } catch (error) {
              console.error(`Error fetching repeated count for ${customer.phone}:`, error);
              repeatedData[customer.phone] = 0;
            }
          })
        );
        setRepeatedBookings(repeatedData);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(true);
      toast.error('Gagal memuat data pelanggan');
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk menapis data berdasarkan filter
  const filterData = (data, filter) => {
    const now = new Date();
    let filteredData = [...data];

    switch (filter) {
      case FILTER_OPTIONS.TODAY:
        filteredData = data.filter(customer =>
          isSameDay(new Date(customer.bookingDate), now)
        );
        break;
      case FILTER_OPTIONS.THIS_WEEK:
        const weekNumber = getWeekNumber(now);
        const year = now.getFullYear();
        const startOfWeek = getStartOfWeek(year, weekNumber);
        filteredData = data.filter(customer => {
          const bookingDate = new Date(customer.bookingDate);
          return bookingDate >= startOfWeek && bookingDate <= now;
        });
        break;
      case FILTER_OPTIONS.THIS_MONTH:
        const month = now.getMonth();
        const yearMonth = now.getFullYear();
        const startOfMonth = new Date(yearMonth, month, 1);
        filteredData = data.filter(customer => {
          const bookingDate = new Date(customer.bookingDate);
          return bookingDate >= startOfMonth && bookingDate <= now;
        });
        break;
      case FILTER_OPTIONS.ALL:
      default:
        // Tidak menapis, gunakan semua data
        break;
    }

    return filteredData;
  };

  // Fungsi untuk menyemak sama ada dua tarikh adalah hari yang sama
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  // Fungsi untuk mendapatkan nombor minggu dalam setahun
  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1)/7);
    return weekNo;
  };

  // Fungsi untuk mendapatkan permulaan minggu
  const getStartOfWeek = (year, week) => {
    const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
    const dow = simple.getUTCDay();
    let ISOweekStart = new Date(simple);
    if (dow <= 4)
      ISOweekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
    else
      ISOweekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
    return ISOweekStart;
  };

  // Fungsi carian pelanggan
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fungsi memilih filter
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // Fungsi reset filter
  const clearAllFilters = () => {
    setFilter(FILTER_OPTIONS.ALL);
    setSearchTerm('');
  };

  // Menapis pelanggan berdasarkan input carian dan tarikh
  const filteredGroupedCustomers = Object.keys(groupedCustomers).reduce((acc, date) => {
    const filteredCustomers = groupedCustomers[date].filter(customer =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone.includes(searchTerm)
    );
    if (filteredCustomers.length > 0) {
      acc[date] = filteredCustomers;
    }
    return acc;
  }, {});

  // Handle checkbox selection
  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev => {
      if (prev.includes(customerId)) {
        return prev.filter(id => id !== customerId);
      } else {
        return [...prev, customerId];
      }
    });
  };

  // Handle select all
  const handleSelectAll = () => {
    const allCustomerIds = Object.values(filteredGroupedCustomers).flat().map(c => c._id);
    if (selectedCustomers.length === allCustomerIds.length) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(allCustomerIds);
    }
  };

  // Delete multiple customers
  const deleteMultipleCustomers = async () => {
    if (selectedCustomers.length === 0) {
      toast.warning('Sila pilih pelanggan untuk dipadamkan');
      return;
    }

    if (!window.confirm(`Adakah anda pasti untuk memadam ${selectedCustomers.length} pelanggan yang dipilih?`)) {
      return;
    }

    try {
      setIsDeleting(true);
      const token = localStorage.getItem('token');
      
      await axios.post('/api/customers/delete-multiple', {
        customerIds: selectedCustomers
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      // Kemaskini state pelanggan
      const updatedCustomers = customers.filter(c => !selectedCustomers.includes(c._id));
      setCustomers(updatedCustomers);

      // Kemaskini pelanggan yang dikumpulkan
      const updatedGroupedCustomers = updatedCustomers.reduce((acc, customer) => {
        const date = formatDate(customer.bookingDate);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(customer);
        return acc;
      }, {});
      setGroupedCustomers(updatedGroupedCustomers);

      setSelectedCustomers([]);
      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customers:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setIsDeleting(false);
    }
  };

  // Single delete customer function
  const deleteCustomer = async (id) => {
    try {
      setDeletingId(id);
      const token = localStorage.getItem('token');
      
      await axios.delete(`/api/customers/${id}`, {
        headers: {
          'x-auth-token': token
        }
      });

      const updatedCustomers = customers.filter(customer => customer._id !== id);
      setCustomers(updatedCustomers);

      // Kemaskini pelanggan yang dikumpulkan
      const updatedGroupedCustomers = updatedCustomers.reduce((acc, customer) => {
        const date = formatDate(customer.bookingDate);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(customer);
        return acc;
      }, {});
      setGroupedCustomers(updatedGroupedCustomers);

      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setDeletingId(null);
    }
  };

  // Confirm single delete
  const confirmDelete = (id, name) => {
    if (window.confirm(`Adakah anda pasti untuk memadam pelanggan ${name || 'ini'}?`)) {
      deleteCustomer(id);
    }
  };

  const getStatusBadgeClass = (status) => {
    return status === 'completed' ? 'status-badge completed' : 'status-badge in-progress';
  };

  const getRepeatedBadgeClass = (count) => {
    if (count >= 3) return 'repeated-badge vvip';
    if (count === 2) return 'repeated-badge regular';
    return 'repeated-badge new';
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('ms-MY', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'Asia/Kuala_Lumpur', // Pastikan zon waktu Malaysia
    });
  };

  if (loading) {
    return (
      <div className="loading-state">
        <Spinner
          type="SyncLoader"
          size={10}
          color="#2980b9"
          label="Memuatkan pelanggan..."
        />
      </div>
    );
  }

  if (error) return <div className="error-state">Ralat memuatkan pelanggan. Sila cuba lagi.</div>;
  if (!customers.length) return <div className="empty-state">Tiada pelanggan ditemui.</div>;

  return (
    <div className="customer-list">
      {/* Chart */}
      <ClosingRateChart therapistId={therapistId} filter={filter} />

      <div className="list-header">
        <h2>Senarai Pelanggan</h2>

        <div className="filters">
          <div className="filter-buttons">
            {Object.keys(FILTER_OPTIONS).map(key => (
              <button
                key={key}
                className={`filter-button ${filter === FILTER_OPTIONS[key] ? 'active' : ''}`}
                onClick={() => handleFilterChange(FILTER_OPTIONS[key])}
              >
                {key.replace('_', ' ')}
              </button>
            ))}
          </div>

          <div className="filter-item">
            <label htmlFor="searchTerm">Cari:</label>
            <input
              type="text"
              id="searchTerm"
              placeholder="Cari pelanggan..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          {/* Butang Clear Filter */}
          <button 
            className="clear-filter-button"
            onClick={clearAllFilters}
          >
            Clear Filter
          </button>
        </div>

        {selectedCustomers.length > 0 && (
          <button 
            onClick={deleteMultipleCustomers}
            className="delete-selected-button"
            disabled={isDeleting}
          >
            {isDeleting ? 'Memadam...' : `Padam (${selectedCustomers.length})`}
          </button>
        )}
      </div>

      {/* Paparan Pelanggan (Group by Date) */}
      <div className="date-groups">
        {Object.keys(filteredGroupedCustomers).map(date => (
          <div className="date-card" key={date}>
            <div className="date-card-header">
              <h3>{date}</h3>
              <span>
                Jumlah Pelanggan: {filteredGroupedCustomers[date].length}
              </span>
            </div>

            <div className="table-container">
              <table className="customer-table">
                <thead>
                  <tr>
                    <th>
                      {/* “Select all” untuk date group ini (optional) */}
                      <input
                        type="checkbox"
                        checked={
                          filteredGroupedCustomers[date].every(c => selectedCustomers.includes(c._id)) &&
                          filteredGroupedCustomers[date].length > 0
                        }
                        onChange={() => {
                          const allIds = filteredGroupedCustomers[date].map(c => c._id);
                          const isAllSelected = allIds.every(id => selectedCustomers.includes(id));
                          if (isAllSelected) {
                            // buang semua
                            setSelectedCustomers(prev => prev.filter(id => !allIds.includes(id)));
                          } else {
                            // tambah semua
                            setSelectedCustomers(prev => [...new Set([...prev, ...allIds])]);
                          }
                        }}
                        className="checkbox-input"
                      />
                    </th>
                    <th>Nama</th>
                    <th>No Telefon</th>
                    <th>Rawatan</th>
                    <th>Tarikh Tempahan</th>
                    <th>Masa</th>
                    <th>Nama Therapist</th>
                    <th>Status</th>
                    <th>Jumlah Tempahan</th>
                    <th>Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGroupedCustomers[date].map((customer) => (
                    <tr 
                      key={customer._id} 
                      className={selectedCustomers.includes(customer._id) ? 'selected-row' : ''}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedCustomers.includes(customer._id)}
                          onChange={() => handleSelectCustomer(customer._id)}
                          className="checkbox-input"
                          aria-label={`Pilih pelanggan ${customer.name}`}
                          title={`Pilih pelanggan ${customer.name}`}
                        />
                      </td>
                      <td>{customer.name}</td>
                      <td>{customer.phone}</td>
                      <td>{customer.treatment}</td>
                      <td>{formatDate(customer.bookingDate)}</td>
                      <td>{customer.startTime} - {customer.endTime}</td>
                      <td>{customer.therapistName}</td>
                      <td>
                        <span className={getStatusBadgeClass(customer.status)}>
                          {customer.status === 'completed' ? 'Selesai' : 'Dalam Proses'}
                        </span>
                      </td>
                      <td>
                        <span className={getRepeatedBadgeClass(repeatedBookings[customer.phone] || 0)}>
                          {repeatedBookings[customer.phone] || 0} kali
                        </span>
                      </td>
                      <td>
                        <button
                          onClick={() => confirmDelete(customer._id, customer.name)}
                          className="delete-button"
                          disabled={deletingId === customer._id}
                          aria-label={`Padam pelanggan ${customer.name}`}
                          title={`Padam pelanggan ${customer.name}`}
                        >
                          {deletingId === customer._id ? 'Memadam...' : 'Padam'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customer;