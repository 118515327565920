import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserDashboard.css';
import UserBookingTab from './UserBookingTab';
import axios from 'axios';
import config from '../config';

function UserDashboard() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('booking');
  const [therapistName, setTherapistName] = useState('');
  
  // Dapatkan user info
  const user = JSON.parse(localStorage.getItem('user')) || {};

  useEffect(() => {
    // Jika user ada 'therapist' (id), fetch data untuk dapat name
    if (user.therapist) {
      fetchTherapistName(user.therapist);
    }
  }, [user.therapist]);

  const fetchTherapistName = async (therapistId) => {
    try {
      // Buat endpoint: GET /therapist/<therapistId> atau setara
      const res = await axios.get(`${config.API_URL}/therapist/${therapistId}`);
      if (res.data && res.data.name) {
        setTherapistName(res.data.name);
      }
    } catch (error) {
      console.error('Error fetching therapist name:', error);
    }
  };

  // Logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  // Render isi tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 'booking':
        return <UserBookingTab />;
      case 'history':
        return (
          <div className="tab-content">
            <h3>History Booking</h3>
            <p>-- Coming soon --</p>
          </div>
        );
      case 'points':
        return (
          <div className="tab-content">
            <h3>Your Points & Rewards</h3>
            <p>-- Coming soon --</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-dashboard-container">
      {/* WELCOME BANNER */}
      <div className="dashboard-banner">
        <h1>Welcome, {user.name || 'Guest'}!</h1>

        <p className="therapist-info">
          {therapistName
            ? `You are currently with therapist: ${therapistName}`
            : `Associated Therapist ID: ${user.therapist || 'No Therapist'}`}
        </p>
      </div>

      {/* TABS */}
      <div className="dashboard-tabs">
        <button
          className={`dashboard-tab ${activeTab === 'booking' ? 'active' : ''}`}
          onClick={() => setActiveTab('booking')}
        >
          Booking
        </button>
        <button
          className={`dashboard-tab ${activeTab === 'history' ? 'active' : ''}`}
          onClick={() => setActiveTab('history')}
        >
          History Booking
        </button>
        <button
          className={`dashboard-tab ${activeTab === 'points' ? 'active' : ''}`}
          onClick={() => setActiveTab('points')}
        >
          Points
        </button>
      </div>

      {renderTabContent()}

      {/* LOGOUT BUTTON */}
      <div className="logout-btn-container">
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default UserDashboard;
