// ChatBotAI.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import './ChatBotAI.css';

function ChatBotAI({ therapist, token }) {
  const [openAIKey, setOpenAIKey] = useState('');
  const [openAIModel, setOpenAIModel] = useState('text-davinci-003');
  const [chatbotEnabled, setChatbotEnabled] = useState(false);
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    const fetchChatbotSettings = async () => {
      try {
        const res = await axios.get(
          `${config.API_URL}/chatbot-ai/setting/${therapist._id}`,
          {
            headers: { 'x-auth-token': token },
          }
        );
        if (res.data) {
          setOpenAIKey(res.data.openAIKey || '');
          setOpenAIModel(res.data.openAIModel || 'text-davinci-003');
          setChatbotEnabled(res.data.chatbotEnabled || false);
          setPrompt(res.data.prompt || '');
        }
      } catch (error) {
        console.error('Error fetching chatbot settings:', error);
      }
    };

    fetchChatbotSettings();
  }, [therapist._id, token]);

  const saveSettings = async () => {
    try {
      const body = {
        openAIKey,
        openAIModel,
        chatbotEnabled,
        prompt,
      };

      await axios.post(
        `${config.API_URL}/chatbot-ai/setting/${therapist._id}`,
        body,
        { headers: { 'x-auth-token': token } }
      );
      alert('ChatBotAI settings saved successfully!');
    } catch (error) {
      console.error('Error saving chatbot settings:', error);
      alert('Failed to save ChatBotAI settings. Please try again.');
    }
  };

  return (
    <div className="chatbot-ai-container">
      <h2>ChatBotAI - Settings & Prompt</h2>
      <p>
        Di sini anda boleh mengaktifkan/menyahaktifkan ChatBotAI, masukkan OpenAI API key, pilih model, dan tetapkan prompt untuk ChatBotAI.
      </p>

      <div className="section-block">
        <h3>1. ChatBot Settings</h3>

        <div className="settings-row">
          <label>Enable ChatBotAI:</label>
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={chatbotEnabled}
              onChange={(e) => setChatbotEnabled(e.target.checked)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        <div className="settings-row">
          <label>OpenAI API Key:</label>
          <input
            type="text"
            value={openAIKey}
            onChange={(e) => setOpenAIKey(e.target.value)}
            placeholder="sk-..."
          />
        </div>

        <div className="settings-row">
          <label>Pilih Model OpenAI:</label>
          <select
            value={openAIModel}
            onChange={(e) => setOpenAIModel(e.target.value)}
          >
            <option value="text-davinci-003">text-davinci-003</option>
            <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
            <option value="gpt-4">gpt-4 (premium)</option>
          </select>
        </div>

        <div className="settings-row">
          <label>Prompt untuk ChatBotAI:</label>
          <textarea
            rows={10}
            name="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={`Contoh Prompt:\nKamu adalah AI Sales ChatBot untuk sebuah pusat terapi bernama Rumah Terapi Sejahtera di Malaysia...`}
          />
        </div>
      </div>

      <button onClick={saveSettings} className="save-chatbot-btn">
        Save Settings & Prompt
      </button>

      <hr />
      {chatbotEnabled ? (
        <div className="chatbot-activated">
          <p>
            <strong>ChatBotAI is ON.</strong> 
            AI akan cuba bantu close sales ikut prompt yang disediakan.
          </p>
          <p>
            Jika chatbot mula merapu, OFF-kan untuk manual closing.
          </p>
        </div>
      ) : (
        <div className="chatbot-deactivated">
          <p>
            <strong>ChatBotAI is OFF.</strong> 
            Closing akan dijalankan secara manual buat masa ini.
          </p>
        </div>
      )}
    </div>
  );
}

export default ChatBotAI;
