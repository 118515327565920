// src/components/EditSlotForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditSlotForm.css';
import { toast } from 'react-toastify';
import { startOfDay, addDays, format } from 'date-fns';
import { toZonedTime, formatDate } from './dateUtils'; 
import config from '../config';

/**
 * Props:
 * - therapist: data therapist (termasuk subscriptionPlan, dsb.)
 * - treatment: rawatan yang sedang diedit
 * - onClose: fungsi untuk tutup modal
 * - onSave: callback bila berjaya simpan slot
 * - savedSetup: (option) setup lama (hari off, break, dsb.)
 * - onSaveSetup: callback untuk simpan setup lama
 */
function EditSlotForm({
  therapist,
  treatment,
  onClose,
  onSave,
  savedSetup,
  onSaveSetup
}) {
  // Fungsi tentukan berapa bulan boleh dipilih
  const getAllowedMonths = () => {
    if (therapist.subscriptionPlan === 'plus') {
      return [1, 2, 3];
    }
    return [1]; // default
  };

  const allowedMonths = getAllowedMonths();
  const [monthsAhead, setMonthsAhead] = useState(allowedMonths[0]); // default

  // Setup default
  const defaultAvailableDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  };

  // State
  const [availableDays, setAvailableDays] = useState(defaultAvailableDays);
  const [startTime, setStartTime] = useState('09:30');
  const [endTime, setEndTime] = useState('19:00');
  const [duration, setDuration] = useState(30);
  const [breakTime, setBreakTime] = useState(0);
  const [lunchBreakTimes, setLunchBreakTimes] = useState({
    monday: { start: '13:00', end: '14:00' },
    tuesday: { start: '13:00', end: '14:00' },
    wednesday: { start: '13:00', end: '14:00' },
    thursday: { start: '13:00', end: '14:00' },
    friday: { start: '13:00', end: '14:00' },
    saturday: { start: '13:00', end: '14:00' },
    sunday: { start: '13:00', end: '14:00' },
  });
  const [bookingCutoffTime, setBookingCutoffTime] = useState(
    therapist.bookingCutoffTime || 30
  );

  // Bila component mount, kalau ada savedSetup => copy
  useEffect(() => {
    if (savedSetup) {
      setAvailableDays(savedSetup.availableDays || defaultAvailableDays);
      setStartTime(savedSetup.startTime || '09:30');
      setEndTime(savedSetup.endTime || '19:00');
      setDuration(savedSetup.slotDuration || 30);
      setBreakTime(savedSetup.breakTime || 0);
      setLunchBreakTimes(savedSetup.lunchBreakTimes || lunchBreakTimes);
      setBookingCutoffTime(savedSetup.bookingCutoffTime || 30);
      toast.info('Setup lama telah dicopy! Sila pilih berapa bulan untuk tambah slot.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSetup]);

  // Pastikan monthsAhead valid
  useEffect(() => {
    if (!allowedMonths.includes(monthsAhead)) {
      setMonthsAhead(allowedMonths[0]);
    }
  }, [therapist.subscriptionPlan, monthsAhead, allowedMonths]);

  // Toggle day
  const handleToggleDay = (day) => {
    setAvailableDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const handleLunchBreakChange = (day, field, value) => {
    setLunchBreakTimes((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value,
      },
    }));
  };

  const validateLunchBreakTimes = () => {
    for (const day in lunchBreakTimes) {
      const { start, end } = lunchBreakTimes[day];
      if (start >= end) {
        toast.error(`Masa rehat ${day} tak valid (start >= end).`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!allowedMonths.includes(Number(monthsAhead))) {
      toast.error('Bulan yang dipilih tidak dibenarkan.');
      return;
    }
    if (!validateLunchBreakTimes()) {
      return;
    }

    // Tarikh mula (hari ini jam 12)
    const start = new Date();
    start.setHours(12, 0, 0, 0);
    // Tarikh akhir
    const end = new Date();
    end.setMonth(end.getMonth() + Number(monthsAhead));
    end.setHours(12, 0, 0, 0);

    // Buat slot
    const slots = generateSlots({
      startDate: start,
      endDate: end,
      availableDays,
      startTime,
      endTime,
      duration,
      breakTime,
      lunchBreakTimes,
    });

    // Update object therapist
    const updatedTherapist = {
      ...therapist,
      startTime,
      endTime,
      slotDuration: duration,
      breakTime,
      lunchBreakTimes,
      bookingCutoffTime,
    };

    // Save setup lama
    onSaveSetup({
      availableDays,
      startTime,
      endTime,
      slotDuration: duration,
      breakTime,
      lunchBreakTimes,
      bookingCutoffTime,
    });

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${config.API_URL}/slots`,
        {
          slots,
          bookingCutoffTime: Number(bookingCutoffTime),
          startTime,
          endTime,
          slotDuration: Number(duration),
          lunchBreakTimes,
          monthsAhead: Number(monthsAhead),
        },
        {
          headers: { 'x-auth-token': token },
        }
      );

      if (response.status === 201) {
        toast.success('Slot berjaya ditambah!');
        onSave(updatedTherapist);
        onClose();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error saving slots:', err);
      toast.error('Gagal menambah slot. Sila cuba lagi.');
    }
  };

  // Generate Slots
  const generateSlots = ({
    startDate,
    endDate,
    availableDays,
    startTime,
    endTime,
    duration,
    breakTime,
    lunchBreakTimes,
  }) => {
    const slots = [];
    const timeZone = 'Asia/Kuala_Lumpur';
    const today = startOfDay(toZonedTime(new Date(), timeZone));

    for (let date = new Date(startDate); date <= endDate; date = addDays(date, 1)) {
      const zonedDate = toZonedTime(date);
      const currentDate = startOfDay(zonedDate);
      if (currentDate < today) continue;

      const dayName = format(zonedDate, 'EEEE', { timeZone }).toLowerCase();
      if (availableDays[dayName]) {
        // Generate daily slots
        const dailySlots = generateDailySlots(zonedDate, {
          startTime,
          endTime,
          duration,
          breakTime,
          lunchBreakStart: lunchBreakTimes[dayName].start,
          lunchBreakEnd: lunchBreakTimes[dayName].end,
        });
        // Tambah rawatan + therapistId
        dailySlots.forEach((slot) => {
          slot.therapistId = therapist._id;
          slot.treatment = treatment;
        });
        slots.push(...dailySlots);
      }
    }
    return slots;
  };

  const generateDailySlots = (
    date,
    { startTime, endTime, duration, breakTime, lunchBreakStart, lunchBreakEnd }
  ) => {
    const slots = [];
    let currentTime = startTime;

    while (isTimeLessThan(currentTime, endTime)) {
      // Check lunch break
      if (
        isTimeGreaterThanOrEqual(currentTime, lunchBreakStart) &&
        isTimeLessThan(currentTime, lunchBreakEnd)
      ) {
        currentTime = lunchBreakEnd;
        continue;
      }
      const slotEndTime = addMinutes(currentTime, duration);
      if (isTimeGreaterThan(slotEndTime, endTime)) break;

      const dateStr = formatDate(date, 'yyyy-MM-dd');
      slots.push({
        date: dateStr,
        startTime: currentTime,
        endTime: slotEndTime,
      });

      // Tambah break
      currentTime = addMinutes(slotEndTime, breakTime);
    }

    return slots;
  };

  const addMinutes = (time, mins) => {
    const [hh, mm] = time.split(':').map(Number);
    const total = hh * 60 + mm + Number(mins);
    const newH = Math.floor(total / 60);
    const newM = total % 60;
    return `${String(newH).padStart(2, '0')}:${String(newM).padStart(2, '0')}`;
  };

  const isTimeLessThan = (t1, t2) => {
    const [h1, m1] = t1.split(':').map(Number);
    const [h2, m2] = t2.split(':').map(Number);
    return h1 < h2 || (h1 === h2 && m1 < m2);
  };

  const isTimeGreaterThan = (t1, t2) => {
    const [h1, m1] = t1.split(':').map(Number);
    const [h2, m2] = t2.split(':').map(Number);
    return h1 > h2 || (h1 === h2 && m1 > m2);
  };

  const isTimeGreaterThanOrEqual = (t1, t2) => {
    const [h1, m1] = t1.split(':').map(Number);
    const [h2, m2] = t2.split(':').map(Number);
    return h1 > h2 || (h1 === h2 && m1 >= m2);
  };

  return (
    <form onSubmit={handleSubmit} className="edit-slot-form">
      <h2>Edit Slot Tersedia: {treatment}</h2>

      <div className="form-group">
        <label>Tambahkan slot untuk berapa bulan ke depan?</label>
        <select
          value={monthsAhead}
          onChange={(e) => setMonthsAhead(Number(e.target.value))}
        >
          {allowedMonths.map((m) => (
            <option key={m} value={m}>{m} Bulan</option>
          ))}
        </select>
        {therapist.subscriptionPlan !== 'plus' && allowedMonths.length === 1 && (
          <p style={{ color: 'blue' }}>
            Hanya langganan <strong>Plus</strong> boleh lebih dari 1 bulan.
          </p>
        )}
      </div>

      <div className="form-group">
        <label>Hari Tersedia:</label>
        <div className="toggle-group">
          {Object.keys(availableDays).map((day) => (
            <div key={day} className="toggle-item">
              <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={availableDays[day]}
                  onChange={() => handleToggleDay(day)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="form-group">
        <label>Masa Mula:</label>
        <input
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Masa Tamat:</label>
        <input
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Durasi per Slot (minit):</label>
        <input
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          min={15}
          step={15}
        />
      </div>

      <div className="form-group">
        <label>Masa Rehat antara Slot (minit):</label>
        <input
          type="number"
          value={breakTime}
          onChange={(e) => setBreakTime(Number(e.target.value))}
          min={0}
          step={5}
        />
      </div>

      <div className="form-group">
        <label>Masa Rehat Makan Tengah Hari:</label>
        {Object.keys(lunchBreakTimes)
          .filter((day) => availableDays[day]) // hanya hari yang dipilih
          .map((day) => (
            <div key={day} className="lunch-break-group">
              <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
              <div className="time-inputs">
                <label>
                  Mula:
                  <input
                    type="time"
                    value={lunchBreakTimes[day].start}
                    onChange={(e) => handleLunchBreakChange(day, 'start', e.target.value)}
                    required
                  />
                </label>
                <label>
                  Tamat:
                  <input
                    type="time"
                    value={lunchBreakTimes[day].end}
                    onChange={(e) => handleLunchBreakChange(day, 'end', e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
          ))}
      </div>

      <div className="form-group">
        <label>Masa Cutoff Tempahan (minit):</label>
        <input
          type="number"
          value={bookingCutoffTime}
          onChange={(e) => setBookingCutoffTime(Number(e.target.value))}
          min={0}
        />
      </div>

      <div className="form-actions">
        <button type="submit" className="save-button">
          Simpan Slot
        </button>
        <button type="button" onClick={onClose} className="cancel-button">
          Batal
        </button>
      </div>
    </form>
  );
}

export default EditSlotForm;
