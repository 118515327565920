// App.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSignInAlt, faUserPlus, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';

import TherapistList from './components/TherapistList';
import BookingForm from './components/BookingForm';
import Login from './components/Login';
import TherapistDashboard from './components/TherapistDashboard';
import ChangePassword from './components/ChangePassword';
import AdminDashboard from './components/AdminDashboard';
import RegisterForm from './components/RegisterForm';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import AdminLogin from './components/AdminLogin';
import PublicTherapistPage from './components/PublicTherapistPage';
import UserDashboard from './components/UserDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from './config';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('therapistList');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [contactCounts, setContactCounts] = useState({ prospect: 0, customer: 0, vvip: 0 });
  const [searchTerm, setSearchTerm] = useState('');

  // Fungsi untuk ambil contact counts
  const fetchContactCounts = async (therapistId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/prospects/count/${therapistId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching contact counts:', error);
      return { prospect: 0, customer: 0, vvip: 0 };
    }
  };

  // Ambil contactCounts jika therapistId ada
  useEffect(() => {
    const getCounts = async () => {
      const therapistId = localStorage.getItem('therapistId');
      if (therapistId) {
        const counts = await fetchContactCounts(therapistId);
        setContactCounts(counts);
      }
    };
    getCounts();
  }, []);

  const handleRegisterSuccess = () => {
    setActiveTab('therapistList');
    navigate('/therapist-list');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsSidebarOpen(false);
    switch (tab) {
      case 'therapistList':
        navigate('/therapist-list');
        break;
      case 'login':
        navigate('/login');
        break;
      case 'register':
        navigate('/register');
        break;
      default:
        navigate('/');
    }
  };

  const handleOpenModal = (therapist) => {
    setSelectedTherapist(therapist);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTherapist(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Komponen dynamic route /therapist/:therapistId
  const TherapistBooking = () => {
    const { therapistId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [localIsModalOpen, setLocalIsModalOpen] = useState(false);
    const [localSelectedTherapist, setLocalSelectedTherapist] = useState(null);

    useEffect(() => {
      const fetchTherapistById = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${config.API_URL}/therapists/${therapistId}`);
          setLocalSelectedTherapist(response.data);
          setLocalIsModalOpen(true);
        } catch (error) {
          console.error('Error fetching therapist details:', error);
          setError('Gagal memuat data terapis');
        } finally {
          setIsLoading(false);
        }
      };
      fetchTherapistById();
    }, [therapistId]);

    const handleLocalClose = () => {
      setLocalIsModalOpen(false);
      setLocalSelectedTherapist(null);
      navigate('/therapist-list');
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
      localIsModalOpen && localSelectedTherapist && (
        <div className="modal-overlay" onClick={handleLocalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-button"
              onClick={handleLocalClose}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              ✕
            </button>
            <BookingForm
              selectedTherapist={localSelectedTherapist}
              onClose={handleLocalClose}
            />
          </div>
        </div>
      )
    );
  };

  /*
    Sembunyikan navigation hanya jika path menepati:
    1) BUKAN route known macam /therapist-list, /login, dsb.
    2) DAN path itu ringkas ("/xxxx") => kita anggap slug public.

    Negative lookahead (?!...) => tak match "therapist-list" / "login" / dsb.
  */
  const isPublicSlugRoute = /^\/(?!therapist-list|login|register|admin-dashboard|admin-login|therapist-dashboard|change-password|reset-password|forgot-password|therapist)\w[\w-]*$/.test(
    location.pathname
  );

  // Hal-hal lain macam /therapist-dashboard, /admin-dashboard pun disorok, ikut code lama
  const shouldShowNavigation =
    !isPublicSlugRoute &&
    location.pathname !== '/therapist-dashboard' &&
    location.pathname !== '/admin-dashboard' &&
    location.pathname !== '/admin-login';

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="App">
      <header className="main-header">
        <div className="header-content">
          {/* Filter carian di /therapist-list */}
          {location.pathname === '/therapist-list' && (
            <div className="search-filter-header">
              <input
                type="text"
                placeholder="Cari therapist mengikut nama, lokasi, perkhidmatan..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="header-search-input"
              />
            </div>
          )}
        </div>

        {/* Tunjuk butang sidebar kalau shouldShowNavigation */}
        {shouldShowNavigation && (
          <button className="menu-toggle" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
          </button>
        )}
      </header>

      {/* Desktop nav (hanya kalau shouldShowNavigation true) */}
      {shouldShowNavigation && (
        <nav className="desktop-nav">
          <button
            className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
            onClick={() => handleTabChange('therapistList')}
          >
            <FontAwesomeIcon icon={faList} /> Senarai Therapist
          </button>
          <button
            className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabChange('login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </button>
          <button
            className={`nav-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabChange('register')}
          >
            <FontAwesomeIcon icon={faUserPlus} /> New Register
          </button>
        </nav>
      )}

      {/* Sidebar (mobile) */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <nav>
          <button className="close-sidebar" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
            onClick={() => handleTabChange('therapistList')}
          >
            <FontAwesomeIcon icon={faList} /> Senarai Therapist
          </button>
          <button
            className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabChange('login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </button>
          <button
            className={`nav-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabChange('register')}
          >
            <FontAwesomeIcon icon={faUserPlus} /> New Register
          </button>
        </nav>
      </div>

      {/* Content utama */}
      <main className="main-content">
        <Routes>
          {/* root => redirect ke /therapist-list */}
          <Route path="/" element={<Navigate to="/therapist-list" />} />

          {/* senarai therapist */}
          <Route
            path="/therapist-list"
            element={<TherapistList onBooking={handleOpenModal} searchTerm={searchTerm} />}
          />

          {/* login/register */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterForm onRegisterSuccess={handleRegisterSuccess} />} />

          {/* admin & therapist dashboard */}
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/therapist-dashboard" element={<TherapistDashboard />} />

          {/* password reset */}
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* booking detail route */}
          <Route path="/therapist/:therapistId" element={<TherapistBooking />} />

          {/* Public Page Slug => TIDAK tunjuk nav */}
          <Route path="/:therapistSlug" element={<PublicTherapistPage />} />
          <Route path="/user-dashboard" element={<UserDashboard />} />
        </Routes>
      </main>

      {/* Booking modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <BookingForm selectedTherapist={selectedTherapist} onClose={handleCloseModal} />
          </div>
        </div>
      )}

      {/* Toastify container */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
