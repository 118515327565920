// SalesForm.js
import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import './SalesForm.css';
import { toast } from 'react-toastify';
import config from '../config';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners'; // Import spinner

function SalesForm({ booking, onClose, onSalesSubmitted, salesData }) {
  const [therapists, setTherapists] = useState([
    {
      therapistName: '',
      treatment: '',
      salesAmount: '',
      isNewTherapist: false,
      isNewTreatment: false,
      newTherapistName: '',
      newTreatmentName: ''
    }
  ]);

  const [saleDate, setSaleDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // yyyy-mm-dd
  });

  const [availableTherapistNames, setAvailableTherapistNames] = useState([]);
  const [availableTreatments, setAvailableTreatments] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Status loading

  // Jika ada salesData (mod edit), pramuat data
  useEffect(() => {
    if (salesData) {
      // Bentuk data sedia ada ke dalam state therapists
      const mappedTherapists = salesData.therapists.map((t) => ({
        therapistName: t.therapist.name,
        treatment: t.treatment.name,
        salesAmount: t.salesAmount.toString(),
        isNewTherapist: false,
        isNewTreatment: false,
        newTherapistName: '',
        newTreatmentName: ''
      }));
      setTherapists(mappedTherapists);

      // Tetapkan tarikh saleDate jika ada
      if (salesData.saleDate) {
        const dateObj = new Date(salesData.saleDate);
        const formattedDate = dateObj.toISOString().split('T')[0];
        setSaleDate(formattedDate);
      }
    }
  }, [salesData]);

  // Fetch senarai therapist & rawatan
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const therapistId = booking.therapistId;

        const [therapistRes, treatmentRes] = await Promise.all([
          axios.get(`${config.API_URL}/api/namaTherapistSales/${therapistId}`, {
            headers: { 'x-auth-token': token },
          }),
          axios.get(`${config.API_URL}/api/namaRawatanSales/${therapistId}`, {
            headers: { 'x-auth-token': token },
          }),
        ]);

        if (Array.isArray(therapistRes.data)) {
          setAvailableTherapistNames(therapistRes.data.map(t => t.name));
        } else {
          console.error('namaTherapistSales response is not an array:', therapistRes.data);
          toast.error('Data therapist tidak sah diterima dari server.');
        }

        if (Array.isArray(treatmentRes.data)) {
          setAvailableTreatments(treatmentRes.data.map(t => t.name));
        } else {
          console.error('namaRawatanSales response is not an array:', treatmentRes.data);
          toast.error('Data rawatan tidak sah diterima dari server.');
        }
      } catch (error) {
        console.error('Error fetching therapists or treatments:', error);
        toast.error('Gagal memuatkan data therapist atau rawatan.');
      }
    };

    if (booking && booking.therapistId) {
      fetchData();
    }
  }, [booking.therapistId, booking]);

  const uniqueTherapistNames = useMemo(() => {
    return [...new Set(availableTherapistNames)];
  }, [availableTherapistNames]);

  const uniqueTreatments = useMemo(() => {
    return [...new Set(availableTreatments)];
  }, [availableTreatments]);

  // Fungsi Pembantu untuk Konfirmasi
  const confirmAction = (message) => {
    return window.confirm(message);
  };

  const handleAddNewTherapist = async (name) => {
    if (!confirmAction(`Adakah anda pasti ingin menambah therapist "${name}" baru?`)) {
      return;
    }

    if (!name) {
      toast.error('Nama Therapist tidak boleh kosong.');
      return;
    }

    const therapistId = booking.therapistId;
    if (!therapistId) {
      toast.error('Therapist ID tidak wujud.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${config.API_URL}/api/namaTherapistSales`,
        { name, therapistId },
        { headers: { 'x-auth-token': token } }
      );

      setAvailableTherapistNames(prev => [...prev, response.data.name]);
      toast.success(`Therapist "${response.data.name}" berjaya ditambah.`);
    } catch (error) {
      console.error('Error adding NamaTherapistSales:', error);
      const errorMessage = error.response?.data?.msg || 'Gagal menambah therapist.';
      toast.error(errorMessage);
    }
  };

  const handleAddNewTreatment = async (name) => {
    if (!confirmAction(`Adakah anda pasti ingin menambah rawatan "${name}" baru?`)) {
      return;
    }

    if (!name) {
      toast.error('Nama Rawatan tidak boleh kosong.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const therapistId = booking.therapistId;

      const response = await axios.post(`${config.API_URL}/api/namaRawatanSales`,
        { name, therapistId },
        { headers: { 'x-auth-token': token } }
      );

      setAvailableTreatments(prev => [...prev, response.data.name]);
      toast.success(`Rawatan "${response.data.name}" berjaya ditambah.`);
    } catch (error) {
      console.error('Error adding NamaRawatanSales:', error);
      const errorMessage = error.response?.data?.msg || 'Gagal menambah rawatan.';
      toast.error(errorMessage);
    }
  };

  const handleDeleteTherapistName = async (name) => {
    if (!confirmAction(`Adakah anda pasti mahu memadam therapist "${name}"?`)) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const therapist = await axios.get(`${config.API_URL}/api/namaTherapistSales/${booking.therapistId}`, {
        headers: { 'x-auth-token': token },
      }).then(res => res.data.find(t => t.name === name));

      if (!therapist) {
        toast.error('Nama Therapist tidak dijumpai.');
        return;
      }

      await axios.delete(`${config.API_URL}/api/namaTherapistSales/${therapist._id}`, {
        headers: { 'x-auth-token': token },
      });

      setAvailableTherapistNames(prev => prev.filter(n => n !== name));
      setTherapists(prev => prev.map(t => t.therapistName === name ? { ...t, therapistName: '', isNewTherapist: false } : t));
      toast.success(`Therapist "${name}" berjaya dipadam.`);
    } catch (error) {
      console.error('Error deleting NamaTherapistSales:', error);
      const errorMessage = error.response?.data?.msg || 'Gagal memadam therapist.';
      toast.error(errorMessage);
    }
  };

  const handleDeleteTreatment = async (name) => {
    if (!confirmAction(`Adakah anda pasti mahu memadam rawatan "${name}"?`)) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const treatment = await axios.get(`${config.API_URL}/api/namaRawatanSales/${booking.therapistId}`, {
        headers: { 'x-auth-token': token },
      }).then(res => res.data.find(t => t.name === name));

      if (!treatment) {
        toast.error('Nama Rawatan tidak dijumpai.');
        return;
      }

      await axios.delete(`${config.API_URL}/api/namaRawatanSales/${treatment._id}`, {
        headers: { 'x-auth-token': token },
      });

      setAvailableTreatments(prev => prev.filter(tt => tt !== name));
      setTherapists(prev => prev.map(t => t.treatment === name ? { ...t, treatment: '', isNewTreatment: false } : t));
      toast.success(`Rawatan "${name}" berjaya dipadam.`);
    } catch (error) {
      console.error('Error deleting NamaRawatanSales:', error);
      const errorMessage = error.response?.data?.msg || 'Gagal memadam rawatan.';
      toast.error(errorMessage);
    }
  };

  const handleAddTherapistEntry = () => {
    if (!confirmAction('Adakah anda ingin menambah entri therapist baru?')) {
      return;
    }
    setTherapists([...therapists, {
      therapistName: '',
      treatment: '',
      salesAmount: '',
      isNewTherapist: false,
      isNewTreatment: false,
      newTherapistName: '',
      newTreatmentName: ''
    }]);
  };

  const handleRemoveTherapist = (index) => {
    if (!confirmAction('Adakah anda pasti ingin menghapus entri therapist ini?')) {
      return;
    }
    const newTherapists = therapists.filter((_, i) => i !== index);
    setTherapists(newTherapists);
  };

  const handleChange = (index, field, value) => {
    const newTherapists = therapists.map((therapist, i) => {
      if (i === index) {
        if (field === 'therapistName') {
          if (value === 'Add New') {
            return { ...therapist, therapistName: '', isNewTherapist: true };
          } else {
            return { ...therapist, therapistName: value, isNewTherapist: false, newTherapistName: '' };
          }
        }
        if (field === 'treatment') {
          if (value === 'Add New') {
            return { ...therapist, treatment: '', isNewTreatment: true };
          } else {
            return { ...therapist, treatment: value, isNewTreatment: false, newTreatmentName: '' };
          }
        }
        return { ...therapist, [field]: value };
      }
      return therapist;
    });
    setTherapists(newTherapists);
  };

  const handleNewTherapistNameChange = (index, value) => {
    const newTherapists = therapists.map((therapist, i) => {
      if (i === index) {
        return { ...therapist, newTherapistName: value };
      }
      return therapist;
    });
    setTherapists(newTherapists);
  };

  const handleNewTreatmentNameChange = (index, value) => {
    const newTherapists = therapists.map((therapist, i) => {
      if (i === index) {
        return { ...therapist, newTreatmentName: value };
      }
      return therapist;
    });
    setTherapists(newTherapists);
  };

  const confirmAddTherapistName = async (index) => {
    const therapist = therapists[index];
    if (therapist.newTherapistName.trim() !== '') {
      await handleAddNewTherapist(therapist.newTherapistName.trim());
      const updatedTherapists = therapists.map((t, i) => {
        if (i === index) {
          return { ...t, therapistName: therapist.newTherapistName.trim(), isNewTherapist: false, newTherapistName: '' };
        }
        return t;
      });
      setTherapists(updatedTherapists);
    } else {
      toast.error('Nama Therapist Baru tidak boleh kosong.');
    }
  };

  const confirmAddTreatmentName = async (index) => {
    const therapist = therapists[index];
    if (therapist.newTreatmentName.trim() !== '') {
      await handleAddNewTreatment(therapist.newTreatmentName.trim());
      const updatedTherapists = therapists.map((t, i) => {
        if (i === index) {
          return { ...t, treatment: therapist.newTreatmentName.trim(), isNewTreatment: false, newTreatmentName: '' };
        }
        return t;
      });
      setTherapists(updatedTherapists);
    } else {
      toast.error('Nama Rawatan Baru tidak boleh kosong.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!confirmAction('Adakah anda pasti ingin menghantar borang jualan ini?')) {
      return;
    }

    // Validasi
    for (let t of therapists) {
      if (!t.therapistName || !t.treatment || !t.salesAmount) {
        toast.error('Sila lengkapkan semua medan.');
        return;
      }
      if (isNaN(Number(t.salesAmount)) || Number(t.salesAmount) <= 0) {
        toast.error('Jumlah Sales mesti nombor yang sah dan lebih besar daripada 0.');
        return;
      }
    }

    try {
      setIsLoading(true); // Mulakan loading
      const token = localStorage.getItem('token');

      // Dapatkan mapping ID
      const [therapistRes, treatmentRes] = await Promise.all([
        axios.get(`${config.API_URL}/api/namaTherapistSales/${booking.therapistId}`, { headers: { 'x-auth-token': token } }),
        axios.get(`${config.API_URL}/api/namaRawatanSales/${booking.therapistId}`, { headers: { 'x-auth-token': token } }),
      ]);

      if (!Array.isArray(therapistRes.data) || !Array.isArray(treatmentRes.data)) {
        toast.error('Data dari server tidak sah.');
        setIsLoading(false);
        return;
      }

      const therapistMap = therapistRes.data.reduce((acc, curr) => {
        acc[curr.name] = curr._id;
        return acc;
      }, {});
      const treatmentMap = treatmentRes.data.reduce((acc, curr) => {
        acc[curr.name] = curr._id;
        return acc;
      }, {});

      const payload = {
        booking: booking._id,
        therapists: therapists.map(t => ({
          therapist: therapistMap[t.therapistName],
          treatment: treatmentMap[t.treatment],
          salesAmount: Number(t.salesAmount),
        })),
        saleDate
      };

      // Semak jika ada therapist atau rawatan yang tak jumpa ID
      if (payload.therapists.some(t => !t.therapist || !t.treatment)) {
        toast.error('Gagal mencari ID untuk nama therapist atau rawatan.');
        setIsLoading(false);
        return;
      }

      if (salesData) {
        // Mod Edit (PUT)
        await axios.put(`${config.API_URL}/api/sales/${salesData._id}`, payload, {
          headers: { 'x-auth-token': token },
        });
        toast.success('Data jualan berjaya dikemaskini.');
      } else {
        // Mod Baru (POST)
        await axios.post(`${config.API_URL}/api/sales`, payload, {
          headers: { 'x-auth-token': token },
        });
        toast.success('Data jualan berjaya disimpan.');
      }

      onSalesSubmitted();
      onClose();
    } catch (error) {
      console.error('Error saving/updating sales data:', error.response ? error.response.data : error);
      if (error.response) {
        toast.error(error.response.data.msg || 'Gagal menyimpan data jualan.');
      } else if (error.request) {
        toast.error('Tidak dapat menyambung ke server. Sila cuba lagi.');
      } else {
        toast.error('Ralat tidak dijangka berlaku.');
      }
    } finally {
      setIsLoading(false); // Hentikan loading
    }
  };

  const preventSubmitOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="sales-form-container">
      <h2 className="sales-form-title">Sales Form</h2>
      <div className="sales-form-section">
        <h3 className="sales-form-section-title">Bahagian A: Detail Tempahan</h3>
        <p><strong>Nama Klien:</strong> {booking.userName}</p>
        <p><strong>Tarikh Tempahan:</strong> {new Date(booking.bookingDate).toLocaleDateString()}</p>
        <p><strong>Rawatan:</strong> {booking.treatment}</p>
        {/* Maklumat tambahan jika perlu */}
      </div>
      <div className="sales-form-section">
        <h3 className="sales-form-section-title">Bahagian B: Sales Details</h3>
        {therapists.map((therapist, index) => (
          <div key={index} className="sales-form-therapist-entry">
            <h4 className="therapist-entry-title">Therapist {index + 1}</h4>

            {/* Nama Therapist */}
            <label className="sales-form-label">Nama Therapist:</label>
            <div className="select-with-button">
              <select
                value={therapist.isNewTherapist ? '' : therapist.therapistName}
                onChange={(e) => handleChange(index, 'therapistName', e.target.value)}
                required
                className="sales-form-select"
                disabled={isLoading} // Nonaktifkan semasa loading
              >
                <option value="">-- Pilih Therapist --</option>
                {uniqueTherapistNames.map((name, idx) => (
                  <option key={idx} value={name}>{name}</option>
                ))}
                <option value="Add New">Tambah Baru</option>
              </select>
              {therapist.isNewTherapist && (
                <div className="new-entry-container">
                  <input
                    type="text"
                    placeholder="Nama Therapist Baru"
                    value={therapist.newTherapistName}
                    onChange={(e) => handleNewTherapistNameChange(index, e.target.value)}
                    required
                    onKeyDown={preventSubmitOnEnter}
                    className="sales-form-input"
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => confirmAddTherapistName(index)}
                    className="add-button"
                    disabled={isLoading}
                  >
                    <FaPlus /> Tambah
                  </button>
                </div>
              )}
            </div>

            {therapist.therapistName && !therapist.isNewTherapist && (
              <button
                type="button"
                onClick={() => handleDeleteTherapistName(therapist.therapistName)}
                className="sales-form-delete-button"
                title="Padam Nama Therapist"
                disabled={isLoading}
              >
                <FaTrash /> Padam Therapist
              </button>
            )}

            {/* Rawatan */}
            <label className="sales-form-label">Rawatan:</label>
            <div className="select-with-button">
              <select
                value={therapist.isNewTreatment ? '' : therapist.treatment}
                onChange={(e) => handleChange(index, 'treatment', e.target.value)}
                required
                className="sales-form-select"
                disabled={isLoading}
              >
                <option value="">-- Pilih Rawatan --</option>
                {uniqueTreatments.map((t, idx) => (
                  <option key={idx} value={t}>{t}</option>
                ))}
                <option value="Add New">Tambah Baru</option>
              </select>
              {therapist.isNewTreatment && (
                <div className="new-entry-container">
                  <input
                    type="text"
                    placeholder="Rawatan Baru"
                    value={therapist.newTreatmentName}
                    onChange={(e) => handleNewTreatmentNameChange(index, e.target.value)}
                    required
                    onKeyDown={preventSubmitOnEnter}
                    className="sales-form-input"
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => confirmAddTreatmentName(index)}
                    className="add-button"
                    disabled={isLoading}
                  >
                    <FaPlus /> Tambah
                  </button>
                </div>
              )}
            </div>

            {therapist.treatment && !therapist.isNewTreatment && (
              <button
                type="button"
                onClick={() => handleDeleteTreatment(therapist.treatment)}
                className="sales-form-delete-button"
                title="Padam Rawatan"
                disabled={isLoading}
              >
                <FaTrash /> Padam Rawatan
              </button>
            )}

            {/* Tarikh Jualan */}
            <label className="sales-form-label">Tarikh Jualan:</label>
            <input
              type="date"
              value={saleDate}
              onChange={(e) => setSaleDate(e.target.value)}
              required
              className="sales-form-input"
              disabled={isLoading}
            />

            {/* Jumlah Sales */}
            <label className="sales-form-label">Jumlah Sales (RM):</label>
            <input
              type="number"
              placeholder="Jumlah Sales (RM)"
              value={therapist.salesAmount}
              onChange={(e) => handleChange(index, 'salesAmount', e.target.value)}
              required
              onKeyDown={preventSubmitOnEnter}
              min="0"
              step="0.01"
              className="sales-form-input"
              disabled={isLoading}
            />

            {therapists.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveTherapist(index)}
                className="sales-form-remove-therapist-button"
                title="Padam Therapist"
                disabled={isLoading}
              >
                <FaTrash /> Padam Therapist
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddTherapistEntry}
          className="sales-form-add-therapist-button"
          disabled={isLoading}
        >
          <FaPlus /> Tambah Therapist
        </button>
      </div>
      <div className="sales-form-actions">
        <button
          type="submit"
          className="sales-form-submit-button"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="spinner-container">
              <ClipLoader size={20} color={"#ffffff"} />
              <span className="spinner-text">Menghantar...</span>
            </div>
          ) : (
            'Simpan'
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            if (confirmAction('Adakah anda pasti ingin membatalkan perubahan?')) {
              onClose();
            }
          }}
          className="sales-form-cancel-button"
          disabled={isLoading}
        >
          Batal
        </button>
      </div>
    </form>
  );
}

export default SalesForm;
