// src/components/AvailableSlot.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './AvailableSlot.css';
import EditSlotForm from './EditSlotForm';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, isSameDay, startOfDay } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';
import Spinner from './Spinner'; // Pastikan import Spinner jika digunakan

function AvailableSlot({ therapist }) {
  // Pastikan slotDuration adalah Number
  const [therapistState, setTherapistState] = useState(() => ({
    ...therapist,
    slotDuration: Number(therapist.slotDuration),
  }));

  // Senarai rawatan
  const [treatments, setTreatments] = useState([]);
  // Slot yang disusun ikut rawatan
  const [slotsByTreatment, setSlotsByTreatment] = useState({});
  // Modal edit
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  // Filter date di calendar
  const [filterDate, setFilterDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [highlightDates, setHighlightDates] = useState([]);

  // Polling & loading
  const [polling, setPolling] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // **State untuk simpan 'setup lama'** (hari off, lunch break, dsb.)
  const [savedSetup, setSavedSetup] = useState(null);

  // Ambil senarai rawatan
  const fetchTreatments = useCallback(() => {
    const treatmentsData = therapist?.jenisRawatan?.rawatanLain || [];
    setTreatments(treatmentsData);
  }, [therapist]);

  // Ambil slot
  const fetchSlots = useCallback(async () => {
    if (!polling) return;
    setIsLoading(true);

    try {
      const response = await axios.get(`${config.API_URL}/slots/${therapist._id}`);
      const slotsData = response.data;

      const today = startOfDay(new Date());
      const groupedByTreatment = {};

      slotsData.forEach((slot) => {
        const slotDate = parseISO(slot.date);
        // Tunjukkan slot yang >= hari ini
        if (slotDate >= today) {
          if (!groupedByTreatment[slot.treatment]) {
            groupedByTreatment[slot.treatment] = [];
          }
          groupedByTreatment[slot.treatment].push(slot);
        }
      });

      // Susun slot mengikut tarikh + masa
      Object.keys(groupedByTreatment).forEach((treatmentName) => {
        groupedByTreatment[treatmentName].sort((a, b) => {
          const aDateTime = new Date(`${a.date}T${a.startTime}`);
          const bDateTime = new Date(`${b.date}T${b.startTime}`);
          return aDateTime - bDateTime;
        });
      });

      if (Object.keys(groupedByTreatment).length === 0) {
        toast.info('Sila tambah slot available anda di sini.');
      }

      setSlotsByTreatment(groupedByTreatment);
    } catch (error) {
      console.error('Error fetching slots:', error);
      setPolling(false);
      if (error.response && error.response.status !== 404) {
        toast.error('Gagal mendapatkan slot. Sila cuba lagi.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [therapist._id, polling]);

  useEffect(() => {
    if (therapist) {
      fetchTreatments();
      fetchSlots();
    }
    return () => {
      setPolling(false); // hentikan polling bila unmount
    };
  }, [therapist, fetchTreatments, fetchSlots]);

  // Highlight dates
  useEffect(() => {
    const dates = [];
    const today = startOfDay(new Date());
    Object.values(slotsByTreatment).forEach((slotsArr) => {
      slotsArr.forEach((slot) => {
        const date = parseISO(slot.date);
        if (date >= today && !slot.isBooked) {
          dates.push(date);
        }
      });
    });
    setHighlightDates(dates);
  }, [slotsByTreatment]);

  // Buka edit slot
  const handleEditSlot = (treatmentName) => {
    setSelectedTreatment(treatmentName);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedTreatment(null);
  };

  // Padam 1 slot
  const handleDeleteSlot = async (slotId) => {
    if (!window.confirm('Adakah anda pasti mahu memadam slot ini?')) return;
    try {
      await axios.delete(`${config.API_URL}/slots/${slotId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      toast.success('Slot berjaya dipadam.');
      fetchSlots();
    } catch (error) {
      console.error('Error deleting slot:', error);
      toast.error('Gagal memadam slot. Sila cuba lagi.');
    }
  };

  // Padam semua slot untuk sesuatu rawatan
  const handleClearSlots = async (treatmentName) => {
    if (!window.confirm(`Adakah anda pasti mahu memadam semua slot untuk ${treatmentName}?`)) return;
    try {
      await axios.delete(`${config.API_URL}/slots/clear/${therapist._id}/${encodeURIComponent(treatmentName)}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      toast.success(`Semua slot untuk ${treatmentName} berjaya dipadam.`);
      fetchSlots();
    } catch (error) {
      console.error('Error clearing slots:', error);
      toast.error('Gagal memadam semua slot. Sila cuba lagi.');
    }
  };

  // Pilih tarikh di DatePicker
  const handleDateSelect = (date) => {
    setFilterDate(date);
    setShowCalendar(false);
  };

  // **Baru**: Simpan setup lama
  const handleSaveSetup = (setupData) => {
    setSavedSetup(setupData);
    toast.success('Setup slot lama telah disimpan!');
  };

  // **Baru**: Copy setup lama
  const handleCopyOldSetup = (treatmentName) => {
    if (!savedSetup) {
      toast.error('Tiada setup lama untuk di-copy. Sila buat setup sekali dahulu.');
      return;
    }
    setSelectedTreatment(treatmentName);
    setShowEditModal(true);
  };

  return (
    <div className="available-slot">
      <h2>Slot Tersedia</h2>

      {/* Butang buka calendar */}
      <button onClick={() => setShowCalendar(true)} className="calendar-button">
        Pilih Tarikh
      </button>

      {/* Calendar modal */}
      {showCalendar && (
        <div className="calendar-modal" onClick={() => setShowCalendar(false)}>
          <div className="calendar-container" onClick={(e) => e.stopPropagation()}>
            <ReactDatePicker
              selected={filterDate}
              onChange={handleDateSelect}
              inline
              highlightDates={highlightDates}
              dayClassName={(date) =>
                highlightDates.some((d) => isSameDay(d, date)) ? 'highlighted-date' : undefined
              }
              minDate={startOfDay(new Date())}
              filterDate={(date) => highlightDates.some((d) => isSameDay(d, date))}
            />
          </div>
        </div>
      )}

      {/* Spinner */}
      {isLoading ? (
        <div className="spinner-container">
          <Spinner type="ClipLoader" size={50} color="#2980b9" label="Memuatkan slot..." />
        </div>
      ) : (
        <div className="treatment-card-container">
          {treatments.map((treatmentName, idx) => (
            <div key={idx} className="treatment-card">
              <h3>{treatmentName}</h3>
              <button onClick={() => handleEditSlot(treatmentName)} className="edit-slot-button">
                Edit Slot
              </button>
              <button onClick={() => handleClearSlots(treatmentName)} className="clear-slots-button">
                Padam Semua Slot
              </button>
              {/* Baru: butang copy setup lama */}
              <button onClick={() => handleCopyOldSetup(treatmentName)} className="copy-old-setup-button">
                Copy Setup Lama
              </button>

              <div className="slot-list">
                {slotsByTreatment[treatmentName] && slotsByTreatment[treatmentName].length > 0 ? (
                  // Group by date
                  Object.entries(
                    slotsByTreatment[treatmentName].reduce((acc, slot) => {
                      // filter ikut tarikh
                      if (filterDate && !isSameDay(parseISO(slot.date), filterDate)) return acc;
                      if (!acc[slot.date]) acc[slot.date] = [];
                      acc[slot.date].push(slot);
                      return acc;
                    }, {})
                  ).map(([date, slotsArr]) => (
                    <div key={date} className="date-group">
                      <h4>{date}</h4>
                      {slotsArr.map((slot) => (
                        <div key={slot._id} className="slot-item">
                          {slot.startTime} - {slot.endTime} {slot.isBooked ? '(Ditempah)' : ''}
                          <button
                            className="delete-slot-button"
                            onClick={() => handleDeleteSlot(slot._id)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <p>Tiada slot tersedia untuk rawatan ini.</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal Edit Slot */}
      {showEditModal && selectedTreatment && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <EditSlotForm
              therapist={therapistState}
              treatment={selectedTreatment}
              onClose={handleCloseModal}
              onSave={(updatedTherapist) => {
                // Merge data
                setTherapistState((prev) => ({
                  ...prev,
                  ...updatedTherapist,
                  slotDuration: Number(updatedTherapist.slotDuration),
                }));
                // Refresh slot
                setTimeout(() => {
                  fetchSlots();
                  toast.success('Slot berjaya ditambah.');
                }, 0);
              }}
              // Pass setup lama dan callback
              savedSetup={savedSetup}
              onSaveSetup={handleSaveSetup}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AvailableSlot;
