// src/components/WhatsappIntegration.jsx

import React, { useState, useEffect, useRef } from 'react';
import QRCode from "react-qr-code";
import './WhatsappIntegration.css';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WhatsappIntegration = ({ therapistId }) => {
  const [qrCodeData, setQrCodeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('disconnected');
  const [showGetQrButton, setShowGetQrButton] = useState(false);
  const sessionId = therapistId;
  const terminateTimeoutRef = useRef(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [sendBookingMessage, setSendBookingMessage] = useState(false);
  const [sendCancelMessage, setSendCancelMessage] = useState(false);
  const [bookingMessageTemplate, setBookingMessageTemplate] = useState('');
  const [cancelMessageTemplate, setCancelMessageTemplate] = useState('');

  const STATUS = {
    DISCONNECTED: 'disconnected',
    SESSION_STARTED: 'session_started',
    QR_PENDING: 'qr_pending',
    CONNECTED: 'connected',
    FAILED: 'failed',
  };

  const isSavingRef = useRef(false);
  const [isPollingActive, setIsPollingActive] = useState(false);

  const getNoCacheHeaders = () => ({
    'x-auth-token': localStorage.getItem('token'),
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  const fetchMessageTemplates = async () => {
    try {
      const response = await axios.get('/api/therapists/message-templates', {
        headers: getNoCacheHeaders(),
      });
      const templates = response.data.data;
      setSendBookingMessage(templates.sendBookingMessage);
      setBookingMessageTemplate(templates.bookingMessageTemplate);
      setSendCancelMessage(templates.sendCancelMessage);
      setCancelMessageTemplate(templates.cancelMessageTemplate);
    } catch (error) {
      console.error('Error fetching message templates:', error);
      toast.error('Gagal mengambil template pesan. Sila cuba lagi.');
    }
  };

  useEffect(() => {
    const checkInitialStatus = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
          headers: getNoCacheHeaders(),
        });
        let currentStatus = response.data.status;
        currentStatus = currentStatus ? currentStatus.toLowerCase() : null;

        if (!currentStatus) {
          setStatus(STATUS.DISCONNECTED);
          toast.info('Tiada sesi aktif. Sila sambung WhatsApp untuk memulakan sesi baru.');
        } else if (currentStatus === STATUS.CONNECTED) {
          setStatus(STATUS.CONNECTED);
          toast.success('WhatsApp sudah berjaya disambungkan.');
        } else if (currentStatus === STATUS.FAILED || currentStatus === STATUS.DISCONNECTED) {
          setStatus(STATUS.DISCONNECTED);
          toast.info('Sambungan telah tamat. Sila sambung WhatsApp untuk memulakan semula.');
        } else if (currentStatus === STATUS.SESSION_STARTED) {
          setStatus(STATUS.SESSION_STARTED);
          toast.info('Sesi dimulakan. Sila dapatkan QR code.');
          setShowGetQrButton(true);
        } else if (currentStatus === STATUS.QR_PENDING) {
          setStatus(STATUS.QR_PENDING);
          toast.info('QR code tersedia. Sila imbas dalam masa 60 saat.');
          setIsPollingActive(true); // Mulakan polling jika QR Pending
        }

        await fetchMessageTemplates();
      } catch (error) {
        console.error('Ralat semasa menyemak status awal:', error);
        toast.error('Gagal menyemak status sesi. Sila cuba lagi.');
        setStatus(STATUS.DISCONNECTED);
      } finally {
        setLoading(false);
      }
    };

    checkInitialStatus();
  }, [sessionId]);

  useEffect(() => {
    let pollingInterval = null;

    if (status === STATUS.QR_PENDING && isPollingActive) {
      pollingInterval = setInterval(async () => {
        try {
          const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
            headers: getNoCacheHeaders(),
          });
          let currentStatus = response.data.status;
          currentStatus = currentStatus ? currentStatus.toLowerCase() : null;

          if (currentStatus === STATUS.CONNECTED) {
            setStatus(STATUS.CONNECTED);
            toast.success('WhatsApp telah disambungkan dengan berjaya.');
            setIsPollingActive(false);
            clearInterval(pollingInterval);
            if (terminateTimeoutRef.current) {
              clearTimeout(terminateTimeoutRef.current);
              terminateTimeoutRef.current = null;
            }
          } else if (currentStatus === STATUS.DISCONNECTED || currentStatus === STATUS.FAILED) {
            setIsPollingActive(false);
            clearInterval(pollingInterval);
          }
        } catch (error) {
          console.error('Ralat semasa polling status:', error);
          // Anda boleh memilih untuk memberhentikan polling jika berlaku ralat tertentu
        }
      }, 5000); // Poll setiap 5 saat
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [status, sessionId, isPollingActive]);

  useEffect(() => {
    if (status === STATUS.QR_PENDING) {
      terminateTimeoutRef.current = setTimeout(async () => {
        try {
          const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
            headers: getNoCacheHeaders(),
          });
          let currentStatus = response.data.status;
          currentStatus = currentStatus ? currentStatus.toLowerCase() : null;

          if (currentStatus !== STATUS.CONNECTED) {
            await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
              headers: getNoCacheHeaders(),
            });
            setStatus(STATUS.DISCONNECTED);
            setQrCodeData('');
            setShowGetQrButton(false);
            setIsPollingActive(false); // Hentikan polling
            toast.info('Sesi tidak aktif telah ditamatkan.');
          }
        } catch (error) {
          console.error('Ralat semasa auto-terminate:', error);
          toast.error('Gagal menamatkan sesi secara automatik.');
        }
      }, 120000); // 2 minit

      setIsPollingActive(true); // Mulakan polling apabila QR Pending

      return () => {
        if (terminateTimeoutRef.current) {
          clearTimeout(terminateTimeoutRef.current);
          terminateTimeoutRef.current = null;
        }
        setIsPollingActive(false); // Pastikan polling dihentikan apabila komponen dibuang
      };
    }
  }, [status, sessionId]);

  const handleConnectButtonClick = async () => {
    if (status !== STATUS.DISCONNECTED) {
      toast.info('Sesi sedang berjalan. Sila reset sambungan terlebih dahulu jika ingin memulakan sesi baru.');
      return;
    }

    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/start-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      setStatus(STATUS.SESSION_STARTED);
      // Bila sesi dah start, button "Dapatkan QR Code" akan appear lepas 3s
      setTimeout(() => {
        setShowGetQrButton(true);
      }, 3000);
    } catch (error) {
      console.error('Ralat semasa menyambung:', error);
      if (error.response && error.response.status === 500) {
        toast.error('Sesi lama wujud. Sila reset sambungan untuk memulakan semula.');
      } else {
        toast.error('Gagal menyambung ke WhatsApp. Sila cuba lagi.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Polling for QR Code
  const fetchQrCodeWithRetry = async (retryCount = 0) => {
    if (retryCount > 10) {
      toast.error('QR Code masih tidak tersedia. Sila reset sambungan dan cuba lagi.');
      setShowGetQrButton(true);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`/api/whatsapp/session-qr/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      const qrData = response.data.qrCodeData;

      if (qrData) {
        setQrCodeData(qrData);
        setStatus(STATUS.QR_PENDING);
        setLoading(false);
      } else {
        // Belum dapat QR code, tunggu 3s dan cuba lagi
        setTimeout(() => {
          fetchQrCodeWithRetry(retryCount + 1);
        }, 3000);
      }
    } catch (error) {
      console.error('Ralat semasa mendapatkan QR code:', error);
      toast.error('Gagal mendapatkan QR Code. Sila cuba lagi.');
      setShowGetQrButton(true);
      setLoading(false);
    }
  };

  const handleGetQrCodeClick = async () => {
    setLoading(true);
    setShowGetQrButton(false);
    await fetchQrCodeWithRetry();
    setIsPollingActive(true); // Mulakan polling selepas mengambil QR Code
  };

  const handleResetConnectionClick = async () => {
    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      setIsPollingActive(false); // Hentikan polling
      toast.success('Sambungan telah direset.');
      await fetchMessageTemplates();
    } catch (error) {
      console.error('Ralat semasa reset sambungan:', error);
      toast.error('Gagal reset sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectButtonClick = async () => {
    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      setIsPollingActive(false); // Hentikan polling
      toast.success('Sambungan telah diputuskan.');
      await fetchMessageTemplates();
    } catch (error) {
      console.error('Ralat semasa putuskan sambungan:', error);
      toast.error('Gagal putuskan sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setIsEditing(true);
  };

  const handleCancelEdit = async () => {
    setEditMode(false);
    setIsEditing(false);
    await fetchMessageTemplates();
  };

  const handleSendBookingMessageToggle = (e) => {
    setSendBookingMessage(e.target.checked);
  };

  const handleSendCancelMessageToggle = (e) => {
    setSendCancelMessage(e.target.checked);
  };

  const handleBookingMessageTemplateChange = (e) => {
    setBookingMessageTemplate(e.target.value);
  };

  const handleCancelMessageTemplateChange = (e) => {
    setCancelMessageTemplate(e.target.value);
  };

  const handleSaveTemplates = async () => {
    try {
      setLoading(true);
      isSavingRef.current = true;
      const payload = {
        sendBookingMessage,
        bookingMessageTemplate,
        sendCancelMessage,
        cancelMessageTemplate,
      };

      if (sendBookingMessage && (!bookingMessageTemplate || bookingMessageTemplate.trim() === '')) {
        toast.error('Template pesan booking diperlukan ketika toggle dihidupkan.');
        setLoading(false);
        isSavingRef.current = false;
        return;
      }
      if (sendCancelMessage && (!cancelMessageTemplate || cancelMessageTemplate.trim() === '')) {
        toast.error('Template pesan cancel diperlukan ketika toggle dihidupkan.');
        setLoading(false);
        isSavingRef.current = false;
        return;
      }

      await axios.put('/api/therapists/message-templates', payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      
      toast.success('Template pesan berhasil disimpan.');
      setEditMode(false);
      setIsEditing(false);
      await fetchMessageTemplates();
    } catch (error) {
      console.error('Ralat semasa menyimpan template pesan:', error);
      if (error.response) {
        toast.error(`Gagal menyimpan template pesan: ${error.response.data.message}`);
      } else {
        toast.error('Gagal menyimpan template pesan. Sila cuba lagi.');
      }
    } finally {
      setLoading(false);
      isSavingRef.current = false;
    }
  };

  return (
    <div className="whatsapp-integration-container">
      <h2>Integrasi WhatsApp</h2>
      <p>
        Status:{' '}
        {loading
          ? 'Sila tunggu, sedang menyemak/mendapatkan data...'
          : status === STATUS.CONNECTED
          ? 'WhatsApp Berjaya Disambung'
          : status === STATUS.QR_PENDING
          ? 'Dalam Proses Sambungan (QR Diperlukan)'
          : status === STATUS.SESSION_STARTED
          ? 'Sesi Sedang Dimulakan. Sila tunggu...'
          : 'Tidak Disambung'}
      </p>

      {loading && (
        <div className="spinner-container">
          <ClipLoader color="#4CAF50" loading={true} size={50} />
        </div>
      )}

      <button
        id="connectButton"
        onClick={handleConnectButtonClick}
        disabled={loading || status !== STATUS.DISCONNECTED}
      >
        Sambung WhatsApp
      </button>

      {showGetQrButton && status === STATUS.SESSION_STARTED && (
        <button
          id="getQrButton"
          onClick={handleGetQrCodeClick}
          disabled={loading}
        >
          Dapatkan QR Code
        </button>
      )}

      {status === STATUS.CONNECTED && !editMode && (
        <button id="disconnectButton" onClick={handleDisconnectButtonClick} disabled={loading}>
          Putuskan Sambungan
        </button>
      )}

      <button
        id="resetButton"
        onClick={handleResetConnectionClick}
        disabled={loading}
      >
        Reset Sambungan
      </button>

      {qrCodeData && status === STATUS.QR_PENDING && (
        <div id="qrCodeContainer">
          <QRCode value={qrCodeData} size={200} />
          <p>Sila imbas QR Code ini dengan WhatsApp anda dalam masa 2 minit.</p>
        </div>
      )}

      {status === STATUS.CONNECTED && (
        <div className="message-settings">
          <h3>Pengaturan Pesan</h3>
          {!editMode && (
            <button
              id="editButton"
              onClick={handleEditClick}
              disabled={loading}
              className="edit-button"
            >
              Edit Template
            </button>
          )}

          <div className="whatsapp-toggle-container">
            <label className="whatsapp-toggle-label">
              Send Mesej Booking Berjaya
              <input
                type="checkbox"
                checked={sendBookingMessage}
                onChange={handleSendBookingMessageToggle}
                disabled={loading || !editMode}
              />
              <span className="whatsapp-toggle-switch"></span>
            </label>
          </div>

          {sendBookingMessage && (
            <div className="template-field">
              <label htmlFor="bookingTemplate">Template Pesan Booking:</label>
              <textarea
                id="bookingTemplate"
                value={bookingMessageTemplate}
                onChange={handleBookingMessageTemplateChange}
                placeholder="Masukkan template pesan booking di sini..."
                rows="3"
                disabled={!editMode}
              ></textarea>
              <p className="template-info">
                Gunakan: <code>{'{{userName}}'}</code>, <code>{'{{treatment}}'}</code>, <code>{'{{bookingDate}}'}</code>, <code>{'{{userPhone}}'}</code>, <code>{'{{startTime}}'}</code>, <code>{'{{endTime}}'}</code>
              </p>
            </div>
          )}

          <div className="whatsapp-toggle-container">
            <label className="whatsapp-toggle-label">
              Send Mesej Cancel Berjaya
              <input
                type="checkbox"
                checked={sendCancelMessage}
                onChange={handleSendCancelMessageToggle}
                disabled={loading || !editMode}
              />
              <span className="whatsapp-toggle-switch"></span>
            </label>
          </div>

          {sendCancelMessage && (
            <div className="template-field">
              <label htmlFor="cancelTemplate">Template Pesan Cancel:</label>
              <textarea
                id="cancelTemplate"
                value={cancelMessageTemplate}
                onChange={handleCancelMessageTemplateChange}
                placeholder="Masukkan template pesan cancel di sini..."
                rows="3"
                disabled={!editMode}
              ></textarea>
              <p className="template-info">
                Gunakan: <code>{'{{userName}}'}</code>, <code>{'{{treatment}}'}</code>, <code>{'{{bookingDate}}'}</code>, <code>{'{{userPhone}}'}</code>, <code>{'{{startTime}}'}</code>, <code>{'{{endTime}}'}</code>
              </p>
            </div>
          )}

          {editMode && (
            <div className="edit-button-container">
              <button
                id="saveTemplatesButton"
                onClick={handleSaveTemplates}
                disabled={loading}
                className="save-button"
              >
                Simpan Template Pesan
              </button>
              <button
                id="cancelEditButton"
                onClick={handleCancelEdit}
                disabled={loading}
                className="cancel-button"
              >
                Batalkan
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WhatsappIntegration;
