// src/components/AccountFinancial.js

import React, { useState, useEffect, useMemo, useRef } from 'react';
import './AccountFinancial.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash, FaDollarSign, FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOMServer from 'react-dom/server';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isWithinInterval,
  parse,
  startOfDay,
  endOfDay,
} from 'date-fns';
import Spinner from './Spinner'; // Import Spinner
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// Fungsi untuk memformat ObjectId
const formatObjectId = (id) => {
  return id ? id.substring(id.length - 5) : 'N/A';
};

// Fungsi untuk memformat tarikh ke 'DD/MM/YYYY'
const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) return 'Tidak Diketahui';
  const day = String(date.getDate()).padStart(2, '0'); // DD
  const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
  const year = date.getFullYear(); // YYYY
  return `${day}/${month}/${year}`;
};

// Fungsi untuk memformat tarikh ke 'YYYY-MM-DD' untuk input date picker
const formatDateForInput = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
  const day = String(date.getDate()).padStart(2, '0'); // DD
  return `${year}-${month}-${day}`;
};

// Komponen ExpensesTable yang boleh digunakan semula
const ExpensesTable = ({ expenses, handleEdit, handleDelete, isCommission = false }) => {
  // Jika expenses adalah objek yang mengandungi kategori
  if (typeof expenses === 'object' && !Array.isArray(expenses)) {
    return (
      <>
        {Object.entries(expenses).map(([category, expensesList]) => (
          <React.Fragment key={category}>
            <tr className="category-row">
              <td colSpan="5">
                <strong>{category}</strong>
              </td>
            </tr>
            {expensesList.map((expense) => (
              <tr key={expense._id}>
                <td>{expense.description}</td>
                <td>RM {parseFloat(expense.amount).toFixed(2)}</td>
                <td>{expense.category ? expense.category.name : 'Tidak Diketahui'}</td>
                <td>{formatDate(expense.date)}</td>
                <td className="action-buttons">
                  {!isCommission ? (
                    <>
                      <button className="edit-button" onClick={() => handleEdit(expense)}>
                        <FaEdit /> Edit
                      </button>
                      <button className="delete-button" onClick={() => handleDelete(expense._id)}>
                        <FaTrash /> Delete
                      </button>
                    </>
                  ) : (
                    <span> - </span>
                  )}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  }

  // Jika expenses adalah array (untuk Expenses Komisen yang sudah dipecahkan)
  if (Array.isArray(expenses)) {
    return expenses.map((expense) => (
      <tr key={expense._id}>
        <td>{expense.description}</td>
        <td>RM {parseFloat(expense.amount).toFixed(2)}</td>
        <td>{expense.category ? expense.category.name : 'Tidak Diketahui'}</td>
        <td>{formatDate(expense.date)}</td>
        <td className="action-buttons">
          {!isCommission ? (
            <>
              <button className="edit-button" onClick={() => handleEdit(expense)}>
                <FaEdit /> Edit
              </button>
              <button className="delete-button" onClick={() => handleDelete(expense._id)}>
                <FaTrash /> Delete
              </button>
            </>
          ) : (
            <span> - </span>
          )}
        </td>
      </tr>
    ));
  }

  return null;
};

function AccountFinancial({ therapistId: propTherapistId, token: propToken }) {
  // State Hooks
  const [expenses, setExpenses] = useState([]);
  const [sales, setSales] = useState([]);
  const [aggregatedSales, setAggregatedSales] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [formData, setFormData] = useState({
    description: '',
    amount: '',
    date: '',
    category: '',
    expenseType: 'current', // Tambahkan expenseType dengan default 'current'
  });
  const [loading, setLoading] = useState(false);
  const [loadingSales, setLoadingSales] = useState(false);

  // Categories State
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  // Capital Per Month States
  const [initialCapital, setInitialCapital] = useState(0);
  const [capital, setCapital] = useState(0);
  const [capitalLoading, setCapitalLoading] = useState(false);
  const [showCapitalModal, setShowCapitalModal] = useState(false);
  const [capitalFormData, setCapitalFormData] = useState({
    initialCapital: '',
  });

  // Penapis States
  const [filter, setFilter] = useState('all'); // 'today', 'week', 'month', 'year', 'custom'
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Menambah State untuk Tab Aktif
  const [activeExpensesTab, setActiveExpensesTab] = useState('expensesSekarang'); // Default tab

  // New State untuk Category Filter
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Dapatkan therapistId dan token dari props atau localStorage
  const therapistId = propTherapistId || localStorage.getItem('userId');
  const token = propToken || localStorage.getItem('token');

  // New States for Commission Management
  const [commissionRules, setCommissionRules] = useState([]);
  const [loadingCommissionRules, setLoadingCommissionRules] = useState(false);
  const [showCommissionSettingsModal, setShowCommissionSettingsModal] = useState(false);
  const [editingCommissionIndex, setEditingCommissionIndex] = useState(null);
  const [newCommissionRule, setNewCommissionRule] = useState({
    namarawatansales: '',
    namatherapistsales: '',
    commissionType: '',
    value: '',
  });
  const [commissionExpenses, setCommissionExpenses] = useState([]);

  // New States for Dropdown Data
  const [namaRawatanSalesList, setNamaRawatanSalesList] = useState([]);
  const [namaTherapistSalesList, setNamaTherapistSalesList] = useState([]);
  const [loadingNamaRawatanSales, setLoadingNamaRawatanSales] = useState(false);
  const [loadingNamaTherapistSales, setLoadingNamaTherapistSales] = useState(false);

  // Ref for Receipt
  const receiptRef = useRef();

  // New States for Add Sales Item Modal
  const [showAddItemSaleModal, setShowAddItemSaleModal] = useState(false);
  const [itemSaleFormData, setItemSaleFormData] = useState({
    itemName: '',
    amount: '',
    date: '',
  });

  // Tambah State untuk Edit dan Delete Item Sales
  const [showEditItemSaleModal, setShowEditItemSaleModal] = useState(false);
  const [currentItemSale, setCurrentItemSale] = useState(null);

  // Fungsi untuk menentukan julat tarikh berdasarkan filter
const getDateRange = () => {
  const today = new Date();
  switch (filter) {
    case 'today':
      return { start: startOfDay(today), end: endOfDay(today) };
    case 'week':
      return { start: startOfWeek(today, { weekStartsOn: 1 }), end: endOfWeek(today, { weekStartsOn: 1 }) };
    case 'month':
      return { start: startOfMonth(today), end: endOfMonth(today) };
    case 'year':
      return { start: startOfYear(today), end: endOfYear(today) };
    case 'custom':
      return {
        start: startDate ? startOfDay(startDate) : null,
        end: endDate ? endOfDay(endDate) : null,
      };
    default:
      return null;
  }
};

  // Menggunakan useMemo untuk menapis sales
  const filteredSales = useMemo(() => {
    return sales.filter((sale) => {
      // Penapisan berdasarkan carian (contohnya, clientName atau treatmentName atau itemName)
      const matchesSearch =
        (sale.clientName && sale.clientName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (sale.treatmentName && sale.treatmentName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (sale.itemName && sale.itemName.toLowerCase().includes(searchQuery.toLowerCase()));

      // Penapisan berdasarkan tarikh
      const dateRange = getDateRange();
      if (dateRange && dateRange.start && dateRange.end) {
        // Pastikan saleDate adalah dalam format yang betul
        if (sale.saleDate && sale.saleDate !== 'Tidak Diketahui') {
          const parsedSaleDate = parse(sale.saleDate, 'dd/MM/yyyy', new Date());
          // Periksa sama ada tarikh yang diparse adalah sah
          if (isNaN(parsedSaleDate)) {
            return false; // Ekses jika tarikh tidak sah
          }
          return matchesSearch && isWithinInterval(parsedSaleDate, { start: dateRange.start, end: dateRange.end });
        }
        return false; // Ekses jika tiada saleDate
      }

      return matchesSearch;
    });
  }, [sales, searchQuery, filter, startDate, endDate]);

  // Menggunakan useMemo untuk menapis expenses
  const combinedExpenses = useMemo(() => {
    return [...expenses, ...commissionExpenses];
  }, [expenses, commissionExpenses]);

  const filteredExpenses = useMemo(() => {
  return combinedExpenses.filter((expense) => {
    // Penapisan berdasarkan carian
    const matchesSearch = expense.description.toLowerCase().includes(searchQuery.toLowerCase());

      // Penapisan berdasarkan tarikh
         const dateRange = getDateRange();
    if (dateRange && dateRange.start && dateRange.end) {
      const expenseDate = new Date(expense.date);
      // Pastikan expenseDate adalah sah
      if (isNaN(expenseDate)) {
        return false;
      }
      return matchesSearch && isWithinInterval(expenseDate, { start: dateRange.start, end: dateRange.end });
    }

    return matchesSearch;
  });
}, [combinedExpenses, searchQuery, filter, startDate, endDate]);

  // Menggunakan useMemo untuk data Donut Chart
  const donutData = useMemo(() => {
    const capitalRounded = parseFloat(capital.toFixed(2));
    const totalExpensesRounded = parseFloat(filteredExpenses.reduce((acc, exp) => acc + parseFloat(exp.amount), 0).toFixed(2));
    const totalSalesRounded = parseFloat(filteredSales.reduce((acc, sale) => acc + parseFloat(sale.salesAmount), 0).toFixed(2));

    return {
      labels: ['Modal Semasa (RM)', 'Total Expenses (RM)', 'Total Sales (RM)'],
      datasets: [
        {
          label: 'Capital Overview',
          data: [capitalRounded, totalExpensesRounded, totalSalesRounded],
          backgroundColor: ['#2980b9', '#e74c3c', '#2ecc71'],
          hoverBackgroundColor: ['#1f618d', '#c0392b', '#27ae60'],
        },
      ],
    };
  }, [capital, filteredExpenses, filteredSales]);

  const donutOptions = useMemo(
    () => ({
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed !== null) {
                label += 'RM ' + context.parsed.toFixed(2);
              }
              return label;
            },
          },
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleFont: { size: 16 },
          bodyFont: { size: 14 },
        },
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 14,
            },
            padding: 20,
          },
        },
        datalabels: {
          formatter: (value, ctx) => {
            return 'RM ' + value.toFixed(2);
          },
          color: '#fff',
          font: {
            size: 14,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  // Menggunakan useMemo untuk data Donut Chart Berdasarkan Kategori Terpilih
  const categoryDonutData = useMemo(() => {
    let filtered = filteredExpenses;
    if (selectedCategory !== 'all') {
      filtered = filteredExpenses.filter(exp => exp.category._id === selectedCategory);
    }

    // Kira jumlah per jenis expense
    const expenseTypeCounts = filtered.reduce((acc, exp) => {
      acc[exp.expenseType] = (acc[exp.expenseType] || 0) + parseFloat(exp.amount);
      return acc;
    }, {});

    return {
      labels: Object.keys(expenseTypeCounts).map(type => {
        switch(type) {
          case 'fixed':
            return 'Expenses Tetap';
          case 'current':
            return 'Expenses Sekarang';
          case 'commission':
            return 'Komisen';
          default:
            return type;
        }
      }),
      datasets: [
        {
          data: Object.values(expenseTypeCounts),
          backgroundColor: ['#3498db', '#e74c3c', '#2ecc71'],
          hoverBackgroundColor: ['#2980b9', '#c0392b', '#27ae60'],
        },
      ],
    };
  }, [filteredExpenses, selectedCategory]);

  const categoryDonutOptions = useMemo(() => ({
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += 'RM ' + context.parsed.toFixed(2);
            }
            return label;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
      },
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 14,
          },
          padding: 20,
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          return 'RM ' + value.toFixed(2);
        },
        color: '#fff',
        font: {
          size: 14,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  }), []);

  // Debugging
  useEffect(() => {
    console.log('therapistId:', therapistId);
    console.log('token:', token);
    if (therapistId && token) {
      fetchCategories(); // Fetch categories
      fetchCommissionRules(); // Fetch commission rules
      fetchNamaRawatanSales(); // Fetch namaRawatanSales
      fetchNamaTherapistSales(); // Fetch namaTherapistSales
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId, token]);

  // Fetch Categories terlebih dahulu, kemudian fetch Expenses dan lain-lain setelah categories dimuatkan
  useEffect(() => {
    const fetchAllData = async () => {
      await fetchCategories(); // Pastikan categories dimuatkan terlebih dahulu
      await fetchCommissionRules(); // Fetch commission rules
      await fetchExpenses();
      await fetchSales();
      await fetchInitialCapital();
      await fetchNamaRawatanSales(); // Pastikan namaRawatanSales dimuatkan
      await fetchNamaTherapistSales(); // Pastikan namaTherapistSales dimuatkan
    };

    if (therapistId && token) {
      fetchAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId, token]);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const response = await axios.get('/api/categories', {
        headers: {
          'x-auth-token': token,
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Gagal mengambil data kategori.');
    } finally {
      setLoadingCategories(false);
    }
  };

  const fetchCommissionRules = async () => {
    setLoadingCommissionRules(true);
    try {
      const response = await axios.get('/api/commissions/search', {
        headers: {
          'x-auth-token': token,
        },
        params: { therapistId },
      });
      setCommissionRules(response.data);
    } catch (error) {
      console.error('Error fetching commission rules:', error);
      toast.error('Gagal mengambil data komisen.');
    } finally {
      setLoadingCommissionRules(false);
    }
  };

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/expenses', {
        headers: {
          'x-auth-token': token,
        },
        params: { therapistId },
      });
      setExpenses(response.data);
      console.log('Fetched Expenses:', response.data); // Tambahkan ini untuk debugging
      toast.success('Perbelanjaan berjaya dimuatkan!');
    } catch (error) {
      console.error('Error fetching expenses:', error);
      toast.error('Gagal mengambil data perbelanjaan.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSales = async () => {
    setLoadingSales(true);
    try {
      // Fetch booking sales
      const bookingSalesResponse = await axios.get(`/api/sales/therapist/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
      });

      const rawBookingSalesData = bookingSalesResponse.data;

      // Transform booking sales data
      const flatBookingSales = rawBookingSalesData.flatMap((sale) => {
        const booking = sale.booking;
        return sale.therapists.map((therapistData) => ({
          bookingId: booking._id, // Simpan ObjectId penuh
          formattedBookingId: formatObjectId(booking._id) || 'N/A', // Untuk paparan sahaja
          clientName: booking.userName || 'Tidak Diketahui',
          clientPhone: booking.userPhone || 'Tidak Diketahui',
          bookingDate: booking.bookingDate ? formatDate(booking.bookingDate) : 'Tidak Diketahui',
          slotTime: booking.slotId ? `${booking.slotId.startTime} - ${booking.slotId.endTime}` : 'Tidak Diketahui',
          slotDate: booking.slotId ? formatDate(booking.slotId.date) : 'Tidak Diketahui',
          treatmentName: therapistData.treatment?.name || 'Tidak Diketahui',
          therapistName: therapistData.therapist?.name || 'Tidak Diketahui',
          salesAmount: parseFloat(therapistData.salesAmount) || 0,
          saleDate: sale.saleDate ? formatDate(sale.saleDate) : 'Tidak Diketahui',
          saleType: 'booking',
        }));
      });

      // Fetch item sales
      const itemSalesResponse = await axios.get(`/api/sales/items/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
      });

      const rawItemSalesData = itemSalesResponse.data;

      // Transform item sales data
      const flatItemSales = rawItemSalesData.map((itemSale) => ({
        itemId: itemSale._id, // Simpan ObjectId penuh
        formattedItemId: formatObjectId(itemSale._id) || 'N/A', // Untuk paparan sahaja
        itemName: itemSale.itemName || 'Tidak Diketahui',
        salesAmount: parseFloat(itemSale.amount).toFixed(2),
        saleDate: formatDate(itemSale.date),
        saleType: 'item',
      }));

      // Combine both sales
      const combinedSales = [...flatBookingSales, ...flatItemSales];

      console.log('Combined Sales Data:', combinedSales);

      setSales(combinedSales);
      toast.success('Data jualan berjaya dimuatkan!');
    } catch (error) {
      console.error('Error fetching sales:', error.response?.data || error.message);
      toast.error('Gagal mengambil data jualan.');
    } finally {
      setLoadingSales(false);
    }
  };

  const fetchInitialCapital = async () => {
    setCapitalLoading(true);
    try {
      const response = await axios.get(`/api/therapists/${therapistId}/capital`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setInitialCapital(parseFloat(response.data.initialCapital));
      toast.success('Modal permulaan berjaya dimuatkan!');
    } catch (error) {
      console.error('Error fetching initial capital:', error);
      toast.error('Gagal mengambil modal permulaan.');
    } finally {
      setCapitalLoading(false);
    }
  };

  // Fungsi untuk mengambil namaRawatanSales dari API
  const fetchNamaRawatanSales = async () => {
    setLoadingNamaRawatanSales(true);
    try {
      const response = await axios.get(`/api/namaRawatanSales/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setNamaRawatanSalesList(response.data);
    } catch (error) {
      console.error('Error fetching namaRawatanSales:', error);
      toast.error('Gagal mengambil data Nama Rawatan Sales.');
    } finally {
      setLoadingNamaRawatanSales(false);
    }
  };

  // Fungsi untuk mengambil namaTherapistSales dari API
  const fetchNamaTherapistSales = async () => {
    setLoadingNamaTherapistSales(true);
    try {
      const response = await axios.get(`/api/namaTherapistSales/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setNamaTherapistSalesList(response.data);
    } catch (error) {
      console.error('Error fetching namaTherapistSales:', error);
      toast.error('Gagal mengambil data Nama Therapist Sales.');
    } finally {
      setLoadingNamaTherapistSales(false);
    }
  };

  // useEffect untuk mengagregatkan data jualan berdasarkan tarikh
  useEffect(() => {
    if (filteredSales.length > 0) {
      const aggregated = {};

      filteredSales.forEach((sale) => {
        const date = sale.saleDate; // 'DD/MM/YYYY'

        if (!aggregated[date]) {
          aggregated[date] = 0;
        }

        aggregated[date] += parseFloat(sale.salesAmount);
      });

      const aggregatedSalesArray = Object.entries(aggregated).map(([date, total]) => ({
        date,
        total: total.toFixed(2),
      }));

      console.log('Aggregated Sales Data:', aggregatedSalesArray);

      setAggregatedSales(aggregatedSalesArray);
    } else {
      setAggregatedSales([]);
    }
  }, [filteredSales]);

  // useEffect untuk mengira modal semasa
  useEffect(() => {
    // Hitung jumlah total sales dan expenses
    const totalSales = filteredSales.reduce((acc, sale) => acc + parseFloat(sale.salesAmount), 0);
    const totalExpenses = filteredExpenses.reduce((acc, expense) => acc + parseFloat(expense.amount), 0);

    // Kira modal semasa
    const newCapital = initialCapital + totalSales - totalExpenses;
    setCapital(newCapital);
  }, [filteredSales, filteredExpenses, initialCapital]);

  // useEffect untuk mengira komisen dan tambah sebagai expenses
  useEffect(() => {
    if (commissionRules.length === 0) {
      setCommissionExpenses([]);
      return;
    }

    const computeCommissions = () => {
      const commissionExpensesArray = [];

      filteredSales.forEach((sale) => {
        const namarawatansales = sale.treatmentName;
        const namatherapistsales = sale.therapistName;

        // Cari aturan komisen yang sesuai
        const applicableRules = commissionRules.filter((rule) => {
          if (rule.namatherapistsales) {
            return (
              rule.namarawatansales === namarawatansales &&
              rule.namatherapistsales === namatherapistsales
            );
          } else {
            return rule.namarawatansales === namarawatansales;
          }
        });

        // Terapkan aturan komisen
        applicableRules.forEach((rule) => {
          let commissionAmount = 0;
          if (rule.commissionType === 'percentage') {
            commissionAmount = (sale.salesAmount * rule.value) / 100;
          } else if (rule.commissionType === 'fixed') {
            commissionAmount = rule.value;
          }

          if (commissionAmount > 0) {
            const commissionCategory = categories.find(
              (cat) => cat.name.toLowerCase() === 'komisen'
            );
            if (!commissionCategory) {
              toast.warn(
                'Kategori "Komisen" belum ditetapkan. Sila tambah kategori "Komisen" dalam pengurusan kategori.'
              );
              return;
            }

            const commissionExpense = {
              _id: `commission-${sale.bookingId}-${rule._id}`, // unique ID
              description: `Booking ID: ${formatObjectId(sale.bookingId)}\nNama Rawatan: ${namarawatansales}\nNama Therapist: ${namatherapistsales}`,
              namarawatansales, // Menambah bidang berasingan
              namatherapistsales, // Menambah bidang berasingan
              amount: commissionAmount.toFixed(2),
              category: { _id: commissionCategory._id, name: commissionCategory.name },
              date:
                sale.saleDate && sale.saleDate !== 'Tidak Diketahui'
                  ? parse(sale.saleDate, 'dd/MM/yyyy', new Date()).toISOString()
                  : new Date().toISOString(),
              expenseType: 'commission',
            };

            commissionExpensesArray.push(commissionExpense);
          }
        });
      });

      return commissionExpensesArray;
    };

    const computedCommissionExpenses = computeCommissions();

    console.log('Computed Commission Expenses:', computedCommissionExpenses); // Debugging

    setCommissionExpenses(computedCommissionExpenses);
  }, [filteredSales, commissionRules, categories]);

  const openModal = (expense = null) => {
    setCurrentExpense(expense);
    if (expense) {
      setFormData({
        description: expense.description,
        amount: expense.amount,
        date: expense.date.slice(0, 10), // Pastikan format tarikh sesuai 'YYYY-MM-DD'
        category: expense.category?._id || '', // Jika expense.category adalah objek, ambil _id
        expenseType: expense.expenseType || 'current',
      });
    } else {
      setFormData({
        description: '',
        amount: '',
        date: '',
        category: categories.length > 0 ? categories[0]._id : '',
        expenseType: 'current', // Default ke 'current'
      });
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentExpense(null);
    setFormData({
      description: '',
      amount: '',
      date: '',
      category: '',
      expenseType: 'current',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const { description, amount, date, category, expenseType } = formData;
    if (!description || !amount || !date || !category || !expenseType) {
      toast.warn('Sila isi semua medan.');
      return;
    }

    if (!['fixed', 'current'].includes(expenseType)) {
      toast.warn('expenseType tidak valid.');
      return;
    }

    setLoading(true);
    try {
      if (currentExpense && !currentExpense._id.startsWith('commission-')) {
        // Edit Expense
        const response = await axios.put(`/api/expenses/${currentExpense._id}`, formData, {
          headers: {
            'x-auth-token': token,
          },
        });
        setExpenses((prevExpenses) =>
          prevExpenses.map((exp) => (exp._id === response.data._id ? response.data : exp))
        );
        toast.success('Perbelanjaan berjaya dikemaskini!');
      } else if (!currentExpense) {
        // Add Expense
        const response = await axios.post(
          '/api/expenses',
          { ...formData, therapistId },
          {
            headers: {
              'x-auth-token': token,
            },
          }
        );
        setExpenses((prevExpenses) => [...prevExpenses, response.data]);
        toast.success('Perbelanjaan berjaya ditambah!');
      } else {
        // Jika currentExpense adalah komisen, jangan edit atau tambah
        toast.warn('Komisen tidak boleh diedit atau ditambah secara manual.');
      }
      closeModal();
    } catch (error) {
      console.error('Error saving expense:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Gagal menyimpan perbelanjaan.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    console.log('Deleting expense ID:', id);
    console.log('Token:', token);
    if (!window.confirm('Adakah anda pasti untuk memadam perbelanjaan ini?')) return;

    setLoading(true);
    try {
      await axios.delete(`/api/expenses/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setExpenses((prevExpenses) => prevExpenses.filter((exp) => exp._id !== id));
      toast.success('Perbelanjaan berjaya dipadam!');
    } catch (error) {
      console.error('Error deleting expense:', error.response?.data || error.message);
      toast.error('Gagal memadam perbelanjaan.');
    } finally {
      setLoading(false);
    }
  };

  // Implementasikan fungsi downloadReceipt
  const downloadReceipt = async (sale) => {
    // Filter sales berdasarkan tarikh yang dipilih
    const selectedDate = sale.date; // 'DD/MM/YYYY'
    const salesForReceipt = filteredSales.filter((s) => s.saleDate === selectedDate);

    if (salesForReceipt.length === 0) {
      toast.warn('Tiada jualan untuk tarikh ini.');
      return;
    }

    // Buat elemen dinamik untuk resit
    const receiptContent = (
      <div style={{ padding: '20px', fontFamily: 'Arial', color: '#000' }}>
        <h2 style={{ textAlign: 'center' }}>Resit Laporan Jualan Harian</h2>
        <p><strong>Tarikh:</strong> {selectedDate}</p>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Jenis Jualan</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID Booking / ID Item</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nama Klien / Nama Item</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nama Rawatan / -</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Nama Therapist / -</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Jumlah Sales (RM)</th>
            </tr>
          </thead>
          <tbody>
            {salesForReceipt.map((s, idx) => (
              <tr key={idx}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {s.saleType === 'booking' ? 'Booking' : 'Item'}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {s.saleType === 'booking' ? s.formattedBookingId : s.formattedItemId}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {s.saleType === 'booking' ? s.clientName : s.itemName}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {s.saleType === 'booking' ? s.treatmentName : '-'}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {s.saleType === 'booking' ? s.therapistName : '-'}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  RM {parseFloat(s.salesAmount).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3 style={{ textAlign: 'right', marginTop: '20px' }}>
          Jumlah Total: RM {salesForReceipt.reduce((acc, s) => acc + parseFloat(s.salesAmount), 0).toFixed(2)}
        </h3>
      </div>
    );

    // Render elemen ke dalam dokumen sementara
    const element = document.createElement('div');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    element.innerHTML = ReactDOMServer.renderToStaticMarkup(receiptContent);
    document.body.appendChild(element);

    // Gunakan html2canvas untuk menangkap elemen
    try {
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Resit_Jualan_${selectedDate.replace(/\//g, '-')}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Gagal menghasilkan resit.');
    } finally {
      document.body.removeChild(element);
    }
  };

  const handleCapitalChange = (e) => {
    const { name, value } = e.target;
    setCapitalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveCapital = async () => {
    const { initialCapital } = capitalFormData;
    if (!initialCapital || isNaN(initialCapital) || parseFloat(initialCapital) < 0) {
      toast.warn('Sila masukkan jumlah modal permulaan yang sah.');
      return;
    }

    setCapitalLoading(true);
    try {
      const response = await axios.put(
        `/api/therapists/${therapistId}/capital`,
        { initialCapital: parseFloat(initialCapital) },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );
      setInitialCapital(parseFloat(response.data.initialCapital));
      toast.success('Modal permulaan berjaya dikemaskini!');
      setShowCapitalModal(false);
    } catch (error) {
      console.error('Error updating initial capital:', error);
      toast.error('Gagal mengemaskini modal permulaan.');
    } finally {
      setCapitalLoading(false);
    }
  };

  // Category Management Handlers
  const openCategoryModal = () => {
    setNewCategoryName('');
    setShowCategoryModal(true);
  };

  const closeCategoryModal = () => {
    setShowCategoryModal(false);
    setNewCategoryName('');
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      toast.warn('Sila masukkan nama kategori.');
      return;
    }

    try {
      const response = await axios.post(
        '/api/categories',
        { name: newCategoryName.trim() },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );
      setCategories((prevCategories) => [...prevCategories, response.data]);
      toast.success('Kategori berjaya ditambah!');
      closeCategoryModal();
    } catch (error) {
      console.error('Error adding category:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Gagal menambah kategori.');
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (!window.confirm('Adakah anda pasti untuk memadam kategori ini?')) return;

    try {
      await axios.delete(`/api/categories/${categoryId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setCategories((prevCategories) => prevCategories.filter((cat) => cat._id !== categoryId));
      toast.success('Kategori berjaya dipadam!');
    } catch (error) {
      console.error('Error deleting category:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Gagal memadam kategori.');
    }
  };

  // Commission Settings Handlers
  const handleNewCommissionRuleChange = (e) => {
    const { name, value } = e.target;
    setNewCommissionRule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveCommissionRule = async () => {
    const { namarawatansales, namatherapistsales, commissionType, value } = newCommissionRule;

    // Validasi wajib pilih namarawatansales
    if (!namarawatansales) {
      toast.warn('Sila pilih Namarawatansales.');
      return;
    }

    // Validasi nilai komisen
    if (!commissionType || !value) {
      toast.warn('Sila isi jenis dan nilai komisen.');
      return;
    }

    // Pastikan nilai komisen adalah nombor positif
    if (value <= 0) {
      toast.warn('Nilai komisen mesti lebih besar daripada 0.');
      return;
    }

    // Jika namatherapistsales dipilih, pastikan ia wujud dalam senarai namaTherapistSales
    if (
      namatherapistsales &&
      !namaTherapistSalesList.some((therapist) => therapist.name === namatherapistsales)
    ) {
      toast.warn('Namatherapistsales yang dipilih tidak sah.');
      return;
    }

    setLoadingCommissionRules(true);
    try {
      const rule = {
        namarawatansales,
        commissionType,
        value: parseFloat(value),
      };
      if (namatherapistsales) rule.namatherapistsales = namatherapistsales;

      if (editingCommissionIndex !== null) {
        // Update existing rule
        const ruleToUpdate = commissionRules[editingCommissionIndex];
        const response = await axios.put(`/api/commissions/${ruleToUpdate._id}`, rule, {
          headers: {
            'x-auth-token': token,
          },
        });
        setCommissionRules((prev) =>
          prev.map((r, idx) => (idx === editingCommissionIndex ? response.data : r))
        );
        toast.success('Aturan komisen berjaya dikemaskini.');
        setEditingCommissionIndex(null);
      } else {
        // Add new rule
        const response = await axios.post(
          '/api/commissions',
          { ...rule, therapistId },
          {
            headers: {
              'x-auth-token': token,
            },
          }
        );
        setCommissionRules((prev) => [...prev, response.data]);
        toast.success('Aturan komisen berjaya ditambah.');
      }
      // Reset form
      setNewCommissionRule({
        namarawatansales: '',
        namatherapistsales: '',
        commissionType: '',
        value: '',
      });
    } catch (error) {
      console.error('Error saving commission rule:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Gagal menyimpan aturan komisen.');
    } finally {
      setLoadingCommissionRules(false);
    }
  };

  const openEditCommissionRule = (index) => {
    const rule = commissionRules[index];
    setEditingCommissionIndex(index);
    setNewCommissionRule({
      namarawatansales: rule.namarawatansales || '',
      namatherapistsales: rule.namatherapistsales || '',
      commissionType: rule.commissionType || '',
      value: rule.value || '',
    });
  };

  const handleDeleteCommissionRule = async (index) => {
    const rule = commissionRules[index];
    if (!window.confirm('Adakah anda pasti untuk memadam aturan komisen ini?')) return;

    setLoadingCommissionRules(true);
    try {
      await axios.delete(`/api/commissions/${rule._id}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setCommissionRules((prev) => prev.filter((_, idx) => idx !== index));
      toast.success('Aturan komisen berjaya dipadam!');
    } catch (error) {
      console.error('Error deleting commission rule:', error.response?.data || error.message);
      toast.error(error.response?.data?.message || 'Gagal memadam aturan komisen.');
    } finally {
      setLoadingCommissionRules(false);
    }
  };

  // Mengumpulkan Expenses Mengikut Kategori dan Jenis
  const expensesByTypeAndCategory = useMemo(() => {
    const grouped = {
      fixed: {},
      current: {},
      commission: {},
    };

    filteredExpenses.forEach((expense) => {
      const { expenseType, category } = expense;
      const categoryName = category ? category.name : 'Tidak Diketahui'; // Langsung gunakan nama kategori

      if (!grouped[expenseType][categoryName]) {
        grouped[expenseType][categoryName] = [];
      }
      grouped[expenseType][categoryName].push(expense);
    });

    return grouped;
  }, [filteredExpenses]);

  // Jumlah Total Expenses
  const totalExpenses = useMemo(() => {
    return filteredExpenses.reduce((acc, expense) => acc + parseFloat(expense.amount), 0).toFixed(2);
  }, [filteredExpenses]);

  // Jumlah Total Sales
  const totalSales = useMemo(() => {
    return filteredSales.reduce((acc, sale) => acc + parseFloat(sale.salesAmount), 0).toFixed(2);
  }, [filteredSales]);

  // Menggunakan useMemo untuk mengelompokkan commissionExpenses berdasarkan namatherapistsales
  const commissionExpensesByTherapist = useMemo(() => {
    const grouped = {};

    commissionExpenses.forEach((expense) => {
      const therapistName = expense.namatherapistsales || 'Tidak Diketahui';
      if (!grouped[therapistName]) {
        grouped[therapistName] = [];
      }
      grouped[therapistName].push(expense);
    });

    return grouped;
  }, [commissionExpenses]);

  // **Fungsi handleSaveItemSale yang telah dikemaskini**
  const handleSaveItemSale = async () => {
    const { itemName, amount, date } = itemSaleFormData;
    if (!itemName || !amount || !date) {
      toast.warn('Sila isi semua medan.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        '/api/sales/items',
        {
          itemName,
          amount: parseFloat(amount),
          date,
          // Therapist akan ditetapkan secara automatik oleh backend berdasarkan pengguna yang diautentikasi
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      const newItemSale = response.data;

      // Transform data untuk sesuai dengan format sales
      const transformedItemSale = {
        itemId: newItemSale._id, // Simpan ObjectId penuh
        formattedItemId: formatObjectId(newItemSale._id) || 'N/A', // Untuk paparan sahaja
        itemName: newItemSale.itemName || 'Tidak Diketahui',
        salesAmount: parseFloat(newItemSale.amount).toFixed(2),
        saleDate: formatDate(newItemSale.date),
        date: formatDateForInput(newItemSale.date), // Untuk input tarikh
        saleType: 'item',
      };

      setSales((prevSales) => [transformedItemSale, ...prevSales]);
      toast.success('Jualan item berjaya ditambah!');
      setShowAddItemSaleModal(false);
      setItemSaleFormData({
        itemName: '',
        amount: '',
        date: '',
      });
    } catch (error) {
      console.error('Error adding item sale:', error.response?.data || error.message);
      toast.error(error.response?.data?.msg || 'Gagal menambah jualan item.');
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk membuka modal edit item sale
  const openEditItemSaleModal = (itemSale) => {
    setCurrentItemSale(itemSale);
    setItemSaleFormData({
      itemName: itemSale.itemName,
      amount: itemSale.amount,
      date: itemSale.date, // Pastikan format tarikh sesuai 'YYYY-MM-DD'
    });
    setShowEditItemSaleModal(true);
  };

  // Fungsi untuk memadam item sale
  const handleDeleteItemSale = async (id) => {
    if (!window.confirm('Adakah anda pasti untuk memadam jualan item ini?')) return;

    setLoading(true);
    try {
      await axios.delete(`/api/sales/items/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setSales((prevSales) => prevSales.filter((sale) => sale.itemId !== id));
      toast.success('Jualan item berjaya dipadam!');
    } catch (error) {
      console.error('Error deleting item sale:', error.response?.data || error.message);
      toast.error(error.response?.data?.msg || 'Gagal memadam jualan item.');
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk mengedit item sale
  const handleEditItemSale = async () => {
    const { itemName, amount, date } = itemSaleFormData;
    if (!itemName || !amount || !date) {
      toast.warn('Sila isi semua medan.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(
        `/api/sales/items/${currentItemSale.itemId}`, // Gunakan full ID
        {
          itemName,
          amount: parseFloat(amount),
          date,
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      // Update state sales dengan item yang dikemaskini
      setSales((prevSales) =>
        prevSales.map((sale) =>
          sale.itemId === currentItemSale.itemId
            ? {
                ...sale,
                itemName: response.data.itemName,
                salesAmount: parseFloat(response.data.amount).toFixed(2),
                saleDate: formatDate(response.data.date),
                date: formatDateForInput(response.data.date),
              }
            : sale
        )
      );
      toast.success('Jualan item berjaya dikemaskini!');
      setShowEditItemSaleModal(false);
      setCurrentItemSale(null);
    } catch (error) {
      console.error('Error editing item sale:', error.response?.data || error.message);
      toast.error(error.response?.data?.msg || 'Gagal mengemaskini jualan item.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-financial">
      <h3>Account Financial</h3>
      <p>Bahagian Expenses dan Sales akan dipaparkan di bawah.</p>

      {/* Filter Section */}
      <div className="filter-section">
        <div className="filter-buttons">
          <button
            className={`filter-button ${filter === 'all' ? 'active' : ''}`}
            onClick={() => setFilter('all')}
          >
            Semua
          </button>
          <button
            className={`filter-button ${filter === 'today' ? 'active' : ''}`}
            onClick={() => setFilter('today')}
          >
            Hari Ini
          </button>
          <button
            className={`filter-button ${filter === 'week' ? 'active' : ''}`}
            onClick={() => setFilter('week')}
          >
            Minggu Ini
          </button>
          <button
            className={`filter-button ${filter === 'month' ? 'active' : ''}`}
            onClick={() => setFilter('month')}
          >
            Bulan Ini
          </button>
          <button
            className={`filter-button ${filter === 'year' ? 'active' : ''}`}
            onClick={() => setFilter('year')}
          >
            Tahun Ini
          </button>
          <button
            className={`filter-button ${filter === 'custom' ? 'active' : ''}`}
            onClick={() => setFilter('custom')}
          >
            Pilih Tarikh
          </button>
        </div>

        {/* Calendar Filter for Custom Range */}
        {filter === 'custom' && (
          <div className="custom-date-picker">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              dateFormat="dd/MM/yyyy"
              isClearable
              className="date-picker-input"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              dateFormat="dd/MM/yyyy"
              isClearable
              className="date-picker-input"
            />
          </div>
        )}

        {/* Search Bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Cari..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      {/* Capital Per Month Section */}
      <div className="capital-section">
        <h4>Capital Per Month</h4>
        {capitalLoading ? (
          <div className="spinner-container">
            <Spinner type="BounceLoader" size={60} color="#2980b9" label="Memuatkan modal..." />
          </div>
        ) : (
          <>
            <div className="capital-info">
              <p>
                <strong>Modal Permulaan:</strong> RM {initialCapital.toFixed(2)}
              </p>
              <p>
                <strong>Modal Semasa:</strong> RM {capital.toFixed(2)}
              </p>
            </div>
            <div className="capital-actions">
              <button
                className="edit-capital-button"
                onClick={() => {
                  setCapitalFormData({ initialCapital: initialCapital.toFixed(2) });
                  setShowCapitalModal(true);
                }}
              >
                <FaEdit /> Edit Modal Permulaan
              </button>
            </div>
            <div className="capital-chart" style={{ height: '400px', width: '400px' }}>
              <Doughnut data={donutData} options={donutOptions} />
            </div>
          </>
        )}
      </div>

      {/* Section untuk Filter Dropdown dan Donut Chart Kategori */}
      <div className="category-filter-chart">
        <h4>Filter Expenses Berdasarkan Kategori</h4>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="category-select"
        >
          <option value="all">Semua Kategori</option>
          {categories.map(category => (
            <option key={category._id} value={category._id}>{category.name}</option>
          ))}
        </select>
        <div className="category-chart">
          <Doughnut data={categoryDonutData} options={categoryDonutOptions} />
        </div>
      </div>

      {/* Expenses Section */}
      <div className="expenses-section">
        <div className="expenses-header">
          <h4>Expenses</h4>
          <button className="add-expense-button" onClick={() => openModal()}>
            <FaPlus /> Tambah Expense
          </button>
        </div>

        {/* Navigasi Tab */}
        <div className="expenses-tabs">
          <button
            className={`tab-button ${activeExpensesTab === 'expensesSekarang' ? 'active' : ''}`}
            onClick={() => setActiveExpensesTab('expensesSekarang')}
          >
            Expenses Sekarang
          </button>
          <button
            className={`tab-button ${activeExpensesTab === 'expensesTetap' ? 'active' : ''}`}
            onClick={() => setActiveExpensesTab('expensesTetap')}
          >
            Expenses Tetap
          </button>
          <button
            className={`tab-button ${activeExpensesTab === 'expensesKomisen' ? 'active' : ''}`}
            onClick={() => setActiveExpensesTab('expensesKomisen')}
          >
            Expenses Komisen
          </button>
        </div>

        {/* Button to Manage Commission Settings */}
        <div className="commission-settings-section">
          <button
            className="manage-commissions-button"
            onClick={() => setShowCommissionSettingsModal(true)}
          >
            <FaEdit /> Manage Commissions
          </button>
        </div>

        {loading ? (
          <div className="spinner-container">
            <Spinner type="ClipLoader" size={50} color="#2980b9" label="Memuatkan perbelanjaan..." />
          </div>
        ) : (
          <div className="table-responsive">
            {/* Kandungan Tab */}
            {activeExpensesTab === 'expensesSekarang' && (
              <>
                <h5>Expenses Sekarang</h5>
                <table className="expenses-table">
                  <thead>
                    <tr>
                      <th>Deskripsi</th>
                      <th>Jumlah (RM)</th>
                      <th>Kategori</th>
                      <th>Tarikh</th>
                      <th>Tindakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensesByTypeAndCategory.current &&
                    Object.keys(expensesByTypeAndCategory.current).length === 0 ? (
                      <tr>
                        <td colSpan="5" style={{ textAlign: 'center' }}>
                          Tiada perbelanjaan sekarang.
                        </td>
                      </tr>
                    ) : (
                      <ExpensesTable
                        expenses={expensesByTypeAndCategory.current}
                        handleEdit={openModal}
                        handleDelete={handleDelete}
                      />
                    )}
                  </tbody>
                  {/* Jumlah Total Expenses Sekarang */}
                  {expensesByTypeAndCategory.current &&
                    Object.keys(expensesByTypeAndCategory.current).length > 0 && (
                      <tfoot>
                        <tr className="total-row">
                          <td colSpan="1">
                            <strong>Jumlah Total Sekarang</strong>
                          </td>
                          <td>
                            <strong>
                              RM{' '}
                              {expensesByTypeAndCategory.current
                                ? Object.values(expensesByTypeAndCategory.current)
                                    .flat()
                                    .reduce((acc, exp) => acc + parseFloat(exp.amount), 0)
                                    .toFixed(2)
                                : '0.00'}
                            </strong>
                          </td>
                          <td colSpan="3"></td>
                        </tr>
                      </tfoot>
                    )}
                </table>
              </>
            )}

            {activeExpensesTab === 'expensesTetap' && (
              <>
                <h5>Expenses Tetap</h5>
                <table className="expenses-table">
                  <thead>
                    <tr>
                      <th>Deskripsi</th>
                      <th>Jumlah (RM)</th>
                      <th>Kategori</th>
                      <th>Tarikh</th>
                      <th>Tindakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensesByTypeAndCategory.fixed &&
                    Object.keys(expensesByTypeAndCategory.fixed).length === 0 ? (
                      <tr>
                        <td colSpan="5" style={{ textAlign: 'center' }}>
                          Tiada perbelanjaan tetap.
                        </td>
                      </tr>
                    ) : (
                      <ExpensesTable
                        expenses={expensesByTypeAndCategory.fixed}
                        handleEdit={openModal}
                        handleDelete={handleDelete}
                      />
                    )}
                  </tbody>
                  {/* Jumlah Total Expenses Tetap */}
                  {expensesByTypeAndCategory.fixed &&
                    Object.keys(expensesByTypeAndCategory.fixed).length > 0 && (
                      <tfoot>
                        <tr className="total-row">
                          <td colSpan="1">
                            <strong>Jumlah Total Tetap</strong>
                          </td>
                          <td>
                            <strong>
                              RM{' '}
                              {expensesByTypeAndCategory.fixed
                                ? Object.values(expensesByTypeAndCategory.fixed)
                                    .flat()
                                    .reduce((acc, exp) => acc + parseFloat(exp.amount), 0)
                                    .toFixed(2)
                                : '0.00'}
                            </strong>
                          </td>
                          <td colSpan="3"></td>
                        </tr>
                      </tfoot>
                    )}
                </table>
              </>
            )}

            {activeExpensesTab === 'expensesKomisen' && (
              <>
                <h5>Expenses Komisen</h5>
                {Object.keys(commissionExpensesByTherapist).length === 0 ? (
                  <p>Tiada perbelanjaan komisen.</p>
                ) : (
                  Object.entries(commissionExpensesByTherapist).map(([therapistName, expensesList]) => (
                    <div key={therapistName} className="commission-table">
                      <h6>{therapistName}</h6>
                      <table className="expenses-table">
                        <thead>
                          <tr>
                            <th>Deskripsi</th>
                            <th>Jumlah (RM)</th>
                            <th>Kategori</th>
                            <th>Tarikh</th>
                            <th>Tindakan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ExpensesTable
                            expenses={expensesList}
                            isCommission={true}
                          />
                        </tbody>
                        <tfoot>
                          <tr className="total-row">
                            <td colSpan="1">
                              <strong>Jumlah Total Komisen</strong>
                            </td>
                            <td>
                              <strong>
                                RM{' '}
                                {expensesList.reduce(
                                  (acc, exp) => acc + parseFloat(exp.amount),
                                  0
                                ).toFixed(2)}
                              </strong>
                            </td>
                            <td colSpan="3"></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  ))
                )}
              </>
            )}

            {/* Jumlah Total Semua Expenses */}
            {activeExpensesTab !== 'expensesKomisen' && filteredExpenses.length > 0 && (
              <table className="expenses-table">
                <tfoot>
                  <tr className="total-row">
                    <td colSpan="1">
                      <strong>Jumlah Total Semua Expenses</strong>
                    </td>
                    <td>
                      <strong>RM {totalExpenses}</strong>
                    </td>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        )}
      </div>

      {/* Category Management Section */}
      <div className="category-management">
        <h5>Manage Categories</h5>
        {loadingCategories ? (
          <div className="spinner-container">
            <Spinner type="ClipLoader" size={40} color="#2980b9" label="Memuatkan kategori..." />
          </div>
        ) : (
          <div className="category-list">
            {categories.map((category) => (
              <div key={category._id} className="category-item">
                <span>{category.name}</span>
                <button
                  className="delete-category-button"
                  onClick={() => handleDeleteCategory(category._id)}
                >
                  <FaTrash /> Delete
                </button>
              </div>
            ))}
            <button className="add-category-button" onClick={openCategoryModal}>
              <FaPlus /> Add Category
            </button>
          </div>
        )}
      </div>

      {/* Sales Section */}
      <div className="sales-section">
        <div className="sales-header">
          <h4>Sales</h4>
          <div>
            <button className="refresh-sales-button" onClick={fetchSales}>
              Refresh Sales
            </button>
            <button className="add-sales-item-button" onClick={() => setShowAddItemSaleModal(true)}>
              <FaPlus /> Tambah Jualan Item
            </button>
          </div>
        </div>

        {loadingSales ? (
          <div className="spinner-container">
            <Spinner type="BeatLoader" size={50} color="#2980b9" label="Memuatkan jualan..." />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="sales-table">
              <thead>
                <tr>
                  <th>Tarikh</th>
                  <th>Jumlah Sales (RM)</th>
                  <th>Tindakan</th>
                </tr>
              </thead>
              <tbody>
                {aggregatedSales.length === 0 ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center' }}>
                      Tiada data jualan.
                    </td>
                  </tr>
                ) : (
                  aggregatedSales.map((sale, index) => (
                    <tr key={index}>
                      <td data-label="Tarikh">{sale.date}</td>
                      <td data-label="Jumlah Sales (RM)">RM {sale.total}</td>
                      <td data-label="Tindakan">
                        <button
                          className="download-receipt-button"
                          onClick={() => downloadReceipt(sale)}
                        >
                          <FaDollarSign /> Resit
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              {/* Tambahkan baris untuk jumlah total sales */}
              {aggregatedSales.length > 0 && (
                <tfoot>
                  <tr className="total-row">
                    <td colSpan="1">
                      <strong>Jumlah Total</strong>
                    </td>
                    <td>
                      <strong>RM {totalSales}</strong>
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        )}
      </div>

      {/* Sales Items Section */}
      <div className="sales-items-section">
        <div className="sales-items-header">
          <h4>Jualan Item Individu</h4>
          <button className="refresh-sales-button" onClick={fetchSales}>
            Refresh Jualan
          </button>
          <button className="add-sales-item-button" onClick={() => setShowAddItemSaleModal(true)}>
            <FaPlus /> Tambah Jualan Item
          </button>
        </div>

        {loadingSales ? (
          <div className="spinner-container">
            <Spinner type="BeatLoader" size={50} color="#2980b9" label="Memuatkan jualan..." />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="sales-items-table">
              <thead>
                <tr>
                  <th>Tarikh</th>
                  <th>Nama Item</th>
                  <th>Jumlah Sales (RM)</th>
                  <th>Tindakan</th>
                </tr>
              </thead>
              <tbody>
                {filteredSales
                  .filter((sale) => sale.saleType === 'item')
                  .map((sale) => (
                    <tr key={sale.itemId}>
                      <td data-label="Tarikh">{sale.saleDate}</td>
                      <td data-label="Nama Item">{sale.itemName}</td>
                      <td data-label="Jumlah Sales (RM)">RM {sale.salesAmount}</td>
                      <td data-label="Tindakan">
                        <button
                          className="edit-item-sale-button"
                          onClick={() => openEditItemSaleModal(sale)}
                        >
                          <FaEdit /> Edit
                        </button>
                        <button
                          className="delete-item-sale-button"
                          onClick={() => handleDeleteItemSale(sale.itemId)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                {filteredSales.filter((sale) => sale.saleType === 'item').length === 0 && (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>
                      Tiada jualan item.
                    </td>
                  </tr>
                )}
              </tbody>
              {/* Jumlah Total Sales Items */}
              {filteredSales.filter((sale) => sale.saleType === 'item').length > 0 && (
                <tfoot>
                  <tr className="total-row">
                    <td colSpan="2">
                      <strong>Jumlah Total</strong>
                    </td>
                    <td>
                      <strong>
                        RM{' '}
                        {filteredSales
                          .filter((sale) => sale.saleType === 'item')
                          .reduce((acc, sale) => acc + parseFloat(sale.salesAmount), 0)
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        )}
      </div>

      {/* Modal untuk Expenses */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {currentExpense && currentExpense.expenseType === 'commission'
                  ? 'Komisen Tidak Boleh Diedit'
                  : currentExpense
                  ? 'Edit Expense'
                  : 'Tambah Expense'}
              </h4>
              <button className="close-button" onClick={closeModal}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              {currentExpense && currentExpense.expenseType === 'commission' ? (
                <p>Komisen tidak boleh diedit atau dipadam secara manual.</p>
              ) : (
                <>
                  <label>Deskripsi</label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Masukkan detail expenses anda"
                  />
                  <label>Jumlah (RM)</label>
                  <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="Masukkan jumlah"
                  />
                  <label>Kategori</label>
                  {loadingCategories ? (
                    <div className="spinner-container">
                      <Spinner type="ClipLoader" size={30} color="#2980b9" label="Memuatkan kategori..." />
                    </div>
                  ) : (
                    <select name="category" value={formData.category} onChange={handleChange}>
                      <option value="">-- Pilih Kategori --</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <label>Jenis Perbelanjaan</label>
                  <select name="expenseType" value={formData.expenseType} onChange={handleChange}>
                    <option value="fixed">Expenses Tetap</option>
                    <option value="current">Expenses Sekarang</option>
                  </select>
                  <label>Tarikh</label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={closeModal}>
                Batal
              </button>
              {!currentExpense || currentExpense.expenseType !== 'commission' ? (
                <button className="save-button" onClick={handleSave}>
                  <FaSave /> Simpan
                </button>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {/* Modal untuk Edit Initial Capital */}
      {showCapitalModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Edit Modal Permulaan</h4>
              <button className="close-button" onClick={() => setShowCapitalModal(false)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <label>Modal Permulaan (RM)</label>
              <input
                type="number"
                name="initialCapital"
                value={capitalFormData.initialCapital}
                onChange={handleCapitalChange}
                min="0"
                step="0.01"
                placeholder="Masukkan modal permulaan"
              />
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={() => setShowCapitalModal(false)}>
                Batal
              </button>
              <button className="save-button" onClick={handleSaveCapital}>
                <FaSave /> Simpan
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal untuk Add Category */}
      {showCategoryModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Add New Category</h4>
              <button className="close-button" onClick={closeCategoryModal}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <label>Nama Kategori</label>
              <input
                type="text"
                name="newCategoryName"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                placeholder="Masukkan nama kategori baru"
              />
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={closeCategoryModal}>
                Batal
              </button>
              <button className="save-button" onClick={handleAddCategory}>
                <FaSave /> Tambah
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal untuk Commission Settings */}
      {showCommissionSettingsModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Commission Settings</h4>
              <button className="close-button" onClick={() => setShowCommissionSettingsModal(false)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              {/* List existing commission rules */}
              {loadingCommissionRules ? (
                <div className="spinner-container">
                  <Spinner type="ClipLoader" size={40} color="#2980b9" label="Memuatkan aturan komisen..." />
                </div>
              ) : (
                <div className="commission-rules-list">
                  {commissionRules.map((rule, index) => (
                    <div key={index} className="commission-rule-item">
                      <p>
                        <strong>Namarawatansales:</strong> {rule.namarawatansales}
                        {rule.namatherapistsales && (
                          <>
                            , <strong>Namatherapistsales:</strong> {rule.namatherapistsales}
                          </>
                        )}
                        <br />
                        <strong>Commission:</strong>{' '}
                        {rule.commissionType === 'percentage'
                          ? `${rule.value}%`
                          : `RM${rule.value} per job`}
                      </p>
                      <div className="commission-rule-actions">
                        <button
                          className="edit-commission-button"
                          onClick={() => openEditCommissionRule(index)}
                        >
                          <FaEdit /> Edit
                        </button>
                        <button
                          className="delete-commission-button"
                          onClick={() => handleDeleteCommissionRule(index)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </div>
                    </div>
                  ))}
                  {commissionRules.length === 0 && <p>Tiada aturan komisen ditetapkan.</p>}
                </div>
              )}
              {/* Form to add or edit commission rule */}
              <div className="commission-rule-form">
                <h5>{editingCommissionIndex !== null ? 'Edit Commission Rule' : 'Tambah Commission Rule'}</h5>
                <label>Namarawatansales:</label>
                {loadingNamaRawatanSales ? (
                  <div className="spinner-container">
                    <Spinner type="ClipLoader" size={30} color="#2980b9" label="Memuatkan nama rawatan..." />
                  </div>
                ) : (
                  <select
                    name="namarawatansales"
                    value={newCommissionRule.namarawatansales}
                    onChange={handleNewCommissionRuleChange}
                  >
                    <option value="">-- Pilih Namarawatansales --</option>
                    {namaRawatanSalesList.map((rawatan) => (
                      <option key={rawatan._id} value={rawatan.name}>
                        {rawatan.name}
                      </option>
                    ))}
                  </select>
                )}

                <label>Namatherapistsales (optional):</label>
                {loadingNamaTherapistSales ? (
                  <div className="spinner-container">
                    <Spinner type="ClipLoader" size={30} color="#2980b9" label="Memuatkan nama therapist..." />
                  </div>
                ) : (
                  <select
                    name="namatherapistsales"
                    value={newCommissionRule.namatherapistsales}
                    onChange={handleNewCommissionRuleChange}
                  >
                    <option value="">-- Pilih Namatherapistsales --</option>
                    {namaTherapistSalesList.map((therapist) => (
                      <option key={therapist._id} value={therapist.name}>
                        {therapist.name}
                      </option>
                    ))}
                  </select>
                )}

                <label>Jenis Komisen:</label>
                <select
                  name="commissionType"
                  value={newCommissionRule.commissionType}
                  onChange={handleNewCommissionRuleChange}
                >
                  <option value="">-- Pilih Jenis Komisen --</option>
                  <option value="percentage">Percentage (%)</option>
                  <option value="fixed">Fixed Amount (RM)</option>
                </select>
                {newCommissionRule.commissionType && (
                  <>
                    <label>
                      Nilai Komisen{' '}
                      {newCommissionRule.commissionType === 'percentage' ? '(%)' : '(RM)'}
                    </label>
                    <input
                      type="number"
                      name="value"
                      value={newCommissionRule.value}
                      onChange={handleNewCommissionRuleChange}
                      min="0"
                      step="0.01"
                      placeholder={
                        newCommissionRule.commissionType === 'percentage' ? 'Contoh: 30' : 'Contoh: 8'
                      }
                    />
                  </>
                )}
                <div className="commission-form-buttons">
                  <button className="save-commission-button" onClick={handleSaveCommissionRule}>
                    <FaSave /> {editingCommissionIndex !== null ? 'Kemaskini' : 'Tambah'}
                  </button>
                  <button
                    className="cancel-commission-button"
                    onClick={() => {
                      setEditingCommissionIndex(null);
                      setNewCommissionRule({
                        namarawatansales: '',
                        namatherapistsales: '',
                        commissionType: '',
                        value: '',
                      });
                    }}
                  >
                    <FaTimes /> Batal
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* Optional footer */}
            </div>
          </div>
        </div>
      )}

      {/* Modal untuk Tambah Sales Item */}
      {showAddItemSaleModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Tambah Jualan Item</h4>
              <button className="close-button" onClick={() => setShowAddItemSaleModal(false)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <label>Nama Item</label>
              <input
                type="text"
                name="itemName"
                value={itemSaleFormData.itemName}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, itemName: e.target.value })}
                placeholder="Masukkan nama item"
              />
              <label>Jumlah (RM)</label>
              <input
                type="number"
                name="amount"
                value={itemSaleFormData.amount}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, amount: e.target.value })}
                min="0"
                step="0.01"
                placeholder="Masukkan jumlah"
              />
              <label>Tarikh</label>
              <input
                type="date"
                name="date"
                value={itemSaleFormData.date}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, date: e.target.value })}
              />
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={() => setShowAddItemSaleModal(false)}>
                Batal
              </button>
              <button className="save-button" onClick={handleSaveItemSale}>
                <FaSave /> Simpan
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal untuk Edit Item Sale */}
      {showEditItemSaleModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Edit Jualan Item</h4>
              <button className="close-button" onClick={() => setShowEditItemSaleModal(false)}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              <label>Nama Item</label>
              <input
                type="text"
                name="itemName"
                value={itemSaleFormData.itemName}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, itemName: e.target.value })}
                placeholder="Masukkan nama item"
              />
              <label>Jumlah (RM)</label>
              <input
                type="number"
                name="amount"
                value={itemSaleFormData.amount}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, amount: e.target.value })}
                min="0"
                step="0.01"
                placeholder="Masukkan jumlah"
              />
              <label>Tarikh</label>
              <input
                type="date"
                name="date"
                value={itemSaleFormData.date}
                onChange={(e) => setItemSaleFormData({ ...itemSaleFormData, date: e.target.value })}
              />
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={() => setShowEditItemSaleModal(false)}>
                Batal
              </button>
              <button className="save-button" onClick={handleEditItemSale}>
                <FaSave /> Simpan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountFinancial;
