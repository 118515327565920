// src/components/ClosingRateChart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './ClosingRateChart.css';
import { toast } from 'react-toastify';

const COLORS = ['#28a745', '#dc3545']; // Hijau untuk Closing, Merah untuk Non-Closing

const ClosingRateChart = ({ therapistId, filter }) => {
  const [prospects, setProspects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [closingRateData, setClosingRateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (therapistId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId, filter]); // Tambah filter sebagai dependency

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      // Fetch Prospects
      const prospectsResponse = await axios.get(`/api/prospects/by-therapist/${therapistId}`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        params: { _: new Date().getTime() },
      });

      let prospectsData = Array.isArray(prospectsResponse.data) ? prospectsResponse.data : [];

      // Filter prospek berdasarkan filter yang dipilih
      prospectsData = filterData(prospectsData, filter);

      setProspects(prospectsData);

      // Fetch Customers
      const customersResponse = await axios.get(`/api/customers/${therapistId}`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        params: { _: new Date().getTime() },
      });

      let customersData = Array.isArray(customersResponse.data) ? customersResponse.data : [];

      // Filter pelanggan berdasarkan filter yang dipilih
      customersData = filterData(customersData, filter);

      setCustomers(customersData);

      // Kira Closing Rate
      const calculatedData = calculateClosingRate(prospectsData, customersData);
      setClosingRateData(calculatedData);

    } catch (error) {
      console.error('Error fetching data for Closing Rate:', error);
      toast.error('Gagal memuat data untuk Closing Rate.');
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk menapis data berdasarkan filter
  const filterData = (data, filter) => {
    const now = new Date();
    let filteredData = [...data];

    switch (filter) {
      case 'today':
        filteredData = data.filter(item =>
          isSameDay(new Date(item.bookingDate || item.createdAt || item.lastMessage?.date), now)
        );
        break;
      case 'this_week':
        const weekNumber = getWeekNumber(now);
        const year = now.getFullYear();
        const startOfWeek = getStartOfWeek(year, weekNumber);
        filteredData = data.filter(item => {
          const date = new Date(item.bookingDate || item.createdAt || item.lastMessage?.date);
          return date >= startOfWeek && date <= now;
        });
        break;
      case 'this_month':
        const month = now.getMonth();
        const yearMonth = now.getFullYear();
        const startOfMonth = new Date(yearMonth, month, 1);
        filteredData = data.filter(item => {
          const date = new Date(item.bookingDate || item.createdAt || item.lastMessage?.date);
          return date >= startOfMonth && date <= now;
        });
        break;
      case 'all':
      default:
        // Tidak menapis, gunakan semua data
        break;
    }

    return filteredData;
  };

  // Fungsi untuk menyemak sama ada dua tarikh adalah hari yang sama
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  // Fungsi untuk mendapatkan nombor minggu dalam setahun
  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1)/7);
    return weekNo;
  };

  // Fungsi untuk mendapatkan permulaan minggu
  const getStartOfWeek = (year, week) => {
    const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
    const dow = simple.getUTCDay();
    let ISOweekStart = new Date(simple);
    if (dow <= 4)
      ISOweekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
    else
      ISOweekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
    return ISOweekStart;
  };

  // Fungsi untuk mengira Closing Rate
  const calculateClosingRate = (prospectsData, customersData) => {
    const totalProspects = prospectsData.length;
    const totalCustomers = customersData.length;
    const total = totalProspects + totalCustomers;
    const closingRate = total > 0 ? Math.round((totalCustomers / total) * 100) : 0;
    const nonClosingRate = 100 - closingRate;

    return [
      { name: 'Closing Rate', value: closingRate },
      { name: 'Non Closing Rate', value: nonClosingRate },
    ];
  };

  // Menyediakan data untuk grafik donut
  const prepareChartData = () => {
    if (closingRateData.length === 0) {
      return [
        { name: 'Closing Rate', value: 0 },
        { name: 'Non Closing Rate', value: 100 },
      ];
    }

    return closingRateData;
  };

  return (
    <div className="closing-rate-chart">
      <h2>Closing Rate</h2>

      {loading ? (
        <div className="loading-spinner">Memuatkan data...</div>
      ) : closingRateData.length === 0 ? (
        <div className="no-data">Tiada data untuk dipaparkan.</div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={prepareChartData()}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label={({ name, value }) => `${name}: ${value}%`}
            >
              {prepareChartData().map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value}%`} />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ClosingRateChart;
