// TherapistList.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import './TherapistList.css';
import BookingForm from './BookingForm';
import UserLocationSelector from './UserLocationSelector';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Import WhatsApp icon
import Spinner from './Spinner'; // Import Spinner dari spinner.js


const TherapistList = ({ searchTerm }) => { // Receive searchTerm as a prop
  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableTherapists, setAvailableTherapists] = useState({});
  const [availableTreatments, setAvailableTreatments] = useState({}); // Tambah state ini
  const [selectedLocation, setSelectedLocation] = useState({ state: '', city: '' });
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [collapsedItems, setCollapsedItems] = useState({});

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${config.API_URL}/therapists`);
        setTherapists(response.data);
        checkAvailability(response.data);
      } catch (error) {
        console.error('Error fetching therapists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTherapists();
  }, []);

  const checkAvailability = async (therapistList) => {
    setLoadingSlots(true);
    const today = moment().tz('Asia/Kuala_Lumpur').startOf('day');
    setCheckedCount(0);

    const treatmentAvailability = {}; // Tempat simpan ketersediaan rawatan

    const requests = therapistList.map(async (therapist) => {
      try {
        const response = await axios.get(`${config.API_URL}/slots/${therapist._id}`);
        const availableSlots = response.data.filter((slot) => {
          const slotDate = moment.tz(slot.date, 'Asia/Kuala_Lumpur').startOf('day');
          return !slot.isBooked && slotDate.isSameOrAfter(today) && slot.treatment;
        });

        // Tentukan rawatan yang mempunyai slot tersedia
        const treatmentsWithSlots = new Set();
        availableSlots.forEach((slot) => {
          treatmentsWithSlots.add(slot.treatment);
        });

        // Simpan ketersediaan rawatan dalam treatmentAvailability
        treatmentAvailability[therapist._id] = Array.from(treatmentsWithSlots);

        // Tentukan jika therapist mempunyai sebarang slot tersedia
        const hasAnyAvailable = treatmentsWithSlots.size > 0;

        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: hasAnyAvailable,
        }));
      } catch (error) {
        console.error(`Error checking availability for therapist ${therapist._id}:`, error);
        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: false,
        }));
      } finally {
        setCheckedCount((prev) => prev + 1);
      }
    });

    await Promise.allSettled(requests);
    
    // Setelah semua permintaan selesai, set availableTreatments
    setAvailableTreatments(treatmentAvailability);
    setLoadingSlots(false);
  };

  const handleBooking = (therapist) => {
    if (availableTherapists[therapist._id]) {
      setSelectedTherapist(therapist);
      setIsModalOpen(true);
    }
  };

  const handleCloseBookingForm = () => {
    setSelectedTherapist(null);
    setIsModalOpen(false);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const filteredTherapists = therapists.filter((therapist) => {
    // Filter based on location
    if (selectedLocation.state && therapist.location.state !== selectedLocation.state) {
      return false;
    }
    if (selectedLocation.city && therapist.location.city !== selectedLocation.city) {
      return false;
    }
    // Filter based on search term from props
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      const nameMatch = therapist.name.toLowerCase().includes(term);
      const locationMatch =
        (therapist.location.state && therapist.location.state.toLowerCase().includes(term)) ||
        (therapist.location.city && therapist.location.city.toLowerCase().includes(term));
      const serviceMatch =
        (therapist.inCall && 'in-call'.includes(term)) ||
        (therapist.outCall && 'out-call'.includes(term));
      return nameMatch || locationMatch || serviceMatch;
    }
    return true;
  });

  // Definisikan keutamaan langganan berdasarkan subscriptionPlan
  const subscriptionPriority = {
    'plus': 1,
    'basic': 2,
    'free_trial': 3,
  };

  // Sort therapists berdasarkan keutamaan langganan dan kehadiran gambar
  const sortedTherapists = filteredTherapists.sort((a, b) => {
    // Keutamaan Langganan
    const aSubscriptionPriority = subscriptionPriority[a.subscriptionPlan] || 4;
    const bSubscriptionPriority = subscriptionPriority[b.subscriptionPlan] || 4;

    if (aSubscriptionPriority !== bSubscriptionPriority) {
      return aSubscriptionPriority - bSubscriptionPriority;
    }

    // Kehadiran Gambar Profil
    const aHasImage = a.image ? 1 : 0;
    const bHasImage = b.image ? 1 : 0;

    if (bHasImage - aHasImage !== 0) {
      return bHasImage - aHasImage;
    }

    // Ketersediaan
    const aAvailable = availableTherapists[a._id] ? 1 : 0;
    const bAvailable = availableTherapists[b._id] ? 1 : 0;
    return bAvailable - aAvailable;
  });

  const handleShareLink = useCallback((therapist) => {
    const therapistLink = `${window.location.origin}/therapist/${therapist._id}`;
    navigator.clipboard
      .writeText(therapistLink)
      .then(() => {
        alert('Link telah disalin ke clipboard. Anda boleh paste dan kongsikan.');
      })
      .catch((error) => {
        console.error('Gagal untuk copy link:', error);
      });
  }, []);

  const toggleCollapse = (id) => {
    setCollapsedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Fungsi untuk memformat nombor telefon WhatsApp
  const formatWhatsAppNumber = (phone) => {
    if (!phone) return null;

    // Hapus semua aksara bukan digit
    let cleaned = phone.replace(/\D/g, '');

    // Jika nombor bermula dengan '0', ganti dengan '60'
    if (cleaned.startsWith('0')) {
      cleaned = '60' + cleaned.slice(1);
    }

    // Sekarang, nombor harus bermula dengan '60' dan diikuti oleh 9 hingga 10 digit
    const isValid = /^60\d{9,10}$/.test(cleaned);
    return isValid ? cleaned : null;
  };

  // Fungsi untuk mengarahkan ke WhatsApp
  const handleWhatsApp = (therapist) => {
    const formattedNumber = formatWhatsAppNumber(therapist.phone);
    if (formattedNumber) {
      const message = encodeURIComponent("Hi, saya dari sistem booking therapist, nak tanya pasal rawatan boleh?");
      const whatsappURL = `https://wa.me/${formattedNumber}?text=${message}`;
      window.open(whatsappURL, '_blank');
    } else {
      alert('Nombor WhatsApp therapist tidak tersedia atau tidak sah.');
    }
  };

  return (
    <div className="therapist-list-container">
      {/* Header with Location Filter */}
      <div className="therapist-list-header">
        <div className="filter-container">
          <UserLocationSelector
            selectedState={selectedLocation.state}
            selectedCity={selectedLocation.city}
            onChange={handleLocationChange}
          />
        </div>
      </div>
      
      {/* Progress Bar for Availability Checking */}
      <div className="progress-bar-container">
        {loadingSlots && (
          <div className="progress-bar">
            <p>
              Memeriksa slot ketersediaan... {checkedCount}/{filteredTherapists.length} therapist telah diperiksa
            </p>
            <div className="progress">
              <div
                className="progress-filled"
                style={{ width: `${(checkedCount / filteredTherapists.length) * 100}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>

      {/* Therapist Cards */}
      <div className="card-container">
        {loading ? (
          <div className="loading-container">
            <Spinner /> {/* Gantikan spinner sedia ada dengan Spinner komponen */}
            <p>Sedang memuatkan senarai therapist, sila tunggu...</p>
          </div>
        ) : (
          sortedTherapists.map((therapist) => (
            <div
              key={therapist._id}
              className={`therapist-item ${
                availableTherapists[therapist._id] === false ? 'unavailable' : ''
              }`}
            >
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />
              <div className="therapist-info">
                <h2 className="therapist-name">{therapist.name}</h2>
                <p className="therapist-location">
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> {therapist.location?.state || 'Tidak dinyatakan'}, {therapist.location?.city || 'Tidak dinyatakan'}
                </p>
                <div className="therapist-services">
                  <strong>Jenis Rawatan:</strong>
                  {
                    therapist.jenisRawatan?.rawatanLain &&
                    therapist.jenisRawatan.rawatanLain.length > 0 ? (
                      (() => {
                        // Dapatkan rawatan yang mempunyai slot tersedia
                        const availableRawatan = therapist.jenisRawatan.rawatanLain.filter(
                          (rawatan) => availableTreatments[therapist._id]?.includes(rawatan)
                        );

                        if (availableRawatan.length === 0) {
                          return <p>Tiada rawatan dengan slot tersedia</p>;
                        }

                        return (
                          <>
                            <ul className={collapsedItems[therapist._id] ? 'expanded' : ''}>
                              {availableRawatan
                                .slice(0, collapsedItems[therapist._id] ? undefined : 3)
                                .map((rawatan, index) => (
                                  <li key={index}>{rawatan}</li>
                                ))}
                            </ul>
                            {availableRawatan.length > 3 && (
                              <button
                                className="collapse-button"
                                onClick={() => toggleCollapse(therapist._id)}
                              >
                                {collapsedItems[therapist._id] ? 'Lihat Kurang' : 'Lihat Lebih'}
                              </button>
                            )}
                          </>
                        );
                      })()
                    ) : (
                      <p>Tiada rawatan yang tersedia</p>
                    )
                  }
                </div>
                <p className="therapist-service-type">
                  <FontAwesomeIcon icon={faConciergeBell} /> {therapist.inCall && therapist.outCall
                    ? 'In-Call & Out-Call'
                    : therapist.inCall
                    ? 'In-Call'
                    : therapist.outCall
                    ? 'Out-Call'
                    : 'Tidak dinyatakan'}
                </p>
                {availableTherapists[therapist._id] === undefined ? (
                  <div className="loading-placeholder">
                    <Spinner className="small-spinner" />
                    <span className="loading-text">Memeriksa ketersediaan...</span>
                  </div>
                ) : availableTherapists[therapist._id] ? (
                  <div className="button-group">
                    <button className="btn book-btn" onClick={() => handleBooking(therapist)}>
                      Tempah Sekarang
                    </button>
                    <button className="btn share-btn" onClick={() => handleShareLink(therapist)}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> Kongsi Pautan
                    </button>
                    {therapist.phone && (
                      <button
                        className="therapistlist-whatsapp-btn btn whatsapp-btn"
                        onClick={() => handleWhatsApp(therapist)}
                        title="Hubungi melalui WhatsApp"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} /> {/* Ikon WhatsApp */}
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="no-slots-message">Tiada slot tersedia</div>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {/* Booking Form Modal */}
      {isModalOpen && (
        <div className="booking-form-overlay" onClick={handleCloseBookingForm}>
          <div
            className="booking-form-modal"
            onClick={(e) => e.stopPropagation()} // Supaya klik di modal tidak tutup overlay
          >
            {/* Borang Tempahan */}
            <BookingForm
              selectedTherapist={selectedTherapist}
              onClose={handleCloseBookingForm}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TherapistList;
