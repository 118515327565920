// src/components/Labels.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Labels.css';
import { FaTag, FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Spinner from './Spinner'; // Pastikan path ini betul

const Labels = ({ therapistId }) => {
  const [labels, setLabels] = useState([]);
  const [labelsWithContacts, setLabelsWithContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedLabels, setExpandedLabels] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const fetchLabels = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/whatsapp/getLabels/${therapistId}`, null, {
        headers: {
          'x-auth-token': token,
        },
      });

      if (response.data.success) {
        const labelsData = response.data.labels || [];
        setLabels(labelsData);
      } else {
        throw new Error(response.data.error || 'Gagal mendapatkan label');
      }
    } catch (error) {
      console.error('Error fetching labels:', error);
      toast.error('Gagal mendapatkan label');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (therapistId) {
      fetchLabels();
    }
  }, [therapistId]);

  const fetchContactsInLabel = async (labelId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `/api/whatsapp/getChatsByLabelId/${therapistId}`,
        { labelId },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      if (response.data.success) {
        const chats = response.data.chats || [];
        return chats;
      } else {
        throw new Error(response.data.error || 'Gagal mendapatkan kontak dalam label');
      }
    } catch (error) {
      console.error('Error fetching contacts in label:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAllContactsInLabels = async () => {
      const updatedLabels = await Promise.all(
        labels.map(async (label) => {
          const contacts = await fetchContactsInLabel(label.id);
          return { ...label, contacts };
        })
      );
      setLabelsWithContacts(updatedLabels);
    };

    if (labels.length > 0) {
      fetchAllContactsInLabels();
    }
  }, [labels]);

  const toggleLabel = (labelId) => {
    setExpandedLabels((prevState) => ({
      ...prevState,
      [labelId]: !prevState[labelId],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="labels-container">
      <h2>
        <FaTag /> Senarai Labels
      </h2>
      <input
        type="text"
        placeholder="Cari kontak..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-input"
      />
      {isLoading ? (
        <div className="loading-state">
          <Spinner
            type="SyncLoader" // Menetapkan jenis spinner kepada SyncLoader
            size={10}          // SyncLoader biasanya lebih kecil, boleh diubah mengikut keperluan
            color="#2980b9"
            label="Memuatkan label..."
          />
        </div>
      ) : (
        <div className="labels-list">
          {labelsWithContacts.map((label) => (
            <div key={label.id} className="label-item">
              <div className="label-header" onClick={() => toggleLabel(label.id)}>
                <div className="label-title">
                  <FaTag className="label-icon" />
                  <h3>
                    {label.name} ({label.contacts.length})
                  </h3>
                </div>
                <span className="toggle-icon">{expandedLabels[label.id] ? '▼' : '▲'}</span>
              </div>
              {expandedLabels[label.id] && label.contacts && label.contacts.length > 0 && (
                <ul className="contacts-list">
                  {label.contacts
                    .filter((contact) => {
                      const contactName =
                        contact.name || contact.id.user || contact.id._serialized;
                      return contactName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                    })
                    .map((contact) => (
                      <li key={contact.id._serialized} className="contact-item">
                        <FaUser className="contact-icon" />{' '}
                        {contact.name || contact.id.user || contact.id._serialized}
                      </li>
                    ))}
                </ul>
              )}
              {expandedLabels[label.id] && (!label.contacts || label.contacts.length === 0) && (
                <p>Tiada kontak dalam label ini.</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Labels;
