// MyPublicPageSetup.js

import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import './MyPublicPageSetup.css'; // Asingkan CSS untuk setup ini

function MyPublicPageSetup({ therapist }) {
  const defaultBulletPoints = [
    'Professional Care',
    'Expert Therapists',
    'Peaceful Environment'
  ];

  const [bulletPoints, setBulletPoints] = useState(
    therapist.publicBulletPoints && therapist.publicBulletPoints.length > 0
      ? therapist.publicBulletPoints
      : defaultBulletPoints
  );

  const [tagline, setTagline] = useState(therapist.publicTagline || '');
  const [introText, setIntroText] = useState(therapist.publicIntroText || '');
  const token = localStorage.getItem('token');

  const publicDomain = 'https://malaysian.therapist.matintherapy.com';

  const handleSave = async () => {
    try {
      const res = await axios.put(
        `${config.API_URL}/therapist-public-config/${therapist._id}`,
        {
          publicTagline: tagline,
          publicIntroText: introText,
          publicBulletPoints: bulletPoints
        },
        { headers: { 'x-auth-token': token } }
      );
      if (res.data.success) {
        alert('Public info updated successfully!');
      } else {
        alert('Error updating public info');
      }
    } catch (err) {
      console.error(err);
      alert('Server error while updating');
    }
  };

  const publicUrl = therapist.publicSlug
    ? `${publicDomain}/${therapist.publicSlug}`
    : null;

  return (
    <div className="my-public-page-setup-container">
      <h2>My Public Page Setup</h2>

      {publicUrl ? (
        <p>
          <strong>URL Public Page: </strong>
          <a href={publicUrl} target="_blank" rel="noopener noreferrer">
            {publicUrl}
          </a>
        </p>
      ) : (
        <p>Belum ada slug. Sila pastikan nama therapist diupdate.</p>
      )}

      <div className="my-public-page-setup-form-group">
        <label>Tagline:</label>
        <input
          type="text"
          value={tagline}
          onChange={(e) => setTagline(e.target.value)}
        />
      </div>

      <div className="my-public-page-setup-form-group">
        <label>Intro Text:</label>
        <textarea
          rows={5}
          value={introText}
          onChange={(e) => setIntroText(e.target.value)}
        />
      </div>

      <div className="my-public-page-setup-form-group">
        <label>Bullet Points:</label>
        {bulletPoints.map((point, index) => (
          <div key={index} className="my-public-page-setup-bullet-point-item">
            <input
              type="text"
              value={point}
              onChange={(e) => {
                const newBulletPoints = [...bulletPoints];
                newBulletPoints[index] = e.target.value;
                setBulletPoints(newBulletPoints);
              }}
            />
            <button
              type="button"
              onClick={() =>
                setBulletPoints(bulletPoints.filter((_, i) => i !== index))
              }
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setBulletPoints([...bulletPoints, ''])}
        >
          Add Bullet Point
        </button>
      </div>

      <button className="my-public-page-setup-save-button" onClick={handleSave}>
        Save
      </button>
    </div>
  );
}

export default MyPublicPageSetup;
