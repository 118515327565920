// UpgradePlanModal.js

import React from 'react';
import './UpgradePlanModal.css';
import { FaCheck } from 'react-icons/fa';

function UpgradePlanModal({ onClose }) { // Tidak memerlukan userName sebagai props
  const packages = [
    {
      name: 'Plus',
      price: 'RM39/bulan',
      benefits: [
        {
          category: 'Akses penuh pada sistem',
          items: []
        },
        {
          category: 'CRM Tab',
          items: [
            'Connect WhatsApp',
            'Auto Lead Prospek',
            'Auto Pipeline Customer dan VVIP',
            'Broadcast Sistem',
            'Dapatkan List Label'
          ]
        },
        {
          category: 'Available Slot',
          items: ['Boleh tambah slot sampai 3 bulan']
        },
        {
          category: 'Edit Profile',
          items: ['Tiada had untuk tambah treatment']
        },
        {
          category: 'Webhook',
          items: ['Boleh integrasikan dengan sistem luar untuk menerima data dari luar']
        },
        {
          category: 'Sales',
          items: [
            'Data Sales setiap hari',
            'Report Sales',
            'Sales Overview',
            'Account Financial (menguruskan account syarikat)',
            'Auto Komisen Setup',
            'KPI Syarikat Progress',
            'Expenses & Sales Setup'
          ]
        },
        {
          category: 'Integrate Google Calendar',
          items: ['Boleh paparkan booking dalam sistem auto ke Google Calendar']
        },
        {
          category: 'My Booking',
          items: ['Diagnos Form', 'Closing Sales']
        }
      ],
      recommended: true, // Menandakan pakej ini disyorkan
    },
    {
      name: 'Basic',
      price: 'RM9/bulan',
      benefits: [
        {
          category: 'Akses penuh pada sistem',
          items: []
        },
        {
          category: 'Available Slot',
          items: ['Boleh tambah slot sampai 3 bulan']
        },
        {
          category: 'Edit Profile',
          items: ['Tiada had untuk tambah treatment']
        },
        {
          category: 'My Booking',
          items: ['Diagnos Form', 'Closing Sales']
        }
      ]
    },
    {
      name: 'Free Trial',
      price: 'Free',
      benefits: [
        {
          category: 'Akses penuh pada sistem',
          items: []
        },
        {
          category: 'Available Slot',
          items: ['Dapat sebulan sahaja slot']
        },
        {
          category: 'My Booking',
          items: ['Diagnos Form', 'Closing Sales'],
        },
        {
          category: 'Treatment',
          items: ['Hanya boleh tambah 3 treatment']
        }
      ]
    },
  ];

  const handleSelectPlan = (pkgName) => {
    const message = `Salam tuan akmal, saya nak upgrade ke plan ${pkgName}, macam mana nak buat pembayaran?`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappNumber = '601160598203'; // Gantikan dengan nombor WhatsApp yang betul
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="upgrade-modal-overlay">
      <div className="upgrade-modal">
        <h2>Upgrade Langganan Anda</h2>
        <p>Pilih pakej yang sesuai untuk mendapatkan akses penuh ke semua fungsi:</p>
        <div className="packages-container">
          {packages.map((pkg) => (
            <div
              key={pkg.name}
              className={`package-card ${pkg.recommended ? 'recommended' : ''}`}
            >
              {pkg.recommended && <span className="recommended-badge">Recommended</span>}
              <h3>{pkg.name}</h3>
              <p className="price">{pkg.price}</p>
              <ul>
                {pkg.benefits.map((benefit, index) => (
                  <li key={index}>
                    <strong>{benefit.category}:</strong>
                    <ul>
                      {benefit.items.length > 0 ? (
                        benefit.items.map((item, subIndex) => (
                          <li key={subIndex}>
                            <FaCheck className="check-icon" /> {item}
                          </li>
                        ))
                      ) : (
                        <li>
                          <FaCheck className="check-icon" /> Termasuk semua akses sistem
                        </li>
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
              <button
                className="select-button"
                onClick={() => handleSelectPlan(pkg.name)}
              >
                Pilih {pkg.name}
              </button>
            </div>
          ))}
        </div>
        {/* Butang Tutup di Bawah */}
        <button onClick={onClose} className="upgrade-modal-close-button">
          Tutup
        </button>
      </div>
    </div>
  );
}

export default UpgradePlanModal;
