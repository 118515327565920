// src/components/IgnoreList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './IgnoreList.css'; // import css khas

const IgnoreList = () => {
  const [ignoredPhones, setIgnoredPhones] = useState([]);
  const [newPhone, setNewPhone] = useState('');
  const [userWebhooks, setUserWebhooks] = useState([]);
  const [selectedWebhookId, setSelectedWebhookId] = useState('');
  const [webhookData, setWebhookData] = useState([]);
  const [phoneField, setPhoneField] = useState(''); // cth: "billing.phone"

  const therapistId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  // 1. Dapatkan senarai ignored phones
  const fetchIgnoreList = async () => {
    try {
      const res = await axios.get(`/api/ignore-phones/${therapistId}`, {
        headers: { 'x-auth-token': token },
      });
      setIgnoredPhones(res.data);
    } catch (error) {
      console.error('Error fetching ignored phones:', error);
      toast.error('Gagal mendapatkan senarai nombor ignore');
    }
  };

  // 2. Dapatkan senarai webhooks user
  const fetchUserWebhooks = async () => {
    try {
      const res = await axios.get('/webhook/user-webhooks', {
        headers: { 'x-auth-token': token },
      });
      setUserWebhooks(res.data);
    } catch (error) {
      console.error('Error fetching user webhooks:', error);
      toast.error('Gagal mendapatkan senarai webhooks.');
    }
  };

  // 3. Dapatkan data webhook untuk ID terpilih
  const fetchWebhookData = async (webhookId) => {
    try {
      const res = await axios.get(`/webhook/${webhookId}/received-data`, {
        headers: { 'x-auth-token': token },
      });
      setWebhookData(res.data);
    } catch (error) {
      console.error('Error fetching webhook data:', error);
      toast.error('Gagal mendapatkan data webhook.');
    }
  };

  useEffect(() => {
    fetchIgnoreList();
    fetchUserWebhooks();
  }, []);

  const handleWebhookChange = (e) => {
    const id = e.target.value;
    setSelectedWebhookId(id);
    if (id) {
      fetchWebhookData(id);
    } else {
      setWebhookData([]);
    }
  };

  // Fungsi bantu: ambil nested field
  const getValueByPath = (obj, path) => {
    // Contoh path = "billing.phone"
    return path.split('.').reduce((acc, key) => {
      return acc && acc[key] !== undefined ? acc[key] : null;
    }, obj);
  };

  // Tambah phone manual
  const handleAddPhone = async (e) => {
    e.preventDefault();
    if (!newPhone) {
      toast.warning('Sila masukkan nombor phone');
      return;
    }

    try {
      await axios.post(
        '/api/ignore-phones',
        { therapistId, phoneNumber: newPhone },
        { headers: { 'x-auth-token': token } }
      );
      toast.success('Nombor berjaya ditambah ke senarai ignore');
      setNewPhone('');
      fetchIgnoreList();
    } catch (error) {
      console.error('Error adding phone to ignore list:', error);
      toast.error(error.response?.data?.error || 'Ralat semasa menambah nombor ke senarai ignore');
    }
  };

  // Padam satu ignore
  const handleDelete = async (id) => {
    if (!window.confirm('Adakah anda pasti untuk buang nombor ini dari senarai ignore?')) return;
    try {
      await axios.delete(`/api/ignore-phones/${id}`, {
        headers: { 'x-auth-token': token },
      });
      toast.success('Nombor berjaya dibuang dari senarai ignore');
      setIgnoredPhones(ignoredPhones.filter((item) => item._id !== id));
    } catch (error) {
      console.error('Error deleting phone from ignore list:', error);
      toast.error('Ralat semasa memadam nombor');
    }
  };

  // Set ignore phone dari data webhook
  const handleIgnoreWebhookPhone = async (webhookItem) => {
    if (!phoneField) {
      toast.warning('Sila isi medan phoneField.');
      return;
    }

    const phoneValue = getValueByPath(webhookItem.payload, phoneField);
    if (!phoneValue) {
      toast.error(`Medan ${phoneField} tiada nilai sah.`);
      return;
    }

    try {
      await axios.post(
        '/api/ignore-phones',
        { therapistId, phoneNumber: phoneValue },
        { headers: { 'x-auth-token': token } }
      );
      toast.success(`Nombor ${phoneValue} berjaya di-Ignore.`);
      fetchIgnoreList();
    } catch (error) {
      console.error('Error ignoring phone from webhook data:', error);
      toast.error(error.response?.data?.error || 'Ralat semasa set ignore phone');
    }
  };

  return (
    <div className="ignore-list-container">
      <h3>Ignore Phone Numbers</h3>

      {/* 1. Tambah no phone manual */}
      <div className="ignore-add-section">
        <form onSubmit={handleAddPhone} className="ignore-add-form">
          <label>
            Tambah No Phone:
            <input
              type="text"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              placeholder="contoh: 60123456789"
            />
          </label>
          <button type="submit">Tambah</button>
        </form>
      </div>

      {/* 2. Senarai phone yang diabaikan */}
      <div className="ignore-list-table">
        <h4>Nombor yang Diabaikan</h4>
        {ignoredPhones.length === 0 ? (
          <p>Tiada nombor di senarai ignore buat masa ini.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Nombor Phone</th>
                <th>Tarikh Buat</th>
                <th>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {ignoredPhones.map((item) => (
                <tr key={item._id}>
                  <td>{item.phoneNumber}</td>
                  <td>{new Date(item.createdAt).toLocaleString('ms-MY')}</td>
                  <td>
                    <button onClick={() => handleDelete(item._id)}>Padam</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* 3. Ignore phone dari data Webhook */}
      <div className="ignore-webhook-section">
        <h4>Ignore Phone dari Data Webhook</h4>

        <div className="webhook-selector">
          <label>Pilih Webhook:</label>
          <select value={selectedWebhookId} onChange={handleWebhookChange}>
            <option value="">-- Pilih --</option>
            {userWebhooks.map((wh) => (
              <option key={wh._id} value={wh.webhookId}>
                {wh.name || wh.webhookId}
              </option>
            ))}
          </select>
        </div>

        {selectedWebhookId && (
          <>
            <div className="webhook-phonefield-input">
              <label>
                Medan Phone (contoh "billing.phone"):
                <input
                  type="text"
                  value={phoneField}
                  onChange={(e) => setPhoneField(e.target.value)}
                  placeholder="cth: billing.phone"
                />
              </label>
            </div>

            {webhookData.length === 0 ? (
              <p>Tiada data lagi untuk webhook ini atau masih memuat...</p>
            ) : (
              <div className="webhook-data-list">
                {webhookData.map((item, idx) => (
                  <div key={idx} className="webhook-data-item">
                    <pre className="webhook-json">{JSON.stringify(item.payload, null, 2)}</pre>
                    <button onClick={() => handleIgnoreWebhookPhone(item)}>
                      Ignore Phone
                    </button>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IgnoreList;
