// src/components/Broadcast.js
import React, { useState, useEffect, useRef } from 'react';
import {
  FaExclamationTriangle,
  FaPaperPlane,
  FaTrashAlt,
  FaSpinner,
  FaClock,
  FaCheckCircle,
  FaPauseCircle,
  FaHourglassHalf,
  FaCopy
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import './Broadcast.css';
import config from '../config';
import axios from 'axios';
import moment from 'moment-timezone';

const Broadcast = ({ therapistId }) => {
  // Target type: 'group' or 'label'
  const [targetType, setTargetType] = useState('group');
  const [campaignName, setCampaignName] = useState('');
  const [targetGroup, setTargetGroup] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [message, setMessage] = useState('');
  const [minInterval, setMinInterval] = useState(60);
  const [estimatedCompletion, setEstimatedCompletion] = useState('');
  const [scheduleType, setScheduleType] = useState('instant');
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [schedule, setSchedule] = useState({
    startDate: '',
  });
  const [testNumber, setTestNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Contact count object (e.g. { prospect: 0, customer: 0, vvip: 0 })
  const [contactCounts, setContactCounts] = useState({
    prospect: 0,
    customer: 0,
    vvip: 0,
  });

  // Date filters for contacts
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  // Seeding type
  const [seeding, setSeeding] = useState('');

  // For optional image
  const [imageFile, setImageFile] = useState(null);

  // Which tab is active: 'instant' or 'scheduled'
  const [activeTab, setActiveTab] = useState('instant');

  // Polling interval reference
  const progressIntervalRef = useRef(null);

  // Time slots for 24 hours
  const timeSlots = Array.from({ length: 24 }, (_, i) => {
    const startHour = i.toString().padStart(2, '0') + ':00';
    const endHour = ((i + 1) % 24).toString().padStart(2, '0') + ':00';
    return `${startHour}-${endHour}`;
  });

  // State to track show/hide for each broadcast message
  const [expandedMessages, setExpandedMessages] = useState({});

  // ---------------------------------------------------------
  // 1) FETCHING DATA / LABELS / CONTACT COUNTS / CAMPAIGNS
  // ---------------------------------------------------------
  const fetchLabels = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/whatsapp/getLabels/${therapistId}`, null, {
        headers: {
          'x-auth-token': token,
        },
      });

      if (response.data.success) {
        setLabels(response.data.labels || []);
      } else {
        throw new Error(response.data.error || 'Failed to fetch labels');
      }
    } catch (error) {
      console.error('Error fetching labels:', error);
      toast.error('Failed to fetch labels.');
    }
  };

  const fetchContactCounts = async (therapistId, filterStart, filterEnd) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/contacts/counts/${therapistId}`, {
        headers: { 'x-auth-token': token },
        params: {
          filterStartDate: filterStart,
          filterEndDate: filterEnd,
        },
      });
      setContactCounts(response.data);
    } catch (error) {
      console.error('Failed to fetch contact counts:', error);
      toast.error('Failed to fetch contact counts.');
    }
  };

  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/broadcast/campaigns/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
        params: {
          filterStartDate,
          filterEndDate,
        },
      });
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error('Failed to fetch campaigns:', error);
      toast.error('Failed to fetch campaign list.');
    }
  };

  useEffect(() => {
    if (therapistId) {
      fetchLabels();
    }
  }, [therapistId]);

  useEffect(() => {
    if (therapistId) {
      fetchContactCounts(therapistId, filterStartDate, filterEndDate);
    }
  }, [therapistId, filterStartDate, filterEndDate]);

  // Poll for real-time campaign progress (every 30s)
  useEffect(() => {
    progressIntervalRef.current = setInterval(() => {
      if (therapistId) {
        fetchCampaigns();
      }
    }, 30000);

    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [therapistId]);

  useEffect(() => {
    if (therapistId) {
      fetchCampaigns();
    }
  }, [therapistId, filterStartDate, filterEndDate]);

  // ---------------------------------------------------------
  // 2) TIME SLOTS & ESTIMATED COMPLETION
  // ---------------------------------------------------------
  const toggleTimeSlot = (slot) => {
    setSelectedTimeSlots((prev) => {
      if (prev.includes(slot)) {
        return prev.filter((s) => s !== slot);
      } else {
        return [...prev, slot];
      }
    });
  };

  useEffect(() => {
    const calculateEstimatedCompletion = () => {
      let groupCount = 0;
      if (targetType === 'group') {
        groupCount = contactCounts[targetGroup] || 0;
      } else if (targetType === 'label') {
        // For simplicity, each selected label might have 50 contacts, or
        // adapt logic to fetch the actual count from back end if needed.
        groupCount = selectedLabelIds.length * 50;
      }

      const totalSeconds = groupCount * minInterval;

      // Calculate total time slot seconds (each slot is 1 hour = 3600s)
      const totalSlotSeconds = selectedTimeSlots.length * 3600;
      if (totalSlotSeconds === 0) {
        setEstimatedCompletion('No timeslots selected.');
        return;
      }

      // Sort slots (so we get the earliest in the day)
      const sortedSlots = selectedTimeSlots.sort();
      const firstSlotStart = sortedSlots[0].split('-')[0];

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const startDateTime = moment.tz(
        `${schedule.startDate} ${firstSlotStart}`,
        'YYYY-MM-DD HH:mm',
        timeZone
      );

      // Rough calculation: how many days needed
      const daysNeeded = Math.floor(totalSeconds / totalSlotSeconds) + 1;

      // Estimated completion date
      let estimatedCompletionDate = startDateTime.clone().add(daysNeeded - 1, 'days');
      setEstimatedCompletion(estimatedCompletionDate.format('DD/MM/YYYY HH:mm'));
    };

    if (scheduleType === 'scheduled' && selectedTimeSlots.length > 0 && schedule.startDate) {
      calculateEstimatedCompletion();
    }
  }, [
    targetGroup,
    selectedLabelIds,
    targetType,
    minInterval,
    contactCounts,
    scheduleType,
    schedule,
    selectedTimeSlots
  ]);

  // ---------------------------------------------------------
  // 3) CREATE BROADCAST CAMPAIGN
  // ---------------------------------------------------------
  const handleCreateCampaign = async (e) => {
    e.preventDefault();

    if (scheduleType === 'scheduled' && selectedTimeSlots.length === 0) {
      toast.error('Please select at least one time slot.');
      return;
    }

    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      let startDateTime = null;

      if (scheduleType === 'scheduled') {
        const sortedSlots = [...selectedTimeSlots].sort();
        const firstSlotStart = sortedSlots[0].split('-')[0];
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const startDateTimeString = `${schedule.startDate} ${firstSlotStart}`;
        startDateTime = moment
          .tz(startDateTimeString, 'YYYY-MM-DD HH:mm', timeZone)
          .format();
      }

      const formData = new FormData();
      formData.append('therapistId', therapistId);
      formData.append('campaignName', campaignName);
      formData.append('message', message);
      formData.append('minInterval', minInterval);
      formData.append('scheduleType', scheduleType);
      formData.append('seeding', seeding);
      formData.append('filterStartDate', filterStartDate || null);
      formData.append('filterEndDate', filterEndDate || null);

      formData.append('targetType', targetType);
      if (targetType === 'group') {
        formData.append('targetGroup', targetGroup);
      } else if (targetType === 'label') {
        formData.append('targetLabels', JSON.stringify(selectedLabelIds));
      }

      if (scheduleType === 'scheduled') {
        formData.append(
          'schedule',
          JSON.stringify({
            ...schedule,
            startDate: startDateTime,
            timeSlots: selectedTimeSlots,
          })
        );
      }

      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axios.post('/api/broadcast/campaign', formData, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Broadcast campaign created successfully!');
        // Reset form
        setCampaignName('');
        setTargetGroup('');
        setSelectedLabelIds([]);
        setMessage('');
        setSchedule({ startDate: '' });
        setSelectedTimeSlots([]);
        setFilterStartDate('');
        setFilterEndDate('');
        setImageFile(null);
        fetchCampaigns();
      } else {
        throw new Error(response.data.error || 'Failed to create broadcast campaign');
      }
    } catch (error) {
      console.error('Campaign creation error:', error);
      toast.error(error.message || 'Failed to create broadcast campaign');
    }
    setIsLoading(false);
  };

  // ---------------------------------------------------------
  // 4) DELETE CAMPAIGNS
  // ---------------------------------------------------------
  const toggleCampaignSelection = (campaignId) => {
    setSelectedCampaigns((prevSelected) => {
      if (prevSelected.includes(campaignId)) {
        return prevSelected.filter((id) => id !== campaignId);
      } else {
        return [...prevSelected, campaignId];
      }
    });
  };

  const handleDeleteCampaigns = async () => {
    if (selectedCampaigns.length === 0) {
      toast.error('Please select at least one campaign to delete.');
      return;
    }

    if (!window.confirm('Are you sure you want to delete the selected campaign(s)?')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete('/api/broadcast/campaigns', {
        headers: {
          'x-auth-token': token,
        },
        data: { campaignIds: selectedCampaigns },
      });

      if (response.data.success) {
        toast.success('Selected campaign(s) deleted successfully.');
        setCampaigns((prev) => prev.filter((c) => !selectedCampaigns.includes(c._id)));
        setSelectedCampaigns([]);
      } else {
        throw new Error(response.data.error || 'Failed to delete campaign(s)');
      }
    } catch (error) {
      console.error('Delete campaign error:', error);
      toast.error(error.message || 'Failed to delete campaign(s)');
    }
  };

  // ---------------------------------------------------------
  // 5) TEST SEND
  // ---------------------------------------------------------
  const handleTestSend = async () => {
    if (!testNumber || (!message && !imageFile)) {
      toast.error('Please provide a phone number and a message or select an image for the test.');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('therapistId', therapistId);
      formData.append('testNumber', testNumber);
      formData.append('message', message);
      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axios.post('/api/broadcast/test-send', formData, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Test message sent successfully!');
      } else {
        throw new Error(response.data.error || 'Failed to send test message');
      }
    } catch (error) {
      console.error('Test send error:', error);
      toast.error(error.message || 'Failed to send test message');
    }
    setIsLoading(false);
  };

  // ---------------------------------------------------------
  // 6) COPY BROADCAST TEXT (FROM PREVIOUS CAMPAIGNS)
  // ---------------------------------------------------------
  const handleCopyBroadcastText = (text) => {
    setMessage(text);
    toast.info('Broadcast message copied to the textarea!');
    // If also want to copy to clipboard:
    // navigator.clipboard.writeText(text);
  };

  // Toggle expanded state for each message
  const toggleExpandMessage = (id) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // ---------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------
  return (
    <div className="broadcast-container">
      {/* Warning Section */}
      <div className="broadcast-warning">
        <FaExclamationTriangle className="warning-icon" />
        <p>
          WARNING: Please be careful using the broadcast feature. Make sure the
          phone number is warm enough for broadcasts, and follow recommended
          time intervals. The suggested minimum interval is 60 seconds.
        </p>
      </div>

      {/* Form to create new broadcast campaign */}
      <div className="broadcast-form">
        <h3>Create New Broadcast Campaign</h3>

        <div className="form-group">
          <label>Campaign Name:</label>
          <input
            type="text"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            placeholder="Enter a campaign name"
          />
        </div>

        <div className="form-group">
          <label>Target Type:</label>
          <select value={targetType} onChange={(e) => setTargetType(e.target.value)}>
            <option value="group">Group Audience</option>
            <option value="label">Label Audience</option>
          </select>
        </div>

        {targetType === 'group' && (
          <div className="form-group">
            <label>Group Audience:</label>
            <select value={targetGroup} onChange={(e) => setTargetGroup(e.target.value)}>
              <option value="">-- Select a group --</option>
              {contactCounts.prospect > 0 && (
                <option value="prospect">Prospect ({contactCounts.prospect})</option>
              )}
              {contactCounts.customer > 0 && (
                <option value="customer">Customer ({contactCounts.customer})</option>
              )}
              {contactCounts.vvip > 0 && (
                <option value="vvip">VVIP ({contactCounts.vvip})</option>
              )}
            </select>
          </div>
        )}

        {targetType === 'label' && (
          <div className="form-group">
            <label>Select Contact Labels:</label>
            <select
              multiple
              value={selectedLabelIds}
              onChange={(e) =>
                setSelectedLabelIds(Array.from(e.target.selectedOptions, (option) => option.value))
              }
            >
              {labels.map((label) => (
                <option key={label.id} value={label.id}>
                  {label.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Seeding Type:</label>
          <select value={seeding} onChange={(e) => setSeeding(e.target.value)}>
            <option value="">-- Select a seeding --</option>
            {[...Array(20)].map((_, i) => (
              <option key={i} value={`seeding ${i + 1}`}>
                Seeding {i + 1}
              </option>
            ))}
          </select>
        </div>

        {/* Filter date range for prospects */}
        <div className="form-group">
          <label>Filter Prospects by Date Range:</label>
          <div className="date-range">
            <input
              type="date"
              value={filterStartDate}
              onChange={(e) => setFilterStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <span> - </span>
            <input
              type="date"
              value={filterEndDate}
              onChange={(e) => setFilterEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your broadcast message here"
            rows={4}
            style={{ minHeight: '100px' }}
          />
        </div>

        <div className="form-group">
          <label>Upload an Image (optional):</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
        </div>

        {/* Campaign History for copying messages */}
        <div className="history-box">
          <h4>Previous Campaigns (Copy Broadcast Text)</h4>
          {campaigns.length === 0 && <p>No previous campaigns found.</p>}

          {campaigns.length > 0 && (
            <div className="history-list">
              <ul>
                {campaigns.map((c) => {
                  const isExpanded = expandedMessages[c._id] || false;
                  // Shortened text version
                  const shortMessage = c.message?.length > 120
                    ? c.message.slice(0, 120) + '...'
                    : c.message;

                  return (
                    <li key={c._id}>
                      <div className="history-seeding">{c.seeding || '-'}</div>
                      <div className="history-message">
                        {isExpanded ? c.message : shortMessage}
                      </div>
                      {c.message?.length > 120 && (
                        <button
                          className="show-more-button"
                          onClick={() => toggleExpandMessage(c._id)}
                        >
                          {isExpanded ? 'Hide' : 'Show More'}
                        </button>
                      )}
                      <button
                        className="history-copy-button"
                        onClick={() => handleCopyBroadcastText(c.message)}
                      >
                        <FaCopy /> Copy
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        {/* Test broadcast */}
        <div className="test-section">
          <h4>Test Broadcast</h4>
          <div className="form-group">
            <input
              type="text"
              value={testNumber}
              onChange={(e) => setTestNumber(e.target.value)}
              placeholder="Enter phone number for test"
            />
            <button
              onClick={handleTestSend}
              disabled={isLoading}
              className="test-button"
            >
              <FaPaperPlane /> Send Test
            </button>
          </div>
        </div>

        <div className="form-group">
          <label>
            <FaClock /> Minimum Interval (seconds):
          </label>
          <input
            type="number"
            min={60}
            value={minInterval}
            onChange={(e) => setMinInterval(Number(e.target.value))}
          />
        </div>

        <div className="form-group">
          <label>Broadcast Type:</label>
          <select value={scheduleType} onChange={(e) => setScheduleType(e.target.value)}>
            <option value="instant">Instant Broadcast</option>
            <option value="scheduled">Scheduled Broadcast</option>
          </select>
        </div>

        {scheduleType === 'scheduled' && (
          <>
            <div className="form-group">
              <label>Select Time Slots:</label>
              <div className="time-slots">
                {timeSlots.map((slot) => (
                  <div
                    key={slot}
                    className={`time-slot ${selectedTimeSlots.includes(slot) ? 'selected' : ''}`}
                    onClick={() => toggleTimeSlot(slot)}
                  >
                    {slot}
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Select Start Date:</label>
              <input
                type="date"
                value={schedule.startDate}
                onChange={(e) => setSchedule({ ...schedule, startDate: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label>Estimated Completion:</label>
              <p>{estimatedCompletion}</p>
            </div>
          </>
        )}

        <button
          onClick={handleCreateCampaign}
          disabled={isLoading}
          className="create-campaign-button"
        >
          Create Broadcast Campaign
        </button>
      </div>

      {/* Campaign List */}
      <div className="campaign-list">
        <h3>Campaign List</h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'instant' ? 'active' : ''}`}
            onClick={() => setActiveTab('instant')}
          >
            Instant Broadcast
          </button>
          <button
            className={`tab ${activeTab === 'scheduled' ? 'active' : ''}`}
            onClick={() => setActiveTab('scheduled')}
          >
            Scheduled Broadcast
          </button>
        </div>

        <button
          onClick={handleDeleteCampaigns}
          disabled={selectedCampaigns.length === 0}
          className="delete-campaign-button"
        >
          <FaTrashAlt /> Delete Selected Campaign(s)
        </button>

        <div className="table-responsive">
          <table className="campaign-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const filteredCampaigns = campaigns.filter(
                          (c) => c.scheduleType === activeTab
                        );
                        setSelectedCampaigns(filteredCampaigns.map((c) => c._id));
                      } else {
                        setSelectedCampaigns([]);
                      }
                    }}
                    checked={
                      campaigns.filter((c) => c.scheduleType === activeTab).length > 0 &&
                      selectedCampaigns.length ===
                        campaigns.filter((c) => c.scheduleType === activeTab).length
                    }
                  />
                </th>
                <th>Campaign Name</th>
                <th>Target Group / Label</th>
                <th>Start Date</th>
                <th>Time Slots</th>
                <th>Broadcast Type</th>
                <th>Prospect Date Range</th>
                <th>Seeding Type</th>
                <th>Status</th>
                <th>Progress</th>
              </tr>
            </thead>

            <tbody>
              {campaigns
                .filter((c) => c.scheduleType === activeTab)
                .map((campaign) => {
                  const successCount = campaign.recipients.filter((r) => r.status === 'sent')
                    .length;
                  const failedCount = campaign.recipients.filter((r) => r.status === 'failed')
                    .length;
                  const totalRecipients = campaign.recipients.length;
                  const progressPct =
                    totalRecipients === 0
                      ? 0
                      : Math.round(((successCount + failedCount) / totalRecipients) * 100);

                  // Rename 'pending' -> 'Queueing' for display
                  const displayStatus =
                    campaign.status === 'pending' ? 'Queueing' : campaign.status;

                  return (
                    <tr key={campaign._id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedCampaigns.includes(campaign._id)}
                          onChange={() => toggleCampaignSelection(campaign._id)}
                        />
                      </td>
                      <td>{campaign.name}</td>
                      <td>
                        {campaign.targetType === 'group' && campaign.targetGroup}
                        {campaign.targetType === 'label' && 'Contact Label(s)'}
                      </td>
                      <td>
                        {campaign.schedule?.startDate
                          ? moment(campaign.schedule.startDate).format('DD/MM/YYYY')
                          : '-'}
                      </td>
                      <td>
                        {campaign.schedule?.timeSlots?.join(', ') || '-'}
                      </td>
                      <td>{campaign.scheduleType || '-'}</td>
                      <td>
                        {campaign.filterStartDate && campaign.filterEndDate
                          ? `${moment(campaign.filterStartDate).format('DD/MM/YYYY')} - ${moment(
                              campaign.filterEndDate
                            ).format('DD/MM/YYYY')}`
                          : '-'}
                      </td>
                      <td>{campaign.seeding || '-'}</td>
                      <td className={`status ${displayStatus}`}>
                        {displayStatus === 'Queueing' && <FaHourglassHalf />}
                        {displayStatus === 'active' && <FaSpinner className="spin-icon" />}
                        {displayStatus === 'completed' && <FaCheckCircle />}
                        {displayStatus === 'paused' && <FaPauseCircle />}
                        &nbsp;{displayStatus}
                      </td>
                      <td>
                        {successCount} Sent / {failedCount} Failed
                        <br />
                        <small>{progressPct}%</small>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Broadcast;
