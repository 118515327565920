// src/components/SalesOverview.js
import React, { useState, useEffect } from 'react';
import './SalesTab.css';
import { Doughnut, Line } from 'react-chartjs-2';
import Pagination from './Pagination';
import { FaSearch, FaCalendarDay, FaCalendarWeek, FaCalendarAlt, FaCalendar } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

function SalesOverview(props) {
  const {
    salesData,
    filteredData,
    groupedData,
    grandTotal,
    loading,
    error,
    selectedFilter,
    dateRange,
    startDate,
    endDate,
    searchQuery,
    setSelectedFilter,
    setDateRange,
    setSearchQuery,
    spaName,
    spaAddress,
    spaPhone,
    spaLogoUrl,
    showSpaModal,
    setShowSpaModal,
    pendingSaleForPDF,
    setPendingSaleForPDF,
    isEditingSpaInfo,
    setIsEditingSpaInfo,
    monthlyTarget,
    showTargetModal,
    setShowTargetModal,
    isEditingTarget,
    setIsEditingTarget,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    flattenedData,
    totalPages,
    startIndex,
    currentPageData,
    onPageChange,
    donutData,
    donutOptions,
    lineData,
    lineOptions,
    monthlyTargetVal,
    progressPercent,
    handleSaveSpaInfo,
    handleSaveTarget,
    downloadReceipt,
    openEditSpaInfo,
    openTargetModal,
    getBase64FromUrl,
    applyDateRangeFilter,
    applyPredefinedFilter,
    applySearchFilter,
    therapistFilter,           // <-- therapist filter value
    applyTherapistFilter       // <-- function from parent
  } = props;

  // Local state for react-select
  const [selectedTherapist, setSelectedTherapist] = useState(null);

  // Dapatkan senarai unique therapist dari salesData
  const therapistOptions = Array.from(
    new Set(salesData.flatMap(sale =>
      sale.therapists.map(t => t.therapist?.name).filter(Boolean)
    ))
  ).map(name => ({ value: name, label: name }));

  // Bila user pilih di dropdown
  const handleTherapistChange = (therapist) => {
    setSelectedTherapist(therapist);
    // call parent function
    applyTherapistFilter(therapist ? therapist.value : null);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilter, selectedTherapist, searchQuery, dateRange, setCurrentPage]);

  // --- Kira ringkasan rawatan therapist terpilih ---
  const [treatmentSummary, setTreatmentSummary] = useState({});

  useEffect(() => {
    if (!therapistFilter) {
      // jika tak pilih therapist, reset summary
      setTreatmentSummary({});
      return;
    }
    // Kumpul summary dari filteredData
    // filteredData ni dah pun suci (hanya data therapistFilter)
    const tmpSummary = {};
    filteredData.forEach(sale => {
      sale.therapists.forEach(td => {
        if (td?.therapist?.name === therapistFilter) {
          const tName = td?.treatment?.name || 'Tidak Diketahui';
          if (!tmpSummary[tName]) tmpSummary[tName] = 0;
          tmpSummary[tName] += 1;
        }
      });
    });
    setTreatmentSummary(tmpSummary);
  }, [therapistFilter, filteredData]);

  return (
    <div className="sales-tab">
      {(spaName || spaAddress || spaPhone) && (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className="sales-tab-filter-button" onClick={openEditSpaInfo}>
            Edit Spa Info
          </button>
        </div>
      )}

      {/* Filters */}
      <div className="sales-tab-filters">
        <div className="sales-tab-filter-group">
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'All' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('All')}
          >
            <FaCalendar style={{ marginRight: 5 }} />
            All
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'Today' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('Today')}
          >
            <FaCalendarDay style={{ marginRight: 5 }} />
            Today
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Week' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Week')}
          >
            <FaCalendarWeek style={{ marginRight: 5 }} />
            This Week
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Month' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Month')}
          >
            <FaCalendarAlt style={{ marginRight: 5 }} />
            This Month
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Year' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Year')}
          >
            <FaCalendar style={{ marginRight: 5 }} />
            This Year
          </button>
        </div>

        <div className="sales-tab-filter-group">
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
              if (update[0] && update[1]) {
                applyDateRangeFilter(update[0], update[1]);
              }
            }}
            isClearable
            placeholderText="Select Date Range"
            className="sales-tab-date-picker"
          />
        </div>

        <div className="sales-tab-filter-group sales-tab-search-group">
          <FaSearch className="sales-tab-search-icon" />
          <input
            type="text"
            placeholder="Cari Nama Client / Rawatan..."
            value={searchQuery}
            onChange={(e) => applySearchFilter(e.target.value)}
            className="sales-tab-search-input"
          />
        </div>

        {/* Filter by Therapist */}
        <div className="sales-tab-filter-group">
          <Select
            options={therapistOptions}
            isClearable
            placeholder="Filter by Therapist"
            value={selectedTherapist}
            onChange={handleTherapistChange}
            className="sales-tab-select"
          />
        </div>
      </div>

      {/* Papar ringkasan rawatan jika therapistFilter wujud */}
      {therapistFilter && (
        <div style={{
          background: '#FFF8E1',
          padding: '15px',
          marginBottom: '20px',
          borderRadius: '8px'
        }}>
          <h3 style={{ marginBottom: '10px' }}>
            Ringkasan Rawatan untuk Therapist: {therapistFilter}
          </h3>
          {Object.keys(treatmentSummary).length === 0 ? (
            <p style={{ fontStyle: 'italic' }}>Tiada rawatan direkodkan.</p>
          ) : (
            <ul>
              {Object.entries(treatmentSummary).map(([tName, count]) => (
                <li key={tName}>
                  <strong>{tName}</strong>: {count} order
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Graph Section */}
      <div className="sales-tab-graphs">
        <div className="sales-tab-graph-card" style={{ height: '400px' }}>
          <h4>Total Sales by Therapist</h4>
          <div style={{ height: '100%', position: 'relative' }}>
            {donutData.labels.length > 0 ? (
              <Doughnut
                data={donutData}
                options={{
                  ...donutOptions,
                  maintainAspectRatio: false,
                  plugins: {
                    ...donutOptions.plugins,
                    tooltip: {
                      callbacks: {
                        label: (ctx) => `${ctx.label}: RM ${ctx.parsed}`
                      }
                    }
                  }
                }}
              />
            ) : (
              <p className="sales-tab-no-data">No data</p>
            )}
          </div>
        </div>

        <div className="sales-tab-graph-card" style={{ flex: '1 1 300px', height: '400px' }}>
          <h4>Daily Sales Trend</h4>
          <div style={{ height: '100%', position: 'relative' }}>
            {lineData.labels.length > 0 ? (
              <Line
                data={lineData}
                options={{
                  ...lineOptions,
                  maintainAspectRatio: false
                }}
              />
            ) : (
              <p className="sales-tab-no-data">No data</p>
            )}
          </div>
        </div>

        <div className="sales-tab-graph-card">
          <h4>Monthly Target Progress</h4>
          {monthlyTargetVal === 0 ? (
            <div>
              <p>Set your monthly sales target:</p>
              <button onClick={openTargetModal} className="sales-tab-filter-button">
                Set Target
              </button>
            </div>
          ) : (
            <>
              <p>
                Target: <strong>RM {monthlyTargetVal.toFixed(2)}</strong>
              </p>
              <div className="sales-tab-progress-bar">
                <div
                  className="sales-tab-progress-fill"
                  style={{ width: `${progressPercent}%` }}
                ></div>
              </div>
              <p>{progressPercent.toFixed(2)}% achieved</p>
              <button onClick={openTargetModal} className="sales-tab-filter-button">
                Edit Target
              </button>
            </>
          )}
        </div>
      </div>

      {/* Data Table Section */}
      {groupedData && Object.keys(groupedData).length === 0 ? (
        <div className="sales-tab-no-data">Tiada data jualan untuk dipaparkan.</div>
      ) : (
        <>
          {currentPageData.map((item, idx) => {
            const { therapistName, dateString, dailyData } = item;
            return (
              <div key={therapistName + dateString + idx} className="sales-tab-therapist-section">
                <h3 className="sales-tab-therapist-name">{therapistName}</h3>
                <div className="sales-tab-therapist-data">
                  <h4>Tarikh: {dateString}</h4>
                  <table className="sales-tab-table">
                    <thead>
                      <tr>
                        <th>Tempahan</th>
                        <th>Nama Client</th>
                        <th>Telefon Client</th>
                        <th>Tarikh Booking</th>
                        <th>Slot Masa</th>
                        <th>Tarikh Slot</th>
                        <th>Nama Rawatan</th>
                        <th>Jumlah (RM)</th>
                        <th>Resit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyData.sales.map((sale, i) => (
                        <tr key={i}>
                          <td data-label="Tempahan">{sale.bookingId}</td>
                          <td data-label="Nama Client">{sale.clientName}</td>
                          <td data-label="Telefon Client">{sale.clientPhone}</td>
                          <td data-label="Tarikh Booking">{sale.bookingDate}</td>
                          <td data-label="Slot Masa">{sale.slotTime}</td>
                          <td data-label="Tarikh Slot">{sale.slotDate}</td>
                          <td data-label="Nama Rawatan">{sale.treatmentName}</td>
                          <td data-label="Jumlah">RM {sale.salesAmount.toFixed(2)}</td>
                          <td data-label="Resit">
                            <button onClick={() => downloadReceipt(sale)}>
                              Download PDF
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr className="sales-tab-total-row">
                        <td colSpan="9">Jumlah Hari Ini: RM {dailyData.total.toFixed(2)}</td>
                      </tr>
                      {Object.keys(dailyData.byTreatment).map((tName, i2) => {
                        const tData = dailyData.byTreatment[tName];
                        return (
                          <tr key={i2} className="sales-tab-treatment-row">
                            <td colSpan="9">
                              {tName}: {tData.count} order(s) | RM {tData.amount.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </>
      )}

      {/* Grand Total */}
      {grandTotal > 0 && (
        <div className="sales-tab-grand-total">
          <table className="sales-tab-grand-total-table">
            <tbody>
              <tr className="sales-tab-grand-total-row">
                <td>Jumlah Keseluruhan</td>
                <td>RM {grandTotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SalesOverview;
