// TherapistDashboard.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TherapistDashboard.css';
import EditProfileForm from './EditProfileForm';
import ChangePassword from './ChangePassword';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvailableSlot from './AvailableSlot';
import MyBookings from './MyBookings';
import {
  FaCalendarAlt,
  FaCogs,
  FaBook,
  FaUser,
  FaLock,
  FaBars,
  FaEdit,
  FaCalendarCheck,
  FaDollarSign,
  FaGlobe,
} from 'react-icons/fa';
import GoogleCalendarIntegration from './GoogleCalendarIntegration';
import config from '../config';
import CRMDashboard from './CRMDashboard';
import SubscriptionInfo from './SubscriptionInfo';
import WebhookTab from './WebhookTab';
import SalesTab from './SalesTab';
import UpgradePlanModal from './UpgradePlanModal';
import ChatBotAI from './ChatBotAI';

// Komponen untuk public page setup
import MyPublicPageSetup from './MyPublicPageSetup';

function TherapistDashboard() {
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const token = localStorage.getItem('token');

  // Fungsi bantu: proses location
  const processLocation = (location) => {
    if (typeof location === 'object') {
      const processedLocation = `${location.state || ''}, ${location.city || ''}`.trim();
      return processedLocation === ',' ? 'Not specified' : processedLocation;
    }
    return location || 'Not specified';
  };

  // Fetch therapist
  const fetchTherapist = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/therapist-profile`, {
        headers: { 'x-auth-token': token },
      });
      const therapistData = response.data;

      if (therapistData.location) {
        therapistData.location = processLocation(therapistData.location);
      }
      setTherapist(therapistData);
    } catch (error) {
      console.error('Error fetching therapist profile:', error);
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchTherapist();
  }, [fetchTherapist]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    navigate('/login');
  };

  // Update profile
  const handleUpdateProfile = async (updatedProfile) => {
    setIsUpdating(true);
    try {
      const response = await axios.put(
        `${config.API_URL}/update-therapist-profile`,
        updatedProfile,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data && response.data._id) {
        // Proses location semula kalau ada
        if (response.data.location) {
          response.data.location = processLocation(response.data.location);
        }
        setTherapist(response.data);
        toast.success('Profil berjaya dikemas kini!');
        setActiveTab('profile');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Gagal mengemas kini profil. Sila cuba lagi.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordChanged = () => {
    toast.success('Kata laluan berjaya ditukar!');
    setActiveTab('profile');
  };

  if (!therapist) {
    return <div>Loading...</div>;
  }

  // Definisi akses tab berdasarkan subscriptionPlan
  // Tambah "publicPage" supaya therapist boleh setup tagline dsb.
  const tabAccess = {
    plus: [
      'publicPage',
      'availableSlot',
      'crm',
      'webhook',
      'myBookings',
      'sales',
      'chatbotAI',
      'profile',
      'changePassword',
      'integrateGoogleCalendar',
    ],
    free_trial: [
      'publicPage',
      'availableSlot',
      'myBookings',
      'profile',
      'changePassword',
    ],
    basic: [
      'publicPage',
      'availableSlot',
      'myBookings',
      'profile',
      'changePassword',
    ],
  };

  const availableTabs = tabAccess[therapist.subscriptionPlan] || [];

  // Semua tab
  const allTabs = [
    {
      key: 'publicPage', 
      label: 'Dashboard', 
      icon: <FaGlobe />,
    },
    {
      key: 'availableSlot',
      label: 'Available Slots',
      icon: <FaCalendarAlt />,
    },
    {
      key: 'myBookings',
      label: 'My Bookings',
      icon: <FaBook />,
    },
    {
      key: 'crm',
      label: 'CRM',
      icon: <FaCogs />,
    },
    {
      key: 'webhook',
      label: 'Webhook',
      icon: <FaCogs />,
    },
    {
      key: 'sales',
      label: 'Sales',
      icon: <FaDollarSign />,
    },
    {
      key: 'chatbotAI',
      label: 'ChatBotAI',
      icon: <FaCogs />,
    },
    {
      key: 'profile',
      label: 'Profile',
      icon: <FaUser />,
    },
    {
      key: 'changePassword',
      label: 'Change Password',
      icon: <FaLock />,
    },
    {
      key: 'integrateGoogleCalendar',
      label: 'Integrate Google Calendar',
      icon: <FaCalendarCheck />,
    },
  ];

  // Bila klik tab
  const handleTabClick = (tabKey) => {
    if (availableTabs.includes(tabKey)) {
      setActiveTab(tabKey);
      setIsMobileMenuOpen(false);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowUpgradeModal(false);
  };

  return (
    <div className="therapist-dashboard">
      {/* ============= Main Navigation Bar ============= */}
      <div className="main-nav-bar">
        <div className="nav-left desktop-only">
          {/* Logo therapist */}
          <div className="nav-logo">
            <img
              src={therapist.image || '/images/user.png'}
              alt="Therapist Logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/user.png';
              }}
            />
          </div>
          {/* Nav links */}
          <div className="nav-links">
            <button
              className="nav-link"
              onClick={() => setActiveTab('publicPage')}
            >
              Dashboard
            </button>
            <button
              className="nav-link"
              onClick={() => setActiveTab('myBookings')}
            >
              Bookings
            </button>
            <button
              className="nav-link"
              onClick={() => setActiveTab('profile')}
            >
              Profile
            </button>
            <button className="nav-link sign-out-link" onClick={handleLogout}>
              Sign Out
            </button>
          </div>
        </div>

        {/* Subscription info di atas (kanan) */}
        <div className="subscription-container desktop-only">
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
        </div>

        {/* Mobile Menu Button */}
        <div className="mobile-menu-button mobile-only">
          <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <FaBars />
          </button>
        </div>
      </div>

      {/* ============= Mobile Nav Dropdown ============= */}
      {isMobileMenuOpen && (
        <div className="mobile-nav-links mobile-only">
          <div className="nav-logo mobile-nav-logo">
            <img
              src={therapist.image || '/images/user.png'}
              alt="Therapist Logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/user.png';
              }}
            />
          </div>
          <button
            className="nav-link"
            onClick={() => {
              setActiveTab('publicPage');
              setIsMobileMenuOpen(false);
            }}
          >
            Dashboard
          </button>
          <button
            className="nav-link"
            onClick={() => {
              setActiveTab('myBookings');
              setIsMobileMenuOpen(false);
            }}
          >
            Bookings
          </button>
          <button
            className="nav-link"
            onClick={() => {
              setActiveTab('profile');
              setIsMobileMenuOpen(false);
            }}
          >
            Profile
          </button>
          <button className="nav-link sign-out-link" onClick={handleLogout}>
            Sign Out
          </button>

          {/* Subscription Info untuk Mobile */}
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
        </div>
      )}

      {/* ============= Welcome Section ============= */}
      <div className="welcome-section">
        <h1>Welcome, {therapist.name}</h1>
        <p>
          <em>Your journey to wellness starts here.</em>
        </p>
      </div>

      {/* ============= Secondary Navigation Tabs ============= */}
      <div className="secondary-nav-bar">
        <div className="tab-list">
          {allTabs.map(({ key, label, icon }) => {
            const isActive = activeTab === key;
            const isAccessible = availableTabs.includes(key);
            return (
              <button
                key={key}
                className={`secondary-tab ${isActive ? 'active' : ''} ${
                  !isAccessible ? 'disabled' : ''
                }`}
                onClick={() => handleTabClick(key)}
                title={
                  isAccessible ? label : 'Tab ini tidak tersedia pada plan anda'
                }
              >
                {icon} {label}
              </button>
            );
          })}
        </div>
      </div>

      {/* ============= Main Content Section ============= */}
      <div className="main-content-section">
        {/* TAB: My Public Page (Dashboard) */}
        {activeTab === 'publicPage' && (
          <MyPublicPageSetup therapist={therapist} />
        )}

        {/* TAB: Profile */}
        {activeTab === 'profile' && (
          <div className="profile-card">
            <div className="profile-header">
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />
              <h2>{therapist.name.toUpperCase()}</h2>
            </div>
            <div className="profile-details">
              <div className="detail-item">
                <span className="detail-label">Phone:</span>
                <span className="detail-value">{therapist.phone}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Email:</span>
                <span className="detail-value">{therapist.email}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Location:</span>
                <span className="detail-value">
                  {therapist.location || 'Not specified'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Perkhidmatan:</span>
                <span className="detail-value">
                  {therapist.inCall && therapist.outCall
                    ? 'In-Call & Out-Call'
                    : therapist.inCall
                    ? 'In-Call'
                    : therapist.outCall
                    ? 'Out-Call'
                    : 'Tidak dinyatakan'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">
                  Jenis rawatan yang disediakan untuk:
                </span>
                <span className="detail-value">
                  {therapist.jenisRawatan?.lelaki &&
                  therapist.jenisRawatan?.wanita
                    ? 'Lelaki & Wanita'
                    : therapist.jenisRawatan?.lelaki
                    ? 'Lelaki'
                    : 'Wanita'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Rawatan:</span>
                <div className="treatment-types">
                  {therapist.jenisRawatan?.rawatanLain.map((rawatan, index) => (
                    <span key={index} className="treatment-tag">
                      {rawatan}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="profile-actions">
              <button
                onClick={() => setActiveTab('editProfile')}
                className="edit-profile-button"
              >
                <FaEdit /> Edit Profile
              </button>
            </div>
          </div>
        )}

        {/* TAB: Available Slot */}
        {activeTab === 'availableSlot' && (
          <AvailableSlot therapist={therapist} />
        )}

        {/* TAB: Webhook */}
        {activeTab === 'webhook' && <WebhookTab />}

        {/* TAB: My Bookings */}
        {activeTab === 'myBookings' && (
          <MyBookings therapist={therapist} />
        )}

        {/* TAB: ChatBot AI */}
        {activeTab === 'chatbotAI' && (
          <ChatBotAI therapist={therapist} token={token} />
        )}

        {/* TAB: Edit Profile Form */}
        {activeTab === 'editProfile' && (
          <EditProfileForm
            therapist={therapist}
            onUpdate={handleUpdateProfile}
            onCancel={() => setActiveTab('profile')}
          />
        )}

        {/* TAB: Change Password */}
        {activeTab === 'changePassword' && (
          <ChangePassword
            therapistId={therapist._id}
            onPasswordChanged={handlePasswordChanged}
          />
        )}

        {/* TAB: Integrate Google Calendar */}
        {activeTab === 'integrateGoogleCalendar' && (
          <GoogleCalendarIntegration
            token={token}
            therapistId={therapist._id}
          />
        )}

        {/* TAB: CRM */}
        {activeTab === 'crm' && <CRMDashboard />}

        {/* TAB: Sales */}
        {activeTab === 'sales' && (
          <SalesTab therapistId={therapist._id} token={token} />
        )}

        {isUpdating && <div className="loader">Updating profile...</div>}
      </div>

      {/* Overlay Notifikasi untuk Upgrade Plan */}
      {showUpgradeModal && (
        <UpgradePlanModal onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default TherapistDashboard;
