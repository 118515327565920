import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
// Kita boleh re-use logic booking form
import BookingForm from './BookingForm'; 
// misalnya kita letak BookingForm di folder "booking"
import './UserBookingTab.css';

function UserBookingTab() {
  const [therapist, setTherapist] = useState(null);
  // rawatan-rawatan
  const [treatments, setTreatments] = useState([]);
  // controlling booking form popup
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState('');
  // voucher
  const [hasVoucher, setHasVoucher] = useState(true);

  useEffect(() => {
    // Misalnya therapist ID kita simpan dalam user object
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (user.therapist) {
      fetchTherapist(user.therapist);
    }
  }, []);

  async function fetchTherapist(therapistId) {
    try {
      // panggil endpoint dapat info therapist by ID
      // atau if your endpoint is /therapist/ID:
      const res = await axios.get(`${config.API_URL}/therapist/${therapistId}`);
      setTherapist(res.data);
      setTreatments(res.data?.jenisRawatan?.rawatanLain || []);
    } catch (error) {
      console.error('Error fetching therapist:', error);
    }
  }

  function handleBookNow(treatment) {
    setSelectedTreatment(treatment);
    setShowBookingForm(true);
  }

  // callback after booking done / or close
  function handleBookingClose() {
    setShowBookingForm(false);
    setSelectedTreatment('');
  }

  // Contoh "use voucher"
  function handleUseVoucher() {
    // Sini logik: set hasVoucher = false
    // Boleh store in DB pun, bergantung
    setHasVoucher(false);
    alert('You have applied the 15% voucher for your next booking!');
  }

  return (
    <div className="user-booking-tab-container">
      <h2>Available Treatments</h2>
      {treatments.length === 0 ? (
        <p>No treatments available.</p>
      ) : (
        <div className="treatment-cards">
          {treatments.map((treatment, index) => (
            <div key={index} className="treatment-card">
              <h3>{treatment}</h3>
              <p>
                Lorem ipsum detail rawatan, boleh letak ringkasan
                atau harga, dsb.
              </p>
              {/* VOUCHER BUTTON */}
              {hasVoucher && (
                <button
                  className="voucher-btn"
                  onClick={handleUseVoucher}
                >
                  Use 15% Voucher
                </button>
              )}

              <button
                className="book-now-btn"
                onClick={() => handleBookNow(treatment)}
              >
                Book Now
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Tunjuk form booking (boleh re-use BookingForm) */}
      {showBookingForm && (
        <div className="booking-modal">
          <div className="booking-modal-content">
            {/* Boleh pass prop "applyVoucher" = !hasVoucher ? false : true */}
            <BookingForm
              selectedTherapist={therapist}
              // Kita nak pass rawatan yang dah dipilih
              // Mungkin kena modify BookingForm untuk terima "forcedTreatment"
              onClose={handleBookingClose}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserBookingTab;
