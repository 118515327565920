// src/pages/MyBookings.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MyBookings.css';
import { format, parseISO, isSameDay } from 'date-fns';
import { toast } from 'react-toastify';
import 'chart.js/auto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCar, 
  faCheckCircle, 
  faTrash, 
  faEye, 
  faStethoscope,
  faSpinner,
  faCalendarAlt,
  faFilter
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import config from '../config';
import DiagnosForm from './DiagnosForm';
import Modal from 'react-modal';
import SalesForm from './SalesForm'; 
import SalesReport from './SalesReport'; 
import { Bar, Line } from 'react-chartjs-2';
import Spinner from '../components/Spinner'; // Import Spinner

Modal.setAppElement('#root'); 

function MyBookings({ therapist }) {
  const [bookings, setBookings] = useState([]);
  const [groupedBookings, setGroupedBookings] = useState({});
  const [todayBookingsCount, setTodayBookingsCount] = useState(0);
  const [isDiagnosModalOpen, setIsDiagnosModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSalesData, setCurrentSalesData] = useState(null);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  
  const [isSalesReportModalOpen, setIsSalesReportModalOpen] = useState(false);
  const [currentSalesReportBooking, setCurrentSalesReportBooking] = useState(null);

  // New State Variables for Filters
  const [timeFilter, setTimeFilter] = useState('All'); // 'All', 'Today', 'Week', 'Month', 'Year'
  const [statusFilter, setStatusFilter] = useState('All'); // 'All', 'Complete', 'Progress', 'Cancelled'

  // State chart summary
  const [monthlyBookingsData, setMonthlyBookingsData] = useState([]);
  const [peakDayData, setPeakDayData] = useState({ labels: [], data: [] });
  const [peakHourData, setPeakHourData] = useState({ labels: [], data: [] });
  const [totalBookingsCount, setTotalBookingsCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);

  // New State Variables for Send Reminders
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [reminderMessageTemplate, setReminderMessageTemplate] = useState(`Hai {userName}, ini adalah peringatan dari Matin Therapy.\n\nAnda mempunyai janji temu hari ini untuk rawatan {treatment} pada pukul {startTime}.\n\nJika anda menghadapi sebarang masalah untuk hadir, sila maklumkan kepada kami segera.\n\nTerima kasih atas kepercayaan anda. Semoga hari anda menyenangkan!\n\nIni adalah mesej automatik dari sistem kami.`);
  const [currentReminderIndex, setCurrentReminderIndex] = useState(0);
  const [totalReminders, setTotalReminders] = useState(0);
  const [isSendingReminders, setIsSendingReminders] = useState(false);

  // Helper Functions for Time Filters
  const isWithinToday = (date) => isSameDay(new Date(), parseISO(date));

  const isWithinWeek = (date) => {
    const now = new Date();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay()); // Sunday
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Saturday
    const bookingDate = parseISO(date);
    return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
  };

  const isWithinMonth = (date) => {
    const now = new Date();
    return (
      parseISO(date).getMonth() === now.getMonth() &&
      parseISO(date).getFullYear() === now.getFullYear()
    );
  };

  const isWithinYear = (date) => {
    const now = new Date();
    return parseISO(date).getFullYear() === now.getFullYear();
  };

  // On mount, if therapist available, load from sessionStorage or fetch
  useEffect(() => {
    if (therapist) {
      const storedData = sessionStorage.getItem('cachedBookings');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setBookings(parsedData);
      } else {
        fetchBookings();
      }
    }
  }, [therapist]);

  useEffect(() => {
    const grouped = groupBookingsByDate(bookings, selectedDate, timeFilter, statusFilter);
    setGroupedBookings(grouped);
    setTodayBookingsCount(getTodayBookingsCount(bookings));
  }, [bookings, selectedDate, timeFilter, statusFilter]);

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/therapist/bookings`, {
        headers: { 'x-auth-token': token },
      });

      let allBookings = response.data;
      allBookings.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));

      const bookingsWithDiagnos = await Promise.all(
        allBookings.map(async (booking) => {
          try {
            const diagnosResponse = await axios.get(`${config.API_URL}/diagnos/${booking._id}`, {
              headers: { 'x-auth-token': token },
            });
            booking.diagnos = diagnosResponse.data;
          } catch (error) {
            if (error.response && error.response.status === 404) {
              booking.diagnos = [];
            } else {
              console.error('Error fetching diagnos for booking:', error);
            }
          }
          return booking;
        })
      );

      setBookings(bookingsWithDiagnos);
      // Save to sessionStorage
      sessionStorage.setItem('cachedBookings', JSON.stringify(bookingsWithDiagnos));
    } catch (error) {
      console.error('Error fetching bookings:', error);
      toast.error('Gagal mendapatkan tempahan. Sila cuba lagi.');
    } finally {
      setIsLoading(false);
    }
  };

  const groupBookingsByDate = (bookings, dateFilter, timeFilter, statusFilter) => {
    let filteredBookings = bookings;

    // Apply Time Filter
    if (timeFilter !== 'All') {
      filteredBookings = filteredBookings.filter(booking => {
        const bookingDate = booking.bookingDate;
        switch (timeFilter) {
          case 'Today':
            return isWithinToday(bookingDate);
          case 'Week':
            return isWithinWeek(bookingDate);
          case 'Month':
            return isWithinMonth(bookingDate);
          case 'Year':
            return isWithinYear(bookingDate);
          default:
            return true;
        }
      });
    }

    // Apply Status Filter
    if (statusFilter !== 'All') {
      filteredBookings = filteredBookings.filter(booking => booking.status === statusFilter);
    }

    // Apply Date Filter (from existing logic)
    if (dateFilter) {
      const selected = parseISO(selectedDate);
      filteredBookings = filteredBookings.filter(booking =>
        isSameDay(parseISO(booking.bookingDate), selected)
      );
    }

    const grouped = filteredBookings.reduce((acc, booking) => {
      const date = formatDate(booking.bookingDate);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(booking);
      return acc;
    }, {});

    Object.keys(grouped).forEach(date => {
      grouped[date].sort((a, b) => {
        if (a.slotId && b.slotId) {
          return a.slotId.startTime.localeCompare(b.slotId.startTime);
        }
        return 0;
      });
    });
    return grouped;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filteredGroupedBookings = Object.keys(groupedBookings).reduce((acc, date) => {
    const filteredBookings = groupedBookings[date].filter(booking =>
      booking.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      booking.userPhone.includes(searchTerm)
    );
    if (filteredBookings.length > 0) {
      acc[date] = filteredBookings;
    }
    return acc;
  }, {});

  const handleMarkAsComplete = (booking) => {
    setCurrentBooking(booking);
    setIsSalesModalOpen(true);
  };

  const handlePaparkanSales = (booking) => {
    setCurrentSalesReportBooking(booking);
    setIsSalesReportModalOpen(true);
  };

  const updateBookingStatus = async (bookingId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${config.API_URL}/therapist/bookings/${bookingId}`,
        { status: 'Complete' },
        {
          headers: { 'x-auth-token': token },
        }
      );
      toast.success('Tempahan ditandakan sebagai selesai.');
      const updatedBookings = bookings.map((booking) =>
        booking._id === bookingId ? { ...booking, status: 'Complete' } : booking
      );
      setBookings(updatedBookings);
      sessionStorage.setItem('cachedBookings', JSON.stringify(updatedBookings));
    } catch (error) {
      console.error('Error updating booking status:', error);
      toast.error('Gagal mengemas kini status tempahan. Sila cuba lagi.');
    }
  };

  const getTodayBookingsCount = (bookings) => {
    const today = new Date();
    return bookings.filter((booking) =>
      isSameDay(parseISO(booking.bookingDate), today)
    ).length;
  };

  const handleDeleteBooking = async (bookingId) => {
    const confirmation = window.confirm(
      'Adakah anda pasti mahu memadam tempahan ini?'
    );
    if (confirmation) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${config.API_URL}/bookings/${bookingId}`, {
          headers: { 'x-auth-token': token },
        });
        const updatedBookings = bookings.filter(booking => booking._id !== bookingId);
        setBookings(updatedBookings);
        sessionStorage.setItem('cachedBookings', JSON.stringify(updatedBookings));
        toast.success('Tempahan berjaya dipadam.');
      } catch (error) {
        console.error(
          'Error deleting booking:',
          error.response ? error.response.data : error
        );
        toast.error('Gagal memadam tempahan. Sila cuba lagi.');
      }
    }
  };

  const openDiagnosModal = (booking, isEdit = false) => {
    setSelectedBooking(booking);
    setIsEditMode(isEdit);
    setIsDiagnosModalOpen(true);
  };

  const closeDiagnosModal = () => {
    setIsDiagnosModalOpen(false);
    setSelectedBooking(null);
    setIsEditMode(false);
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return format(parseISO(date), 'dd/MM/yyyy');
  };

  const handleSendWhatsApp = async (booking) => {
    const confirmation = window.confirm(
      `Adakah anda pasti mahu menghantar diagnos kepada ${booking.userName} melalui WhatsApp?`
    );
    if (!confirmation) return;

    if (!booking.diagnos || booking.diagnos.length === 0) {
      toast.error('Diagnos tidak tersedia untuk dihantar.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const messageTemplate = booking.diagnos.map((diagnos, idx) => `
Salam ${booking.userName} - Individu ${idx + 1},

Berikut adalah diagnos anda:

*Masalah Pelanggan:*
${diagnos.issueDescription.join(', ')}

*Cadangan Senaman/Rawatan:*
${diagnos.recommendations.join(', ')}

*Hasil Diagnos Selepas Rawatan:*
${diagnos.findings.join(', ')}

Terima kasih kerana booking dengan kami. Kami doakan ${booking.userName} kekal sihat. Semoga berjumpa lagi pada rawatan akan datang.
`.trim()).join('\n\n');

      const formData = new FormData();
      formData.append('therapistId', therapist._id);
      formData.append('testNumber', booking.userPhone);
      formData.append('message', messageTemplate);

      const response = await axios.post('/api/broadcast/test-send', formData, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Mesej WhatsApp berjaya dihantar.');
      } else {
        throw new Error(response.data.error || 'Gagal menghantar mesej WhatsApp.');
      }
    } catch (error) {
      console.error('WhatsApp send error:', error);
      toast.error(error.message || 'Gagal menghantar mesej WhatsApp.');
    }
  };

  const handleSalesSubmitted = async () => {
    if (currentBooking) {
      await updateBookingStatus(currentBooking._id);
      setCurrentBooking(null);
      setIsSalesModalOpen(false);
      // Refresh data kalau perlu, tapi kita dah update local
      // fetchBookings(); // Tak perlu kalau data local dah update
    }
  };

  // Fungsi untuk membuka modal Send Reminder dan mengira jumlah reminders
  const openReminderModal = () => {
    const today = new Date();
    const todaysBookings = bookings.filter(booking => isSameDay(parseISO(booking.bookingDate), today));
    const numReminders = todaysBookings.length;
    setTotalReminders(numReminders);
    setIsReminderModalOpen(true);
  };

  // Fungsi untuk menghantar peringatan
  const startSendingReminders = async () => {
    const today = new Date();

    // Filter tempahan hari ini
    const todaysBookings = bookings.filter(booking => isSameDay(parseISO(booking.bookingDate), today));

    if (todaysBookings.length === 0) {
      toast.info('Tiada tempahan hari ini untuk dihantar peringatannya.');
      setIsReminderModalOpen(false);
      return;
    }

    setIsSendingReminders(true);
    setTotalReminders(todaysBookings.length);
    setCurrentReminderIndex(0);

    for (let i = 0; i < todaysBookings.length; i++) {
      const booking = todaysBookings[i];
      const message = reminderMessageTemplate
        .replace('{userName}', booking.userName)
        .replace('{treatment}', booking.treatment)
        .replace('{startTime}', booking.slotId ? booking.slotId.startTime : 'N/A');

      try {
        await sendWhatsAppMessage(therapist._id, booking.userPhone, message);
        toast.success(`Peringatan dihantar kepada ${booking.userName}`);
      } catch (error) {
        console.error(`Gagal menghantar peringatan kepada ${booking.userName}:`, error);
        toast.error(`Gagal menghantar peringatan kepada ${booking.userName}`);
      }

      setCurrentReminderIndex(i + 1);

      if (i < todaysBookings.length - 1) {
        await new Promise(resolve => setTimeout(resolve, 60000)); // 60 saat
      }
    }

    setIsSendingReminders(false);
    setIsReminderModalOpen(false);
    toast.success('Semua peringatan telah dihantar.');
  };

  // Fungsi untuk menghantar mesej WhatsApp
  const sendWhatsAppMessage = async (therapistId, phoneNumber, message) => {
    try {
      const response = await axios.post(`${config.API_URL}/api/reminder`, {
        therapistId: therapistId,
        phoneNumber: phoneNumber,
        message: message,
      }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Gagal menghantar mesej.');
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // Kira chart data
  useEffect(() => {
    if (bookings.length > 0) {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const thisMonthBookings = bookings.filter(booking => {
        const bd = parseISO(booking.bookingDate);
        return bd.getMonth() === currentMonth && bd.getFullYear() === currentYear;
      });

      setMonthlyCount(thisMonthBookings.length);
      setTotalBookingsCount(bookings.length);

      const dayCount = {};
      const hourCount = {};

      thisMonthBookings.forEach(booking => {
        const bd = parseISO(booking.bookingDate);
        const day = format(bd, 'dd/MM');
        dayCount[day] = (dayCount[day] || 0) + 1;

        if (booking.slotId && booking.slotId.startTime) {
          const hour = booking.slotId.startTime.split(':')[0];
          hourCount[hour] = (hourCount[hour] || 0) + 1;
        }
      });

      const sortedDays = Object.keys(dayCount).sort((a, b) => {
        const da = parseInt(a.split('/')[0], 10);
        const db = parseInt(b.split('/')[0], 10);
        return da - db;
      });
      const monthlyLabels = sortedDays;
      const monthlyValues = sortedDays.map(d => dayCount[d]);

      setMonthlyBookingsData({
        labels: monthlyLabels,
        data: monthlyValues
      });

      let maxCount = 0;
      let peakDays = [];
      for (const d in dayCount) {
        if (dayCount[d] > maxCount) {
          maxCount = dayCount[d];
          peakDays = [d];
        } else if (dayCount[d] === maxCount) {
          peakDays.push(d);
        }
      }
      setPeakDayData({
        labels: peakDays,
        data: peakDays.map(() => maxCount)
      });

      const sortedHours = Object.keys(hourCount).sort((a, b) => parseInt(a)-parseInt(b));
      const hourValues = sortedHours.map(h => hourCount[h]);
      setPeakHourData({
        labels: sortedHours,
        data: hourValues
      });
    }
  }, [bookings]);

  const handleEditSales = async (booking) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/sales/booking/${booking._id}`, {
        headers: { 'x-auth-token': token },
      });
      
      const salesData = response.data;
      // Simpan data ini untuk di pass ke SalesForm
      setCurrentSalesData(salesData);
      setCurrentBooking(booking);
      setIsSalesModalOpen(true);
    } catch (error) {
      console.error('Error fetching sales data for editing:', error);
      toast.error('Gagal mendapatkan data sales untuk edit.');
    }
  };

  return (
    <div className="my-bookings">
      <div className="dashboard-summary">
        <h2>MyBooking Dashboard</h2>
        <div className="stats-row">
          <div className="stat-card">
            <h3>Total Bookings</h3>
            <p>{totalBookingsCount}</p>
          </div>
          <div className="stat-card">
            <h3>Today Bookings</h3>
            <p>{todayBookingsCount}</p>
          </div>
          <div className="stat-card">
            <h3>This Month Bookings</h3>
            <p>{monthlyCount}</p>
          </div>
        </div>

        <div className="charts-container">
          {isLoading ? (
            <div className="spinner-container">
              <Spinner type="SyncLoader" size={10} color="#007bff" loading={isLoading} />
              <p>Sedang memuat data chart, sila tunggu...</p>
            </div>
          ) : (
            <>
              {/* Chart Monthly */}
              <div className="chart-box">
                <h4>Monthly Bookings Trend</h4>
                <p className="chart-subtitle">
                  Menunjukkan jumlah tempahan per hari untuk bulan semasa.
                </p>
                {monthlyBookingsData.labels && monthlyBookingsData.labels.length > 0 ? (
                  <Bar
                    data={{
                      labels: monthlyBookingsData.labels,
                      datasets: [
                        {
                          label: 'Jumlah Tempahan/Hari',
                          data: monthlyBookingsData.data,
                          backgroundColor: '#007bff',
                          borderColor: '#0056b3',
                          borderWidth: 1,
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `Jumlah: ${context.parsed.y}`;
                            }
                          }
                        }
                      },
                      scales: {
                        x: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { display: false }
                        },
                        y: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { color: '#e0e0e0' },
                          beginAtZero: true
                        }
                      }
                    }}
                  />
                ) : <p>Tiada data untuk bulan ini.</p>}
              </div>

              {/* Chart Peak Day */}
              <div className="chart-box">
                <h4>Hari Paling Banyak Booking (Bulan Ini)</h4>
                <p className="chart-subtitle">
                  Hari dengan jumlah tempahan tertinggi bulan ini.
                </p>
                {peakDayData.labels && peakDayData.labels.length > 0 ? (
                  <Bar
                    data={{
                      labels: peakDayData.labels,
                      datasets: [
                        {
                          label: 'Jumlah Tempahan',
                          data: peakDayData.data,
                          backgroundColor: '#28a745',
                          borderColor: '#1e7e34',
                          borderWidth: 1,
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `Jumlah: ${context.parsed.y}`;
                            }
                          }
                        }
                      },
                      scales: {
                        x: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { display: false }
                        },
                        y: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { color: '#e0e0e0' },
                          beginAtZero: true
                        }
                      }
                    }}
                  />
                ) : <p>Tiada data untuk peak day.</p>}
              </div>

              {/* Chart Peak Hour */}
              <div className="chart-box">
                <h4>Waktu Paling Popular (Bulan Ini)</h4>
                <p className="chart-subtitle">
                  Jam dengan jumlah tempahan paling banyak dalam hari.
                </p>
                {peakHourData.labels && peakHourData.labels.length > 0 ? (
                  <Line
                    data={{
                      labels: peakHourData.labels,
                      datasets: [
                        {
                          label: 'Tempahan Mengikut Jam',
                          data: peakHourData.data,
                          fill: false,
                          borderColor: '#dc3545',
                          backgroundColor: '#dc3545',
                          tension: 0.1
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: true,
                          labels: {
                            color: '#333',
                            font: { size: 12 }
                          }
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return `Jumlah: ${context.parsed.y}`;
                            }
                          }
                        }
                      },
                      scales: {
                        x: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { display: false }
                        },
                        y: { 
                          ticks: { color: '#333', padding: 10 },
                          grid: { color: '#e0e0e0' },
                          beginAtZero: true
                        }
                      }
                    }}
                  />
                ) : <p>Tiada data untuk waktu yang popular.</p>}
              </div>
            </>
          )}
        </div>
      </div>

      <h2 className="section-title">Tempahan Saya</h2>
      <div className="header-controls">
        <h3>Tempahan Hari Ini: {todayBookingsCount}</h3>
        <div className="filters">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="date-input"
            aria-label="Pilih Tarikh"
          />
          <input
            type="text"
            placeholder="Cari tempahan..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
            aria-label="Cari Tempahan"
          />
          {selectedDate && (
            <button
              className="clear-filter-button"
              onClick={() => setSelectedDate('')}
              title="Padam Penapis Tarikh"
              aria-label="Padam Penapis Tarikh"
            >
              <FontAwesomeIcon icon={faTrash} /> Clear Tarikh
            </button>
          )}
          {/* Butang Refresh Data */}
          <button
            className="refresh-data-button"
            onClick={fetchBookings}
            title="Refresh Data"
            aria-label="Refresh Data"
          >
            <FontAwesomeIcon icon={faEye} /> Refresh Data
          </button>
          {/* Butang Send Reminders */}
          <button
            className="send-reminders-button"
            onClick={openReminderModal} // Tukar kepada fungsi baru
            title="Hantar Peringatan"
            aria-label="Hantar Peringatan"
          >
            <FontAwesomeIcon icon={faCalendarAlt} /> Hantar Peringatan
          </button>
        </div>
      </div>
      
      {/* Add Filter Tabs Here */}
      <div className="filter-tabs">
        {/* Time Filters */}
        <div className="filter-group">
          <h4>Filter by Time</h4>
          <div className="filter-buttons">
            {['All', 'Today', 'Week', 'Month', 'Year'].map((filter) => (
              <button
                key={filter}
                className={`filter-button ${timeFilter === filter ? 'active' : ''}`}
                onClick={() => setTimeFilter(filter)}
                aria-label={`Filter by ${filter}`}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>

        {/* Status Filters */}
        <div className="filter-group">
          <h4>Filter by Status</h4>
          <div className="filter-buttons">
            {['All', 'Complete', 'Progress', 'Cancelled'].map((filter) => (
              <button
                key={filter}
                className={`filter-button ${statusFilter === filter ? 'active' : ''}`}
                onClick={() => setStatusFilter(filter)}
                aria-label={`Filter by ${filter}`}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="spinner-container">
          <Spinner type="RingLoader" size={60} color="#28a745" loading={isLoading} label="Memuat tempahan..." />
        </div>
      ) : Object.keys(filteredGroupedBookings).length > 0 ? (
        <div className="cards-container">
          {Object.keys(filteredGroupedBookings).map(date => (
            <div key={date} className="date-group">
              <h4>{date} - Jumlah Tempahan: {filteredGroupedBookings[date].length}</h4>
              <div className="cards-grid">
                {filteredGroupedBookings[date].map((booking) => (
                  <div key={booking._id} className="booking-card">
                    <div className="card-header">
                      <h5>{booking.treatment}</h5>
                      <span className={`status ${booking.status.toLowerCase()}`}>
                        {booking.status === 'Progress' && (
                          <FontAwesomeIcon icon={faSpinner} spin className="status-icon" />
                        )}
                        {booking.status}
                      </span>
                    </div>
                    <div className="card-body">
                      <p><strong>Tarikh:</strong> {formatDate(booking.bookingDate)}</p>
                      <p><strong>Masa:</strong> {booking.slotId ? `${booking.slotId.startTime} - ${booking.slotId.endTime}` : 'N/A'}</p>
                      <p><strong>Nama Klien:</strong> {booking.userName}</p>
                      <p><strong>Telefon:</strong> {booking.userPhone}</p>
                      <p>
                        <strong>Jenis Perkhidmatan:</strong> 
                        <span className={`service-type ${booking.serviceType}`}>
                          {booking.serviceType === 'incall' ? (
                            <>
                              <FontAwesomeIcon icon={faMapMarkerAlt} /> In-call
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faCar} /> Out-call
                            </>
                          )}
                        </span>
                      </p>
                      <p>
                        <strong>Lokasi:</strong> 
                        {booking.serviceType === 'incall' ? (
                          <span className="location-info">Di Pusat Terapi</span>
                        ) : (
                          <span
                            className="location-info outcall-address"
                            title={booking.outcallAddress}
                          >
                            {booking.outcallAddress}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="card-actions">
                      {booking.status === 'Progress' ? (
                        <>
                          {booking.diagnos && booking.diagnos.length > 0 ? (
                            <>
                              <button
                                className="view-diagnos-button"
                                onClick={() => openDiagnosModal(booking, false)}
                                title="Paparkan Diagnos"
                                aria-label="Paparkan Diagnos"
                              >
                                <FontAwesomeIcon icon={faEye} /> Paparkan Diagnos
                              </button>
                              <button
                                className="whatsapp-button"
                                onClick={() => handleSendWhatsApp(booking)}
                                title="Hantar Diagnos melalui WhatsApp"
                                aria-label="Hantar Diagnos melalui WhatsApp"
                              >
                                <FontAwesomeIcon icon={faWhatsapp} /> Hantar Laporan
                              </button>
                            </>
                          ) : (
                            <button
                              className="diagnos-button"
                              onClick={() => openDiagnosModal(booking, false)}
                              title="Tambah Diagnos"
                              aria-label="Tambah Diagnos"
                            >
                              <FontAwesomeIcon icon={faStethoscope} /> Diagnos
                            </button>
                          )}
                          <button
                            className="complete-button"
                            onClick={() => handleMarkAsComplete(booking)}
                            title="Tandakan Sebagai Selesai"
                            aria-label="Tandakan Sebagai Selesai"
                          >
                            <FontAwesomeIcon icon={faCheckCircle} /> Tandakan Selesai
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteBooking(booking._id)}
                            title="Padam Tempahan"
                            aria-label="Padam Tempahan"
                          >
                            <FontAwesomeIcon icon={faTrash} /> Padam
                          </button>
                        </>
                      ) : booking.status === 'Complete' ? (
                        <>
                          <button
                            className="paparkan-sales-button"
                            onClick={() => handlePaparkanSales(booking)}
                            title="Paparkan Sales"
                            aria-label="Paparkan Sales"
                          >
                            <FontAwesomeIcon icon={faEye} /> Paparkan Sales
                          </button>
                          {/* Butang Edit Sales */}
                          <button
                            className="edit-sales-button"
                            onClick={() => handleEditSales(booking)}
                            title="Edit Sales"
                            aria-label="Edit Sales"
                          >
                            <FontAwesomeIcon icon={faStethoscope} /> Edit Sales
                          </button>
                          <button
                            className="view-diagnos-button"
                            onClick={() => openDiagnosModal(booking, false)}
                            title="Paparkan Diagnos"
                            aria-label="Paparkan Diagnos"
                          >
                            <FontAwesomeIcon icon={faEye} /> Paparkan Diagnos
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

        </div>
      ) : (
        <p className="no-bookings">Tiada tempahan buat masa ini.</p>
      )}

      {/* Modal Components */}
      <Modal
        isOpen={isDiagnosModalOpen}
        onRequestClose={closeDiagnosModal}
        contentLabel="Diagnos Form"
        className="diagnos-modal"
        overlayClassName="diagnos-modal-overlay"
      >
        {selectedBooking && (
          <DiagnosForm
            booking={selectedBooking}
            onClose={closeDiagnosModal}
            onDiagnosSubmitted={()=>{
              fetchBookings(); // Lepas submit diagnos kalau nak pasti data up-to-date
            }}
            isEdit={isEditMode}
          />
        )}
      </Modal>

      <Modal
        isOpen={isSalesModalOpen}
        onRequestClose={() => setIsSalesModalOpen(false)}
        contentLabel="Sales Form"
        className="sales-modal"
        overlayClassName="diagnos-modal-overlay"
      >
        {currentBooking && (
          <SalesForm
            booking={currentBooking}
            onClose={() => { setIsSalesModalOpen(false); setCurrentSalesData(null); }}
            onSalesSubmitted={handleSalesSubmitted}
            salesData={currentSalesData} // Tambah ini
          />
        )}
      </Modal>

      <Modal
        isOpen={isSalesReportModalOpen}
        onRequestClose={() => setIsSalesReportModalOpen(false)}
        contentLabel="Sales Report"
        className="sales-modal"
        overlayClassName="diagnos-modal-overlay"
      >
        {currentSalesReportBooking && (
          <SalesReport
            booking={currentSalesReportBooking}
            onClose={() => setIsSalesReportModalOpen(false)}
          />
        )}
      </Modal>

      {/* Reminder Modal */}
      <Modal
        isOpen={isReminderModalOpen}
        onRequestClose={() => !isSendingReminders && setIsReminderModalOpen(false)}
        contentLabel="Send Reminders"
        className="reminder-modal"
        overlayClassName="reminder-modal-overlay"
      >
        {!isSendingReminders ? (
          <>
            <h2>Hantar Peringatan Hari Ini</h2>
            <p>Anda mempunyai <strong>{totalReminders}</strong> tempahan hari ini untuk menghantar peringatan.</p>
            <p>Sebelum menghantar, anda boleh mengubah suai template mesej di bawah jika perlu.</p>
            <textarea
              value={reminderMessageTemplate}
              onChange={(e) => setReminderMessageTemplate(e.target.value)}
              disabled={isSendingReminders}
              aria-label="Template Mesej Peringatan"
            />
            <div className="modal-actions">
              <button
                className="cancel-button"
                onClick={() => setIsReminderModalOpen(false)}
                disabled={isSendingReminders}
              >
                Batal
              </button>
              <button
                className="submit-button"
                onClick={startSendingReminders}
                disabled={isSendingReminders}
              >
                Hantar
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>Menghantar Peringatan...</h2>
            <div className="spinner-container">
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              <p>Menghantar peringatan {currentReminderIndex} / {totalReminders}</p>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default MyBookings;
