// PublicTherapistPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

import './PublicTherapistPage.css';
import { FaEnvelope, FaLock, FaUser, FaPhone } from 'react-icons/fa';

function PublicTherapistPage() {
  const navigate = useNavigate();
  const { therapistSlug } = useParams();
  const [therapist, setTherapist] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isSignUp, setIsSignUp] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    async function fetchTherapist() {
      try {
        const res = await axios.get(`${config.API_URL}/therapist-by-slug/${therapistSlug}`);
        if (res.status === 200) {
          setTherapist(res.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchTherapist();
  }, [therapistSlug]);

  const handleSignIn = async () => {
    try {
      const res = await axios.post(`${config.API_URL}/api/signin`, { email, password });

      // Contoh: simpan token & user ke localStorage
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));

      alert('Signed in successfully');

      // Redirect ke dashboard
      navigate('/user-dashboard');
    } catch (error) {
      console.error('Sign in error:', error.response?.data || error.message);
      alert('Sign in failed');
    }
  };

  const handleSignUp = async () => {
    try {
      const res = await axios.post(`${config.API_URL}/api/signup`, {
        name,
        phone,
        email,
        password,
        therapistSlug, // → hantar slug
      });
      console.log('Sign up success:', res.data);
      alert('Account created, please sign in');
      setIsSignUp(false);
    } catch (error) {
      console.error('Sign up error:', error.response?.data || error.message);
      alert('Sign up failed');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!therapist) return <div>Therapist not found</div>;

  return (
    <div className="public-therapist-container">
      {/* LEFT SIDE (Therapist Info) */}
      <div className="therapist-info">
        <h1>{therapist?.name || 'Matin Therapy Center'}</h1>
        <h2>{therapist?.publicTagline || 'Your Journey to Wellness Begins Here'}</h2>
        <p>
          {therapist?.publicIntroText ||
            'Experience tranquility and healing through our professional therapy services'}
        </p>
        <ul>
          {therapist?.publicBulletPoints?.length > 0 ? (
            therapist.publicBulletPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))
          ) : (
            <>
              <li>Professional Care</li>
              <li>Expert Therapists</li>
              <li>Peaceful Environment</li>
            </>
          )}
        </ul>
      </div>

      {/* RIGHT SIDE (Form) */}
      <div className="public-login-form">
        {isSignUp ? <h2>Create an Account</h2> : <h2>Welcome Back</h2>}
        <p>{isSignUp ? 'Please sign up to continue' : 'Please sign in to your account'}</p>

        {isSignUp && (
          <>
            <div className="public-input-group">
              <FaUser className="public-input-icon" />
              <input
                type="text"
                placeholder="Your Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="public-input-group">
              <FaPhone className="public-input-icon" />
              <input
                type="text"
                placeholder="Phone Number (e.g. 6012xxxxxxx)"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </>
        )}

        {/* Email */}
        <div className="public-input-group">
          <FaEnvelope className="public-input-icon" />
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* Password */}
        <div className="public-input-group">
          <FaLock className="public-input-icon" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {isSignUp ? (
          <button className="public-login-button" onClick={handleSignUp}>
            Sign Up
          </button>
        ) : (
          <button className="public-login-button" onClick={handleSignIn}>
            Sign In
          </button>
        )}

        <div className="public-extras">
          {!isSignUp && <a href="#">Forgot your password?</a>}
          <p>
            {isSignUp ? 'Already have an account?' : 'Don’t have an account?'}{' '}
            <span
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? 'Sign in →' : 'Sign up →'}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PublicTherapistPage;
